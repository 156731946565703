import { BlogCard } from "components/cards";
import { useEffect, useState } from "react";
import { getBlogs } from "services/blogs.service";

const LOCAL_STORAGE_KEY = 'blogsCache';

const NewsAndBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const cachedBlogs = localStorage.getItem(LOCAL_STORAGE_KEY);

        if (cachedBlogs) {
            setBlogs(JSON.parse(cachedBlogs));
            setIsLoading(false);
        } else {
            getBlogs().then((response) => {
                if (response && response.results) {
                    setBlogs(response.results);
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(response.results));
                }
                setIsLoading(false);
            });
        }
    }, []);

    if (isLoading) return <div>Loading...</div>;

    return (
        <>
            <div className="flex py-6 text-2xl font-semibold p-3 items-start">
                <h3>Latest News & Blogs</h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
                {blogs.map((blog) => (
                    <div key={blog._id}>
                        <BlogCard blog={blog} />
                    </div>
                ))}
            </div>
        </>
    );
}

export default NewsAndBlogs;
