import React from 'react';
import { useNavigate } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import { APP_ROUTES } from 'constants/routes';

const ContactCard = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-F7F7F7 px-5 py-5 h-100 w-80 m-10 border-2 rounded-xl">
            <div className='flex flex-col'>
                <h1 className='font-bold'>Have any questions ?</h1>

                <div className='py-4'>
                    <p>If you have any questions or concerns about our shipping policy, please don’t hesitate to contact our customer service team.</p>
                </div>
                <div className='py-4'>
                    <p>
                        <VerifiedIcon className='mx-1/2 text-green-700' fontSize="small"></VerifiedIcon> We are always happy to help!
                    </p>
                </div>
                <button type="button" className="items-center text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" onClick={() => { navigate(APP_ROUTES.CONTACT_US) }}>Contact Us</button>
            </div>
        </div>
    );
};

export default ContactCard;