import { createSlice } from '@reduxjs/toolkit';
import { MOCK_ADDRESS } from 'constants/mock_data';

const initialState = {
    isAuthenticated: false,
    loading: false,
    user: null,
    addresses: MOCK_ADDRESS,
    cartDetail: null,
    location: null,
};

export const userSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        loadingStatus: (state, action) => {
            return {
                ...state,
                loading: action.payload,
            }
        },
        loginSuccess: (state, action) => {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                loading: false
            };
        },
        loginFailed: (state) => {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false
            };
        },
        setAddresses: (state, action) => {
            return {
                ...state,
                addresses: action.payload
            }
        },
        setCartDetail: (state, action) => {
            return {
                ...state,
                cartDetail: action.payload
            }
        },
        setLocation: (state, action) => ({
            ...state,
            location: {
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
                address: action.payload.address ? action.payload.address : null,
                city: action.payload.city ? action.payload.city : null,
                state: action.payload.state ? action.payload.state : null,
                country: action.payload.country ? action.payload.country : null,
                zipCode: action.payload.zipCode ? action.payload.zipCode : null,
            },
        }),
    },
});

export const { loginSuccess, loginFailed, loadingStatus, setAddresses, setCartDetail, setLocation } = userSlice.actions;

export default userSlice.reducer;