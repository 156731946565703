import React from 'react';
import { Link } from 'react-router-dom';

const CategoryCard = ({ category }) => {
    return (
        <Link to={`/categories?category=${category.slug}`}>
            <div class="m-5 mx-4 max-w-screen-lg overflow-hidden rounded-xl border shadow-lg md:pl-8">
                <div class="flex flex-col overflow-hidden bg-white sm:flex-row md:h-40">
                    <div class="flex w-full flex-col p-4 sm:w-1/2 sm:p-8 lg:w-3/5">
                        <h2 class="font-bold text-gray-900 md:text-xl">{category.name}</h2>
                        <p class="mt-2 text-lg text-gray-400">{category.department.name}</p>
                        {category.description && (
                            <p class="mt-4 mb-8 max-w-md text-gray-500">
                                {category.description}
                            </p>
                        )}
                    </div>
                    <div class="order-first ml-auto h-48 w-full bg-gray-700 sm:order-none sm:h-auto sm:w-1/2 lg:w-2/5">
                        <img class="h-full w-full object-cover" src={category.image.url} loading="lazy" />
                    </div>
                </div>
            </div>
        </Link>

    );
};

export default CategoryCard;