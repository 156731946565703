import axios from 'axios';
import CONSTANTS from '../utils/Constants';

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_RETAIL_BASE_URL,
    timeout: process.env.REACT_APP_RETAIL_TIMEOUT_IN_MILLISEC,
    headers: {
        'Content-Type': 'application/json',
        'x-app-id': process.env.REACT_APP_PUBLIC_APP_ID,
        'x-app-token': process.env.REACT_APP_PUBLIC_APP_TOKEN
    },
});

axiosClient.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error.response) {
            // responding responding properly
            return Promise.reject(error.response.data);
        } else if (error.request) {
            // Server is not responding (maybe 1.timeout, 2.request dropped, 3.response droppped)
            // alert('Something went wrong, please try again!')
        } else {
            // Something else
            // alert('Something went wrong, please try again!')
        }
    }
);

export const setAuthToken = (apiName, token = null) => {
    switch (apiName) {
        case CONSTANTS.RETAIL_API_NAME:
            token ? axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}` : delete axiosClient.defaults.headers.common['Authorization'];
            break;
        default:
            break;
    }
};
