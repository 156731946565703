import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const errorsSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setErrors: (state, action) => action.payload,
        clearErrors: state => initialState
    },
});

export const { setErrors, clearErrors } = errorsSlice.actions;

export default errorsSlice.reducer;