import BreadCrumbs from "components/BreadCrumbs";
import ConfirmationModal from "components/Modals/ConfirmationModal";
// import OrderSuccessModal from "components/OrderSuccesModal";
import OrderCard from "components/cards/OrderCard";
import React, { useEffect, useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';

const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const ORDER_STATUS = {
    IN_TRANSIT: "IN_TRANSIT",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    RETURN: "RETURN",
    PRE_ORDER: "PRE_ORDER",
};

const OrderSearchBar = ({ search, setSearch }) => {
    return (
        <form className="w-full flex-1 md:mr-4 md:max-w-md">
            <label for="default-search" className="sr-only text-sm font-medium text-gray-900 dark:text-white">Search</label>
            <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg aria-hidden="true" className="h-4 w-4 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </div>
                <input type="search" id="default-search" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" placeholder="Search by Order ID" required="" onChange={(e) => setSearch(e.target.value)} value={search} />
                {/* <button type="submit" className="absolute bottom-0 right-0 top-0 rounded-r-lg bg-primary-700 px-4 py-2 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Search</button> */}
            </div>
        </form>
    );
};

const OrderStatusFilter = () => {
    return (
        <>
            <button
                id="filterDropdownButtonLabel2"
                data-dropdown-toggle="filterDropdownButton"
                type="button"
                className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 md:w-auto"
            >
                <svg className="-ms-0.5 me-1.5 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
                </svg>
                Filter by: Completed
                <svg className="-me-0.5 ms-1.5 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7" />
                </svg>
            </button>
            <div id="filterDropdownButton" className="z-10 hidden w-36 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700">
                <ul className="p-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400" aria-labelledby="filterDropdownButtonLabel">
                    <li>
                        <a href="#" className="group inline-flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
                            <span className="me-2 h-2.5 w-2.5 rounded-full bg-green-500"></span>
                            <span>Completed</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="group inline-flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
                            <span className="me-2 h-2.5 w-2.5 rounded-full bg-primary-600"></span>
                            Pre-order
                        </a>
                    </li>
                    <li>
                        <a href="#" className="group inline-flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
                            <span className="me-2 h-2.5 w-2.5 rounded-full bg-yellow-300"></span>
                            In transit
                        </a>
                    </li>
                    <li>
                        <a href="#" className="group inline-flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
                            <span className="me-2 h-2.5 w-2.5 rounded-full bg-red-500"></span>
                            Cancelled
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

const OrderFilterByPeriod = (selectedValue) => {
    return (
        <>
            <button
                id="dateDropdownButtonLabel2"
                data-dropdown-toggle="dateDropdownButton2"
                type="button"
                className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 md:w-auto"
            >
                Last 7 days
                <svg className="-me-0.5 ms-1.5 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7" />
                </svg>
            </button>
            <div id="dateDropdownButton2" className="z-10 hidden w-80 divide-y divide-gray-100 rounded bg-white shadow dark:divide-gray-600 dark:bg-gray-700">
                <ul className="divide-y divide-gray-200 text-sm font-medium dark:divide-gray-700" aria-labelledby="dateDropdownButtonLabel2">
                    <li>
                        <a href="#" className="group flex items-center gap-2 px-4 py-2 text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-600">
                            Today
                            <span className="font-normal text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"> Aug 21, 2023 - Aug 21, 2023 </span>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="group flex items-center gap-2 px-4 py-2 text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-600">
                            Yesterday
                            <span className="font-normal text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"> Aug 20, 2023 - Aug 21, 2023 </span>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="group flex items-center gap-2 px-4 py-2 text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-600">
                            Last 7 days
                            <span className="font-normal text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"> Aug 21, 2023 - Aug 21, 2023 </span>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="group flex items-center gap-2 px-4 py-2 text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-600">
                            Last Month
                            <span className="font-normal text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"> Aug 15, 2023 - Aug 21, 2023 </span>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="group flex items-center gap-2 px-4 py-2 text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-600">
                            Last year
                            <span className="font-normal text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"> Jan 1, 2023 - Aug 21, 2023 </span>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="group flex items-center gap-2 px-4 py-2 text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-600">
                            All time
                            <span className="font-normal text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"> Feb 1, 2020 - Aug 21, 2023 </span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

const OrderListHeader = () => {
    return (
        <div className="mb-4 items-center justify-between md:flex md:space-x-4">

            <OrderSearchBar />

            <div className="mt-4 items-center space-y-4 sm:flex sm:space-x-4 sm:space-y-0 md:mt-0">
                <OrderStatusFilter />
                <OrderFilterByPeriod />
            </div>
        </div>
    );
};

const Orders = () => {
    const [filters, setFilters] = useState(null);
    const [search, setSearch] = useState("");
    const [orders, setOrders] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        icon: '',
        title: '',
        message: '',
        submitButtonText: '',
        cancelButtonText: '',
        handleSubmit: () => { },
        handleCancel: () => { }
    });

    const fetchOrders = async (filters) => {
        try {
            // Assuming you have an API endpoint to fetch orders
            // const response = await fetch('/api/orders', {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(filters),
            // });
            // const data = await response.json();
            const order = {
                id: generateRandomString(8),
                products: [],
                status: "IN_TRANSIT",
                estimateDelivery: new Date(),
                email: "test@gmail.com",
                invoiceUrl: "http://localhost:3000/static/media/logo.e4ca87f10b815d2e2c2c.png",
                address: {
                    pincode: 492008,
                    city: "Pune",
                    country: "India",
                },
                createdAt: new Date(),
            };
            const data = { orders: [order, { ...order, id: generateRandomString(8), status: ORDER_STATUS.COMPLETED }, { ...order, id: generateRandomString(8), status: ORDER_STATUS.CANCELLED }, { ...order, id: generateRandomString(8), status: ORDER_STATUS.PRE_ORDER }, { ...order, id: generateRandomString(8), status: "RETURN" }] };
            console.log(data);
            let filteredOrders = data.orders;
            if (search) {
                filteredOrders = filteredOrders.filter(order => order.id.toLowerCase().includes(search.toLowerCase()));
            }
            setOrders(filteredOrders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        console.log("useEffect");
        // call the api when the filters change
        fetchOrders(filters);
        console.log(orders);
    }, [filters, search]);

    useEffect(() => {
        console.log("useEffect");
        // call the api when the filters change
        fetchOrders(filters);
        console.log(orders);
    }, [search]);

    const handleCancelOrder = (orderId) => {
        setModalContent({
            icon: <HelpIcon className="!text-[60px] !text-red-500" />,
            title: 'Cancel Order',
            message: 'Are you sure you want to cancel this order?',
            submitButtonText: 'Yes cancel',
            cancelButtonText: 'Not now',
            handleCancel: () => { setModalOpen(false) },
            handleSubmit: () => { setModalOpen(false) }
        });
        setModalOpen(true);
    }


    return (
        <section className="bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
            <div className="mx-auto max-w-screen-2xl px-4 2xl:px-0">
                {/* Page Navigation */}
                <nav className="mb-4 flex" aria-label="Breadcrumb">
                    <BreadCrumbs path={"/profile/orders"} className="mb-4 flex" />
                </nav>

                <h2 className="mb-4 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl md:mb-8">My orders</h2>
                <div className="mb-4 items-center justify-between md:flex md:space-x-4">

                    <OrderSearchBar search={search} setSearch={setSearch} />

                    <div className="mt-4 items-center space-y-4 sm:flex sm:space-x-4 sm:space-y-0 md:mt-0">
                        <OrderStatusFilter value={filters} handleChange={setFilters} />
                        <OrderFilterByPeriod value={filters} handleChange={setFilters} />
                    </div>
                </div>

                {/* Order cards */}
                {orders.map(order => (
                    <OrderCard key={order.id} order={order} orderStatus={order?.status} handleCancelOrder={handleCancelOrder} />
                ))}

            </div>
            <ConfirmationModal
                isOpen={modalOpen}
                icon={modalContent.icon}
                title={modalContent.title}
                message={modalContent.message}
                submitButtonText={modalContent.submitButtonText}
                cancelButtonText={modalContent.cancelButtonText}
                handleCancel={modalContent.handleCancel}
                handleSubmit={modalContent.handleSubmit}
            />
        </section>
    );
};

export default Orders;
