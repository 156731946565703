import { useState, useEffect } from 'react';

const EditAddress = ({ address: addressToEdit = null, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        first_name: '', last_name: '',
        address: '', address_type: '',
        street: '', landmark: '',
        area_code: '', state: '',
        phone: '',
    });

    useEffect(() => {
        if (addressToEdit) {
            const { first_name, last_name, address, street, landmark, address_type, area_code, state, phone, } = addressToEdit ?? {}
            setFormData({
                first_name: first_name ?? '',
                last_name: last_name ?? '',
                address: address ?? '',
                phone: phone ?? '',
                address_type: address_type ?? '',
                area_code: area_code ?? '',
                street: street ?? '',
                landmark: landmark ?? '',
                state: state ?? '',
            });
        }
    }, [addressToEdit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedFormData = { ...(addressToEdit ?? {}), ...formData }
        onSave(updatedFormData);
    };

    return (
        <div aria-hidden="true" className="flex bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        {addressToEdit ? 'Edit Address' : 'Add Address'}
                    </h3>
                    <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs w-8 h-8 ms-auto inline-flex justify-center items-center"
                        onClick={onClose}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                    </button>
                </div>
                <form className="p-4 md:p-5" onSubmit={handleSubmit}>
                    <div className="grid gap-4 mb-4 grid-cols-2">
                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="first_name" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="first name"
                                required
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                        </div>


                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="last_name" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="last name"
                                required
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                        </div>



                        <div className="col-span-2 ">
                            <label htmlFor="address" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Flat No/House No
                            </label>
                            <input
                                type="text"
                                name="address"
                                id="address"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="flat no./house no"
                                required
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="street" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Street/Village
                            </label>
                            <input
                                type="text"
                                name="street"
                                id="street"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="street/locality"
                                required
                                value={formData.street}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="landmark" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Landmark
                            </label>
                            <input
                                type="text"
                                name="landmark"
                                id="landmark"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="landmark"
                                required
                                value={formData.landmark}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="address_type" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Address Type
                            </label>
                            <select
                                id="address_type"
                                name="address_type"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-500 focus:border-primary-500 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                value={formData.address_type}
                                onChange={handleChange}
                            >
                                <option value="">Select address type</option>
                                <option value="home">Home</option>
                                <option value="office">Office</option>
                                <option value="other">Other</option>
                            </select>
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="area_code" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Pincode
                            </label>
                            <input
                                type="number"
                                name="area_code"
                                id="area_code"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="pin-code"
                                required
                                value={formData.area_code}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="state" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                State
                            </label>
                            <input
                                type="text"
                                name="state"
                                id="state"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 flex w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="state/district"
                                required
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="phone" className="flex mb-2 text-xs font-medium text-gray-900 dark:text-white">
                                Phone number
                            </label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                aria-describedby="helper-text-explanation"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="9700000000"
                                required
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <button type="submit" className="text-white inline-flex items-center bg-primary1 hover:bg-primary1 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-primary1 dark:focus:ring-blue-800" >
                        {addressToEdit ? 'Update Address' : 'Save Address'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditAddress;
