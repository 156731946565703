import React from "react";
import { Routes, Route } from "react-router-dom";

import { APP_ROUTES } from "../constants/routes";

import LandingPage from "../pages/HomePage";
import Products from "../pages/Products";
import Collections from "../pages/Collections";
import CollectionDetails from "../pages/CollectionDetails";
import Categories from "../pages/Categories";
import CategoryDetails from "../pages/CategoryDetails";
import OrderJourney from "../pages/OrderJourney";
import Login from "../pages/Auth/Login";
import Logout from "../pages/Auth/Logout";
import Registration from "../pages/Auth/Registration";
import ResetPassword from "../pages/Auth/ResetPassword";
import Contact from "../pages/Contact";
import AboutUs from "../pages/Informations/AboutUs";
import TermsAndConditions from "../pages/Informations/TermsAndConditions";
import PageNotFound from '../pages/Error/PageNotFound';
import OrdersNew from "../pages/Orders";
import Orders from '../pages/Orders2';
import Dashboard from "../pages/Dashboard";
import ProductDetails from "../components/Body/ProductDetails/ProductDetails";
import Wishlist from "../components/Body/Wishlist/Wishlist";
import ProtectedRoute from "../components/common/ProtectedRoute";
import Cart from "pages/Cart";
import OrderDetail from "pages/OrderJourney/OrderDetails";
import OrderTracking from "pages/OrderJourney/OrderTracking";
import DiscountPage from "pages/Discount";
import CareersPage from "pages/Informations/Careers";
import CorporateInfo from "pages/Informations/CorporateInfo";
import ExchangePolicyTerms from "pages/Informations/ExchangePolicy";
import PaymentTerms from "pages/Informations/PaymentTerms";
import StoresInfo from "pages/Informations/Stores";
import DeliveryTerms from "pages/Informations/DeliveryTerms";
import ProductExchangeTerms from "pages/Informations/ProductExchangeTerms";
import PricingTerms from "pages/Informations/PricingTerms";
import ProductReturns from "pages/Informations/ProductReturns";

const RouteMap = () => {
    return (
        <div className="bg-white mx-2 sm:mx-6 md:mx-9">
            <Routes>
                {/** Home Page */}
                <Route path="/" element={<LandingPage />} errorElement={<PageNotFound />} />

                {/** User Profile Routes */}
                <Route path={APP_ROUTES.DASHBOARD} element={<ProtectedRoute><Dashboard /></ProtectedRoute>} errorElement={<PageNotFound />} />
                <Route path={"profile"} element={<ProtectedRoute><Dashboard /></ProtectedRoute>} errorElement={<PageNotFound />} />

                {/** Category related pages */}
                <Route path={APP_ROUTES.CATEGORIES} element={<Categories />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.CATEGORIES + "/:category"} element={<CategoryDetails />} errorElement={<PageNotFound />} />

                {/** Products Related pages */}
                <Route path={APP_ROUTES.PRODUCTS} element={<Products />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.PRODUCTS + "/:productId"} element={<ProductDetails />} errorElement={<PageNotFound />} />

                {/** Collection related pages */}
                <Route path={APP_ROUTES.COLLECTION} element={<Collections />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.COLLECTION + "/:collection"} element={<CollectionDetails />} errorElement={<PageNotFound />} />

                {/** Wishlist and other pages */}
                <Route path={APP_ROUTES.WISHLIST} element={<ProtectedRoute><Wishlist /></ProtectedRoute>} errorElement={<PageNotFound />} />

                {/** Cart and Orders pages */}
                <Route path={APP_ROUTES.CART} element={<OrderJourney />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.CART + "/new"} element={<Cart />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.ORDERS} element={<ProtectedRoute><OrdersNew /></ProtectedRoute>} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.ORDERS + "/new"} element={<Orders />} errorElement={<PageNotFound />} />
                {/* <Route path={APP_ROUTES.ORDERS + "/details"} element={<OrderDetails />} errorElement={<PageNotFound />} /> */}
                <Route path={APP_ROUTES.ORDERS + "/:orderId"} element={<OrderDetail />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.ORDERS + "/:orderId/track"} element={<OrderTracking />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.DISCOUNTS} element={<DiscountPage />} errorElement={<PageNotFound />} />

                {/** Authentication Pages */}
                <Route path={APP_ROUTES.SIGNIN} element={<Login />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.SIGNUP} element={<Registration />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.SIGNOFF} element={<Logout />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.RESET_PASSWORD} element={<ResetPassword />} errorElement={<PageNotFound />} />

                {/** Informational pages */}
                <Route path={APP_ROUTES.CONTACT_US} element={<Contact />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.ABOUT_US} element={<AboutUs />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.TERMS_CONDITIONS} element={<TermsAndConditions />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.CAREERS} element={<CareersPage />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.CORPORATE} element={<CorporateInfo />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.EXCHANGE} element={<ExchangePolicyTerms />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.PAYMENT_TERMS} element={<PaymentTerms />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.STORES} element={<StoresInfo />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.DELIVERY_TERMS} element={<DeliveryTerms />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.EXCHANGE} element={<ProductExchangeTerms />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.PRICING_TERMS} element={<PricingTerms />} errorElement={<PageNotFound />} />
                <Route path={APP_ROUTES.RETURNS} element={<ProductReturns />} errorElement={<PageNotFound />} />

                <Route path="*" element={<LandingPage />} />
            </Routes>
        </div>
    );
};

export default RouteMap;