import React, { useCallback, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import { setAuthToken } from "../services/axios";
import CONSTANTS from '../utils/Constants';
import { getAppDetails } from "../services/appDetail.service";
import { getUser } from '../services/userSpecific/user.service';
import { getCategories } from '../services/categories.service';
import { getProducts } from '../services/product.service';
import { setCategories } from '../reducers/categoriesSlice';
import { setProducts } from '../reducers/productsSlice';
import { loginSuccess, setCartDetail } from '../reducers/userSlice';
import { setAppDetails } from "../reducers/appSlice";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import RouteMap from '../routes/RouteMap';
import 'react-toastify/dist/ReactToastify.css';
import { APP_ROUTES } from "constants/routes";

const DeafultLayout = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const errors = useSelector(state => state.errors);
    const noHeaderFooterRoutes = [APP_ROUTES.SIGNIN, APP_ROUTES.SIGNUP, APP_ROUTES.RESET_PASSWORD];
    const shouldShowHeaderFooter = !noHeaderFooterRoutes.includes(location.pathname);

    useEffect(() => {
        getAppDetails().then(res => {
            setAppDetails(res?.data)
        }).catch((err) => {
            console.log(err)
        })
        getInitialData();
        // FIXME: if access token is expired, refresh it
        const RetailAccessToken = localStorage.getItem('RetailAccessToken');
        if (RetailAccessToken) {
            const decoded = jwtDecode(RetailAccessToken);
            if (decoded.exp * 1000 >= Date.now()) {
                setAuthToken(CONSTANTS.RETAIL_API_NAME, RetailAccessToken);
                getUser(decoded.sub).then(userInfo => {
                    if (userInfo) {
                        dispatch(loginSuccess(userInfo))
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (errors?.message) {
            toast.error(errors.message);
        }
    }, [errors]);

    const getInitialData = useCallback(() => {
        getCategories({ page: 1, pageSize: 1000 }).then(data => {
            dispatch(setCategories(data));
        }).catch((err) => console.log(err))
        getProducts({ page: 1, pageSize: 12 }).then(data => {
            dispatch(setProducts(data));
            // dispatch(setCartDetail({ items: [{ ...data.data[0], quantity: 2 }] }))

        }).catch((err) => console.log(err))
    }, [])

    return (
        <div className="App flex flex-col h-screen">
            {shouldShowHeaderFooter && <Header />}
            <main className="flex-1">
                {children}
                <RouteMap />
                {shouldShowHeaderFooter && <Footer />}
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </main>
        </div>
    );
};

export default DeafultLayout;