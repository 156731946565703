import React, { useState } from 'react';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { useSelector } from 'react-redux';
import LocationModal from 'components/Header/LocationSelection';

const LocationAuto = () => {
    const { addresses = [] } = useSelector((state) => state.userProfile);
    console.log(addresses);
    const [location, setLocation] = useState(addresses?.find(address => address.is_default_address === true));
    // let location = addresses?.find(address => address.is_default_address === true);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const onAddressSelectHandler = (address) => {
        setLocation(address);
        setModalOpen(false);
    }

    const onPincodehandler = (pincode) => {
        setLocation({ area_code: pincode, first_name: '' })
        setModalOpen(false);
    }

    return (
        <>
            {
                location
                    ?
                    <div className="flex items-center justify-center" style={{ cursor: 'pointer' }} onClick={handleOpenModal} >
                        <FmdGoodOutlinedIcon fontSize="large" className="primary-color" />
                        <div className='grid items-center justify-center' style={{ fontSize: 'small' }}>
                            <div>Deliver to {location?.first_name}</div>
                            <div><b>{location?.city} {location?.area_code}</b> </div>
                            {/* <div><b>{location?.city} {location?.area_code}</b> </div> */}
                        </div>
                    </div>
                    :
                    <AddLocationAltOutlinedIcon
                        fontSize="large"
                        className="primary-color"
                        onClick={handleOpenModal}
                        style={{ cursor: 'pointer' }}
                    />
            }
            <LocationModal isOpen={isModalOpen} onClose={handleCloseModal} onAddressSelectHandler={onAddressSelectHandler} onPincodehandler={onPincodehandler} location={location} />
        </>
    )
}

export default LocationAuto