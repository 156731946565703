import React from 'react';
import { useNavigate } from 'react-router-dom';

const Card = ({ size, imageUrl, logoUrl, title, subTitle, target }) => {
    const navigate = useNavigate();
    const sizeClasses = {
        small: 'h-32',
        medium: 'h-48',
        large: 'h-64',
    };

    return (
        <div
            className={`card relative ${sizeClasses[size]} hover:ring-primary`}
            onClick={() => navigate(target)}
        >
            <div
                className="bg-cover bg-center h-full"
                style={{ backgroundImage: `url(${imageUrl})` }}
            ></div>
            <div className="absolute bottom-0 left-0 right-0 p-4 text-white flex items-center">
                {logoUrl && (
                    <img
                        src={logoUrl}
                        alt="Logo"
                        className="w-8 h-8 mr-2 rounded-full"
                    />
                )}
                <div>
                    <h2 className="text-xl font-bold mb-1">{title}</h2>
                    <p className="text-sm">{subTitle}</p>
                </div>
            </div>
        </div>
    );
};

export default Card;