import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { renderStars } from "utils/CommonUtils";
import useCategoryById from "hooks/useCategoryById";
import { APP_ROUTES } from "constants/routes";
import chilli from "assets/categories/dry-red-chilli.png";

import "./cards.css";
const TrendingCard = ({ product }) => {
  const category = useCategoryById(product?.category);
  const [rating] = useState(2.5);
  const getDiscount = useMemo(() => {
    return () => {
      const discount =
        ((product?.pricing?.mrp - product?.pricing?.esp) /
          product?.pricing?.mrp) *
        100;
      return Math.round(discount);
    };
  }, [product]);
  return (
    <Link to={`${APP_ROUTES.PRODUCTS}/${product?.id}`}>
      <div className="trending-card zoom-effect sm:ml-3 w-[380px]">
        <div className="min-w-[120px] w-[120px]">
          <img
            src={product?.media?.[1] ?? chilli}
            alt="product_img"
            className="w-full rounded"
          />
        </div>
        <div className="ml-3 w-[260px]">
          <div className="category-wishlist ">
            <div className="category">{category?.name}</div>
            <div>
              <FavoriteBorderTwoToneIcon className="wishlist" />
            </div>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <div className="product-offer-price">
                ₹{product?.pricing?.esp}
              </div>
              {product?.pricing?.esp !== product?.pricing || true ? (
                <>
                  <div className="product-original-price">
                    ₹{product?.pricing?.mrp}
                  </div>
                  <div style={{ paddingLeft: "8px" }}>
                    <LocalOfferIcon className="discount-icon" />
                  </div>
                  <div className="discount-percent">{getDiscount()}%</div>
                </>
              ) : null}
            </div>
            <div className="product-name">{product?.name}</div>
            <div className="product-rating">
              {renderStars(rating)}
              <div className="rating-number">({rating.toFixed(1)})</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TrendingCard;
