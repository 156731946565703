import React from "react"
import FilterCard from "./FilterCard";
import { Accordion } from "flowbite-react";

const Filters = ({ filters, onFilterChange, ...restProps }) => {
    const updateFilter = (filter) => {
        console.log(filter, "filter")
    }
    return (
        <div {...restProps}>
            <Accordion collapseAll>
                <Accordion.Panel>
                    {
                        filters?.map(filter => <>
                            <Accordion.Title>{filter?.key?.display}</Accordion.Title>
                            <Accordion.Content>
                                <FilterCard filter={filter} onFilterChange={onFilterChange} />
                            </Accordion.Content>
                        </>)
                    }
                </Accordion.Panel>
            </Accordion>
        </div>
    );
};


export default Filters;