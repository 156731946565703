import { useSelector } from "react-redux";
import { TrendingCard } from "components/cards";
const TrendingProducts = () => {
  const { products } = useSelector((state) => state.products);
  return (
    <>
      <div className="flex pt-6 text-2xl font-semibold p-3 items-start">
        <h3>Trending Products</h3>
      </div>
      <div className="flex h-full w-full overflow-y-hidden scrollbar-hide pt-3">
        {products.map((item) => (
          <div
            key={"trending-product:" + item.id}
            className="first:ml-1.5 last:mr-1.5 ml-3 mb-8"
          >
            <TrendingCard product={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default TrendingProducts;
