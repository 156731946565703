import React from 'react';
import ContactCard from "./ContactUsCard";
import BreadCrumbs from '../../components/BreadCrumbs';
import { APP_ROUTES } from 'constants/routes';

const CorporateInfo = () => {
    return (
        <div>
            <header>
                <title>SUNDHEEP PRODUCTS - Corporate Information</title>
                <meta name="description" content="A leading player in food product manufacturing and agricultural trading." />
            </header>
            <BreadCrumbs path={APP_ROUTES.CORPORATE} />
            <div className='flex flex-1 mx-auto pb-5'>
                <div className='flex flex-col space-x-1 space-y-3 mx-auto'>
                    <div className='justify-center items-center text-justify'>
                        <h1 className='font-bold text-xl text-center space-y-1'>Corporate Information</h1>
                    </div>
                </div>
                <div className='mt-6 space-y-5'>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>About SUN DHEEP PRODUCTS</h3>
                        <p>
                            Founded in January 2023, SUN DHEEP PRODUCTS has quickly established itself as a prominent player in the food manufacturing and agricultural trading sectors. As a distinguished Registered Partnership firm, we are dedicated to delivering high-quality food products and reliable agricultural commodities to customers across India and beyond. Our operations are backed by Export & Import Licenses, FSSAI Certification, and MSME UDYAM Registration, reflecting our unwavering commitment to quality, safety, and excellence.
                        </p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Our Business Segments</h3>
                        <p>SUN DHEEP STORES aims to redefine the shopping experience by offering a wide range of meticulously crafted food products under one roof. From spices and pulses to millets, edible oils, and honey, our stores will feature everything that has made SUN DHEEP PRODUCTS a trusted name in the industry. We envision SUN DHEEP STORES as a place where tradition meets innovation, delivering superior quality products to consumers who care about what they eat.</p>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>
                                <span className='font-semibold'>Food Manufacturing: </span>
                                <p>
                                    1. Food Manufacturing: SUN DHEEP PRODUCTS is a leader in the manufacturing, processing, and packaging of a diverse range of food items. Our product lineup includes essential kitchen staples such as Chili Powder, Turmeric Powder, Coriander Powder, various types of pulses like Urad Dal and Bengal Gram, and grains such as Wheat Rava and Millets. Additionally, we produce and package premium Edible Oils and Honey, all crafted with meticulous attention to quality and taste.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Agricultural Trading: </span>
                                <p>We source and supply a wide variety of agricultural commodities, including Chilies, Maize, Paddy, Urad Dal, Chana Dal, Black Gram, and Bengal Gram. Our direct relationships with farmers and established partnerships with corporate giants ensure that we provide the highest quality products to our customers, meeting the demands of the current market.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Our Infrastructure</h3>
                        <p>SUN DHEEP PRODUCTS boasts a robust infrastructure, equipped with state-of-the-art machinery and dedicated storage facilities. Our corporate office serves as the nerve centre of our operations, coordinating the seamless flow of products from our manufacturing units to our customers across the nation. Our investment in advanced technology and efficient logistics ensures that we maintain our high standards of quality and reliability.</p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Our People</h3>
                        <p>At the heart of SUN DHEEP PRODUCTS are our dedicated employees. Our team is a blend of agricultural experts and industry veterans who bring a wealth of knowledge and experience to the table. We believe in fostering a culture of collaboration, innovation, and continuous learning, ensuring that our staff are equipped to meet the evolving needs of our customers and partners.</p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Vision and Mission</h3>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>

                                <span className='font-semibold'>Vision: </span>
                                <p>To be a leading provider of high-quality food products and agricultural commodities, reaching a vast network of end-users across India, while maintaining the highest standards of quality and customer satisfaction.</p>
                            </li>
                            <li>
                                <span className='font-semibold'> Mission: </span>
                                <p>To strengthen our manufacturing capabilities, deliver superior products at competitive prices, and expand our reach through strategic partnerships and innovative retail initiatives like SUN DHEEP STORES.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Corporate Governance</h3>
                        <p>SUN DHEEP PRODUCTS is committed to maintaining the highest standards of corporate governance. We operate with transparency, integrity, and accountability in all aspects of our business. Our governance framework ensures that we meet our obligations to our customers, partners, employees, and the communities we serve.</p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Contact Us</h3>
                        <p>For more information about SUN DHEEP PRODUCTS, our operations, or investment opportunities, please contact us at:</p>
                        <address>
                            <div className='font-bold'>SUN DHEEP PRODUCTS</div>
                            <div>Suit No.201, Sai Ram Plaza, Housing Board Colony,</div>
                            <div>Guntur-522004, Andhra Pradesh.</div>
                            <div>Toll Free Number : 1800  20260 246.</div>
                            <div>Office: +91 9090 9494 84 or +91 7731 066 066</div>
                            <div>Email: sundheepproducts@gmail.com</div>
                        </address>
                    </section>
                </div>
            </div>
            <div className='ml-8'>
                <ContactCard />
            </div>
        </div>
    );
};

export default CorporateInfo;
