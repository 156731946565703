import React from "react";
import ContactCard from "./ContactUsCard";
import BreadCrumbs from "../../components/BreadCrumbs";
import { APP_ROUTES } from "constants/routes";

const AboutUs = () => {
    return (
        <div className="mx-auto py-8 content-start">
            <header>
                <title>SUNDHEEP PRODUCTS - About Us</title>
                <meta name="description" content="A leading player in food product manufacturing and agricultural trading." />
            </header>
            <BreadCrumbs path={APP_ROUTES.ABOUT_US} />
            <div className='flex flex-col space-x-1 space-y-3 mx-auto'>
                <div className='justify-center items-center text-justify'>
                    <h1 className='font-bold text-xl text-center space-y-1'>About Us</h1>
                </div>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Company Profile</h2>
                    <p>
                        Established in January 2023 as a distinguished Registered Partnership firm, SUN DHEEP PRODUCTS is a leading player in the comprehensive manufacturing of various food products and trading in agricultural commodities. Holding coveted Export & Import Licences, FSSAI Certification, and MSME UDYAM Registration, our commitment to excellence is ingrained in every facet of our operations.
                    </p>
                </section>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Core Operations</h2>
                    <p>
                        Our core competencies lie in the meticulous manufacturing, processing, and packaging of a diverse array of food products. From Chilies Powder, Turmeric Powder, and Coriander Powder to Urad Dal (Black Gram), Bengal Gram (Chana Dal), Wheat Rawa Idly Rawa, Finger Millets, Pearl Millets, Sorghum Millets, and an assortment of Edible Oils and Honey, each product is crafted with precision to guarantee superior quality and customer satisfaction. We have invested significantly in state-of-the-art machinery to enhance operational efficiency and simplify our customers' processes.
                    </p>
                </section>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Agricultural Trading</h2>
                    <p>
                        Functioning as a key player in agricultural trading, we source and supply different varieties of Chillies, Maize, Paddy, Urad Dal, Chana Dal, Black Gram, Bengal Gram, and more. Our commitment to quality is evident as we procure agricultural commodities directly from farmers in various varieties and supply to corporates, exporters, and fellow traders in the current market. Our established partnerships with corporate giants such as ITC, BIG HAAT, Growell Feeds India Pvt Ltd, and Avanthi Feeds Ltd underscore our reliability in the industry.
                    </p>
                </section>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Robust Infrastructure</h2>
                    <p>
                        Our robust infrastructure includes high-end machinery, dedicated godowns, and a corporate office, positioning us as a reliable and efficient player in the market.
                    </p>
                </section>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Valuable Assets</h2>
                    <p>
                        Our staff is our most valuable asset, combining expertise in agriculture and rich industry experience to ensure unparalleled customer satisfaction. Our passion for agribusiness resonates in our endeavors in and around the country.
                    </p>
                </section>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Vision and Mission:</h2>
                    <p>Our vision is to reach a large number of end-users nationwide, offering high-quality food products through the establishment of high-end machinery and efficient supply chains for all agricultural commodities at the most competitive prices. Our mission is to fortify our manufacturing unit, delivering quality products to end-users at competitive prices, and catering to the needs of corporates and exporters nationwide.</p>
                </section>
                <section className='space-y-2 text-justify'>
                    <h2 className='font-semibold'>Contact Information</h2>
                    <address class="leading-relaxed">
                        <div className="font-bold">SUNDHEEP PRODUCTS</div>
                        <div>Dr: 4-210, Near Y Junction, Chilakaluripet Road, AnkireddyPalem</div>
                        <div>Guntur-522004, Andhra Pradesh.</div>
                        <div>Office: +91 9090 9494 84 Or +91 7731 066 066.</div>
                    </address>
                </section>
            </div>
            <div className='items-center justify-center'>
                <ContactCard />
            </div>
        </div>
    );
};

export default AboutUs;