import { createSlice } from "@reduxjs/toolkit";

const initialState = null

export const appSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAppDetails: (state, action) => {
            return {
                ...state,
                appDetails: action.payload
            }
        },
    },
});

export const { setAppDetails } = appSlice.actions;

export default appSlice.reducer;