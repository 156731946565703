import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_ROUTES } from 'constants/routes';
import { useState, useEffect } from 'react';
const CartAmountBreakup = ({ handleCheckout, activeStep, ...restProps }) => {
    const { isAuthenticated, cartDetail, addresses } = useSelector(state => state.userProfile);

    const [totalMRP, setTotalMRP] = useState(0)
    const [totalESP, setTotalESP] = useState(0)

    useEffect(() => {
        setTotalMRP(cartDetail?.items?.reduce((accumulator, currentValue) => accumulator + (currentValue.quantity * (currentValue?.pricing?.['mrp'] ?? 0)), 0) ?? 0)
        setTotalESP(cartDetail?.items?.reduce((accumulator, currentValue) => accumulator + (currentValue.quantity * (currentValue?.pricing?.['esp'] ?? 0)), 0) ?? 0)
    }, [cartDetail])
    return (
        <div {...restProps}>
            <div className="flow-root">
                <div className="-my-3 divide-y divide-gray-200">
                    <dl className="flex items-center justify-between gap-4 py-3">
                        <dt className="text-base font-normal text-gray-500">Total MRP</dt>
                        <dd className={`text-base font-medium text-gray-900`}>₹{totalMRP}</dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                        <dt className="text-base font-normal text-gray-500">Savings</dt>
                        <dd className={`text-base font-medium text-green-500`}>₹{(totalMRP ?? 0) - (totalESP ?? 0)}</dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                        <dt className="text-base font-normal text-gray-500">Delivery Charges</dt>
                        <dd className={`text-base font-medium text-gray-900`}>Free home delivery</dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                        <dt className="text-base font-bold text-gray-500">Total Payable</dt>
                        <dd className={`text-base font-bold text-gray-900`}>₹{totalESP}</dd>
                    </dl>


                </div>
            </div>
            <button onClick={handleCheckout} disabled={!totalESP || (activeStep === 1 && !addresses)} type="button"
                className="flex w-full items-center justify-center rounded-lg bg-primary px-5 py-2.5 text-sm font-medium text-white hover:bg-primary disabled:bg-lightgrey disabled:hover:bg-lightgrey">Proceed to Checkout</button>
            {
                !isAuthenticated && <div className="space-y-3">
                    <p className="text-sm font-normal text-gray-500">One or more items in your cart require an account. <Link to={APP_ROUTES.SIGNIN} className="font-medium text-primary underline hover:no-underline">Sign in or create an account now</Link>.</p>
                </div>
            }

            <form className="flex items-end gap-4">
                <div className="flex-1">
                    <label htmlFor="voucher" className="mb-2 block text-sm font-medium text-gray-900"> Do you have a voucher or gift card? </label>
                    <input type="text" id="voucher" className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-primary focus:ring-primary" placeholder="" required />
                </div>
                <button type="submit" className="flex items-center justify-center rounded-lg bg-primary px-5 py-2.5 text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring-4 focus:ring-primary-300">Apply</button>
            </form>
        </div>
    );
}

export default CartAmountBreakup;