import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import companylogo from '../../assets/company/logo.png';
import Input from '../../components/common/form/Input';
import { useDispatch, useSelector } from "react-redux";
import { setErrors } from "../../reducers/errorsSlice";
import { register } from "../../services/auth/auth.service";
import { Link } from "react-router-dom";
import { loginSuccess } from "../../reducers/userSlice";

const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.userProfile.isAuthenticated);
    const errors = useSelector(state => state.errors);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        termsAndCond: false
    });

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);

    const handleFormChange = e => {
        if (errors[e.target.name]) {
            dispatch(setErrors({
                ...errors,
                [e.target.name]: null,
            }));
        }
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            termsAndCond: false
        });
    };

    const handleFormSubmit = async e => {
        e.preventDefault();
        // TODO: Add frontend validation
        if (formData.password.length < 8) {
            dispatch(setErrors({
                password: "Password should be at least 8 characters"
            }));
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            dispatch(setErrors({
                confirmPassword: "Passwords not matched"
            }));
            return;
        }

        const resData = await register({
            name: formData.name,
            email: formData.email,
            password: formData.password,
        });
        if (resData) {
            localStorage.setItem('RetailAccessToken', resData.tokens.access.token);
            localStorage.setItem('RetailRefreshToken', resData.tokens.refresh.token);
            dispatch(setErrors({}));
            dispatch(loginSuccess(resData.user));
        }
    }

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-45 h-20 mr-2" src={companylogo} alt="logo" />
                    {/* Sundheep  */}
                </Link>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Create an account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
                            {/* Toogle */}
                            <>
                                <label className='themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center'>
                                    <input
                                        type='checkbox'
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className='sr-only'
                                    />
                                    <span className='label flex items-center text-sm font-medium text-black'>
                                        Enterprise Customer
                                    </span>
                                    <span
                                        className={`slider mx-4 flex h-8 w-[60px] items-center rounded-full p-1 duration-200 ${isChecked ? 'bg-primary1' : 'bg-terinary'
                                            }`}
                                    >
                                        <span
                                            className={`dot h-6 w-6 rounded-full bg-white duration-200 ${isChecked ? 'translate-x-[28px]' : ''
                                                }`}
                                        ></span>
                                    </span>
                                    <span className='label flex items-center text-sm font-medium text-black'>
                                        Retail Customer
                                    </span>
                                </label>
                            </>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Enter your name"
                                value={formData.name}
                                onChange={handleFormChange}
                                isInvalid={!!errors?.name}
                                error={errors?.name}
                            />
                            <Input
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="Enter your email"
                                value={formData.email}
                                onChange={handleFormChange}
                                isInvalid={!!errors?.email}
                                error={errors?.email}
                            />
                            <Input
                                type="phone"
                                name="phoneNumber"
                                label="Phone Number"
                                placeholder="Enter your phone number"
                                value={formData.phoneNumber}
                                onChange={handleFormChange}
                                isInvalid={!!errors?.phoneNumber}
                                error={errors?.phoneNumber}
                                isOptional={true}
                            />
                            <Input
                                type="password"
                                name="password"
                                label="Password"
                                placeholder="••••••••"
                                value={formData.password}
                                onChange={handleFormChange}
                                isInvalid={!!errors?.password}
                                error={errors?.password}
                            />
                            <Input
                                type="password"
                                name="confirmPassword"
                                label="Confirm Password"
                                placeholder="••••••••"
                                value={formData.confirmPassword}
                                onChange={handleFormChange}
                                isInvalid={!!errors?.confirmPassword}
                                error={errors?.confirmPassword}
                            />
                            <div className="flex items-center h-5">
                                <input type="radio" name="" id="" />
                                <label
                                    htmlFor="terms"
                                    className="font-light text-gray-500 dark:text-gray-300"
                                >Enterprise</label>
                                <input type="radio" name="" id="" />
                                <label
                                    htmlFor="terms"
                                    className="font-light text-gray-500 dark:text-gray-300"
                                >Retail</label>
                                {/* <div className="flex items-center h-5">
                                    <input
                                        id="terms"
                                        name="termsAndCond"
                                        aria-describedby="terms"
                                        type="checkbox"
                                        className="accent-primary1 w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary1 dark:ring-offset-gray-800 "
                                        checked={formData.termsAndCond}
                                        onChange={() => setFormData({ ...formData, termsAndCond: !formData.termsAndCond })}
                                    />
                                </div> */}

                            </div>
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input
                                        id="terms"
                                        name="termsAndCond"
                                        aria-describedby="terms"
                                        type="checkbox"
                                        className="accent-primary1 w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary1 dark:ring-offset-gray-800 "
                                        checked={formData.termsAndCond}
                                        onChange={() => setFormData({ ...formData, termsAndCond: !formData.termsAndCond })}
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label
                                        htmlFor="terms"
                                        className="font-light text-gray-500 dark:text-gray-300"
                                    >
                                        I accept the <Link className="font-medium text-primary1 hover:underline dark:text-primary1" to="#">Terms and Conditions</Link>
                                    </label>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-primary1 hover:bg-primary1 focus:ring-4 focus:outline-none focus:ring-primary1 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary1 dark:hover:bg-primary1 dark:focus:ring-primary1 disabled:bg-gray-500"
                                disabled={!formData.termsAndCond}
                            >Create an account</button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Already have an account? <Link to="/signin" className="font-medium text-primary1 hover:underline dark:text-primary-500">Login here</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default Registration;