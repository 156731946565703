import React from 'react';
import ContactCard from "./ContactUsCard";
import BreadCrumbs from '../../components/BreadCrumbs';
import { APP_ROUTES } from 'constants/routes';

const PaymentTerms = () => {
    return (
        <div>
            <header>
                <title>SUNDHEEP PRODUCTS - Payment Terms</title>
                <meta name="description" content="A leading player in food product manufacturing and agricultural trading." />
            </header>
            <BreadCrumbs path={APP_ROUTES.PAYMENT_TERMS} />
            <div className='flex flex-1 mx-auto pb-5'>
                <div className='flex flex-col space-x-1 space-y-3 mx-auto'>
                    <div className='justify-center items-center text-justify'>
                        <h1 className='font-bold text-xl text-center space-y-1'>Payment Terms</h1>
                        <p>At SUN DHEEP PRODUCTS, we are committed to delivering high-quality products to our customers at the most competitive prices. To maintain our promise of exceptional value, we currently do not accept returns or exchanges on any products.</p>
                    </div>
                </div>
                <div className='mt-6 space-y-5'>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Why We Don't Accept Returns or Exchanges</h3>
                        <p>
                            Our primary focus is on providing you with the best quality products at the lowest possible prices. To achieve this, we carefully source, process, and package each item to meet our rigorous standards. By not accepting returns or exchanges, we are able to keep our operational costs low, allowing us to pass these savings on to you, our valued customers.
                        </p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Minimizing Costs for Our Customers</h3>
                        <p>Returns and exchanges involve additional costs, such as transportation and delivery partner charges, which can significantly impact the overall price of our products. By eliminating these costs, we ensure that you continue to receive the best value for your money without any hidden fees or inflated prices.</p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Our Quality Commitment</h3>
                        <p>We stand by the quality of every product we offer. Each item is thoroughly inspected before it is shipped to ensure it meets our high standards. In the unlikely event that you receive a damaged or defective product, please contact our customer support team immediately, and we will work with you to resolve the issue promptly.</p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Contact Us</h3>
                        <p>If you have any questions or concerns about your order, please don't hesitate to reach out to our customer support team at sundheepproducts@gmail.com or call us at +91 9090 9494 84. We are here to assist you and ensure that your experience with SUN DHEEP PRODUCTS is a positive one.</p>
                        <p>Thank you for your understanding and for choosing SUN DHEEP PRODUCTS.</p>
                    </section>
                </div>
            </div>
            <div className='ml-8'>
                <ContactCard />
            </div>
        </div>
    );
}

export default PaymentTerms;
