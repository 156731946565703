import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplayRazorpay from "components/Body/Payments/Razorpay";
import Stepper from "pages/OrderJourney/Stepper";
import CartAmountBreakup from "./CartAmountBreakup";
import CartReview from "./CartReview";
import Address from "./Address";
import "../../components/Body/CartPage/CartPage.css";
import OrderModal from "components/Body/CartPage/OrderModal";
import SuccessIcon from '@mui/icons-material/CheckCircle';
import FailIcon from '@mui/icons-material/Cancel';

const CartPage = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
    const navigate = useNavigate();

    const handleNext = async () => {
        if (activeStep === 1) {
            await DisplayRazorpay(handleOrderSuccess, handleOrderFail);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };


    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        type: '',
        icon: '',
        title: '',
        message: '',
        buttonText: '',
        handleClose: () => { },
        handleButtonClick: () => { }
    });

    const handleOrderSuccess = () => {
        setModalContent({
            type: 'success',
            icon: <SuccessIcon className="!text-[80px] !text-green-700" />,
            title: 'Order Successful!',
            message: 'Your order has been successfully processed.',
            buttonText: 'Countinue Shopping',
            handleClose: () => { handleCloseModal(); navigate('/orders') },
            handleButtonClick: () => { handleCloseModal(); navigate('/') }
        });
        setModalOpen(true);
    };

    const handleOrderFail = () => {
        setModalContent({
            type: 'fail',
            icon: <FailIcon className="!text-[80px] !text-red-500" />,
            title: 'Order Failed',
            message: 'There was an error processing your order.',
            buttonText: 'Try Again',
            handleClose: () => handleCloseModal(),
            handleButtonClick: () => handleCloseModal()
        });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    return (
        <div>
            <div className="mt-[1rem]"><Stepper activeStep={activeStep} setActiveStep={setActiveStep} /></div>
            <div className="cartpage-container">
                <div className="flex items-start justify-around py-6 gap-6">
                    <div style={{ width: "70%" }}>
                        {activeStep === 0 && <CartReview />}

                        {activeStep === 1 && <Address />}
                    </div>
                    <CartAmountBreakup
                        handleCheckout={handleNext}
                        activeStep={activeStep}
                        className="ml-auto w-full space-y-6 rounded-lg border border-gray-100 bg-gray-50 p-4 sm:mt-8 md:p-6 md:mt-14 lg:max-w-sm xl:max-w-md"
                    />
                </div>
            </div>

            {
                activeStep === 2
                //&&
                //<h1> under construction</h1>
                // <div className="payment-container">
                //   <div className="payment-form">
                //     <h2>Select Payment Method</h2>
                //     {/* Radio buttons for payment method selection */}
                //     <label>
                //       <input
                //         type="radio"
                //         value="upi"
                //         checked={selectedPaymentMethod === 'upi'}
                //         onChange={handlePaymentMethodChange}
                //       />
                //       UPI
                //       <div>
                //         <div>Choose an option</div>
                //         <div>
                //           <label>
                //             <input
                //               type="radio"
                //               value="upi"
                //               checked={selectedPaymentMethod === 'upi'}
                //               onChange={handlePaymentMethodChange}
                //             />
                //             PhonePe
                //           </label>
                //         </div>
                //         <div>
                //           <label>
                //             <input
                //               type="radio"
                //               value="upi"
                //               checked={selectedPaymentMethod === 'upi'}
                //               onChange={handlePaymentMethodChange}
                //             />
                //             Paytm
                //           </label>
                //         </div>
                //       </div>
                //     </label>
                //     <label>
                //       <input
                //         type="radio"
                //         value="wallet"
                //         checked={selectedPaymentMethod === 'wallet'}
                //         onChange={handlePaymentMethodChange}
                //       />
                //       Wallet
                //     </label>
                //     <label>
                //       <input
                //         type="radio"
                //         value="credit_card"
                //         checked={selectedPaymentMethod === 'credit_card'}
                //         onChange={handlePaymentMethodChange}
                //       />
                //       Credit Card / Debit Card
                //     </label>
                //     <label>
                //       <input
                //         type="radio"
                //         value="paypal"
                //         checked={selectedPaymentMethod === 'paypal'}
                //         onChange={handlePaymentMethodChange}
                //       />
                //       PayPal
                //     </label>
                //     <label>
                //       <input
                //         type="radio"
                //         value="net_banking"
                //         checked={selectedPaymentMethod === 'net_banking'}
                //         onChange={handlePaymentMethodChange}
                //       />
                //       Net Banking
                //     </label>
                //     <label>
                //       <input
                //         type="radio"
                //         value="cash_on_delivery"
                //         checked={selectedPaymentMethod === 'cash_on_delivery'}
                //         onChange={handlePaymentMethodChange}
                //       />
                //       Cash on Delivery
                //     </label>
                //     {/* Display the selected payment method */}
                //     <p>Selected Payment Method: {selectedPaymentMethod}</p>
                //   </div>
                // </div>
            }
            <OrderModal
                isOpen={modalOpen}
                type={modalContent.type}
                icon={modalContent.icon}
                title={modalContent.title}
                message={modalContent.message}
                buttonText={modalContent.buttonText}
                handleClose={modalContent.handleClose}
                handleButtonClick={modalContent.handleButtonClick}
            />
        </div>
    );
};

export default CartPage;
