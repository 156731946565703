import './SearchBar.css'
import { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_ROUTES } from 'constants/routes';
import { getProducts } from 'services/product.service';

const SearchBar = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const categories = useSelector(state => state.categories.categories).filter(category => category.level === 1)
    const [searchText, setSearchText] = useState('');
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [productSuggestion, setProductSuggestion] = useState(null)
    const [searchCategory, setSearchCategory] = useState('All categories');
    const [showDropdown, setShowDropdown] = useState(false);

    const resetToInitialState = () => {
        setSearchText('');
        setShowSuggestion(false);
        setProductSuggestion(null);
    }
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const handleOutsideClick = (e) => {
        if (e.target.id !== 'search-dropdown') {
            setShowSuggestion(false);
        }
    };
    const handleSearch = (e) => {
        if (!e?.target?.value) {
            setSearchText('');
            setShowSuggestion(false);
            setProductSuggestion(null);
        }
        else {
            setSearchText(e?.target?.value);
            setShowSuggestion(true);
            getProducts({ q: e?.target?.value, pageSize: 10 }).then((res) => {
                setProductSuggestion(res.data)
            })
        }
    };

    const redirectToPLP = useCallback((e = {}) => {
        e?.preventDefault?.();
        const params = new URLSearchParams(searchParams);
        if (searchText) params.set('q', searchText);
        else {
            params.delete('q');
        }
        navigate(`${APP_ROUTES.PRODUCTS}?${params.toString()}`)
        //reset state
        setShowSuggestion(false);
        setProductSuggestion(null);
        inputRef.current.blur();
    }, [searchText])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            redirectToPLP(e);
        }
    };

    const handleSelectItem = (productTitle) => {
        setSearchText(productTitle);
        setShowSuggestion(false);
    };

    const toggleDropdown = (category) => {
        setSearchCategory(category);
        setShowDropdown(false);
    };

    return (
        <div style={{ width: '500px' }}>
            <form className="max-w-lg mx-auto" onClick={handleOutsideClick}>
                <div className="flex">
                    <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                        Your Email
                    </label>
                    <button
                        id="dropdown-button"
                        data-dropdown-toggle="dropdown"
                        style={{ minWidth: '140px', maxWidth: '180px' }}
                        className="flex-shrink-0 z-10 inline-flex items-center justify-between py-2.5 px-4 text-sm font-medium text-center text-gray-900 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-green-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                        type="button"
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <span style={{ marginRight: '1rem' }}>{searchCategory}</span>
                        <svg
                            className="w-2.5 h-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 4 4 4-4"
                            />
                        </svg>
                    </button>

                    <div className="relative w-full">
                        <input
                            ref={inputRef}
                            type="search"
                            id="search-dropdown"
                            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                            placeholder="Search Groceries..."
                            value={searchText}
                            onChange={handleSearch}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                        />
                        {showSuggestion && searchText && (
                            <ul className='autocomplete-suggestions absolute top-full left-0 z-20 w-full bg-white border border-gray-300 rounded-b-lg shadow-md divide-y divide-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:divide-gray-600'>
                                {productSuggestion?.length === 0 ? (
                                    <li className="p-2.5 text-sm focus:ring-blue-500 focus:border-blue-500 theme-bg-color">No results found</li>
                                ) : (productSuggestion?.map((product, index) => (
                                    <li key={product.id} className="p-2.5 text-sm focus:ring-blue-500 focus:border-blue-500 theme-bg-color" onClick={() => handleSelectItem(product.title)}>
                                        <Link
                                            key={index}
                                            to={`${APP_ROUTES.PRODUCTS}/${product?.id}`}
                                        >
                                            {product.title}
                                        </Link>
                                    </li>
                                )))}
                            </ul>
                        )}
                        <button
                            type="submit"
                            className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-color-search-bt dg-border-color rounded-e-lg border"
                            onClick={redirectToPLP}
                        >
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </div>
                </div>
                <div id="dropdown" ref={dropdownRef} className={`absolute z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ${showDropdown ? '' : 'hidden'}`}>
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                        {categories.map((category, index) => (
                            <li key={category.id}>
                                <button
                                    type="button"
                                    className="inline-flex w-full px-4 py-2 bg-color-category-dd dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => toggleDropdown(category.name)}
                                >
                                    {category.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;
