import React from "react";
import { Carousel } from "flowbite-react";
import CategoryListingSlider from "components/CategoryListingSlider";
import BrandsBanner from "components/BrandsBanner";
import {
  FeaturedProducts,
  DealOfTheWeek,
  TrendingProducts,
} from "components/common/sections";
import NewsAndBlogs from "components/common/sections/NewsAndBlogs";

const HomePage = () => {
  const staticImages = [
    "https://storage.googleapis.com/sundheep-storage/static/image-1.jpeg",
    "https://storage.googleapis.com/sundheep-storage/static/image-2.jpeg",
    "https://storage.googleapis.com/sundheep-storage/static/image-3.jpeg",
    "https://storage.googleapis.com/sundheep-storage/static/image-4.jpeg",
    "https://storage.googleapis.com/sundheep-storage/static/image-5.jpeg",
    "https://storage.googleapis.com/sundheep-storage/static/image-6.jpeg"
  ];

  return (
    <div>
      {/* Category Listing */}
      <CategoryListingSlider />
      <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
        <Carousel pauseOnHover>
          {staticImages.map((image, index) => (
            <img key={index} src={image} alt={`image_${index + 1}`} className="h-56 sm:h-64 xl:h-80 2xl:h-96" />
          ))}
        </Carousel>
      </div>

      {/* Featured products */}
      <FeaturedProducts />
      <DealOfTheWeek />
      <TrendingProducts />
      <BrandsBanner></BrandsBanner>
      {/* Blogs */}
      <NewsAndBlogs />
    </div>
  );
};

export default HomePage;
