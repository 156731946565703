import './Navigation.css'
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FlowBiteDropDown } from "../common/DropDown";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";

const Navigation = () => {
  const categories = useSelector(state => state.categories.categories);
  const [categoriesMap, setCategoriesMap] = useState([]);

  useEffect(() => {
    let levelOneCategories = categories.filter(category => category.level === 1);
    levelOneCategories.sort((cat1, cat2) => cat1.priority - cat2.priority);

    let levelTwoCategories = []
    categories.forEach(category => {
      if (category.level === 2 && category.parent) {
        if (!levelTwoCategories[category.parent]) {
          levelTwoCategories[category.parent] = [];
        }
        levelTwoCategories[category.parent].push(category);
      }
    });
    levelTwoCategories.forEach(categories => {
      categories.sort((cat1, cat2) => cat1.priority - cat2.priority);
    });

    let categoriesMap = levelOneCategories.map(levelOneCat => ({
      label: levelOneCat.name,
      slug: levelOneCat.slug,
      id: levelOneCat.id,
      options: levelTwoCategories[levelOneCat.id]?.map(levelTwoCat => ({
        label: levelTwoCat.name,
        slug: levelTwoCat.slug,
        id: levelTwoCat.id,
        link: `/categories?category=${levelTwoCat.slug}`
      })),
    }));
    categoriesMap = categoriesMap.filter(category => category.options);

    setCategoriesMap(categoriesMap);
  }, [categories]);

  return (
    <div className="bg-body-bgColor">
      <nav className="hidden lg:block mx-5 my-4 bg-gray-50 dark:bg-gray-700 border border-solid rounded">
        <div className="flex justify-between pl-4 mx-0">
          <div className="flex items-center justify-between">
            {categoriesMap.length > 0 && categoriesMap?.map(category => (
              <div
                style={{ padding: '0rem 0.5rem', height: '-webkit-fill-available', display: 'flex', alignItems: 'center' }} className="theme-color"
                key={category.id}
              >
                <FlowBiteDropDown name={category.label} options={category.options} />
              </div>
            ))}
          </div>

          <div className="flex items-center">
            <div className="flex pr-4 text-base break-word">
              <DiscountOutlinedIcon className="text-primary" />
              <a href="/discounts" className="px-2 hover:text-primary">
                Discount Products!
              </a>
            </div>
            <div className="bg-red-600 flex items-center text-white pr-4 rounded-l-full rounded-r">
              <PhoneInTalkOutlinedIcon className="mx-4 h-29 w-14" />
              <div className="flex-col text-base">
                <div className="font-light flex items-center justify-center">Contact Us</div>
                <div className="font-medium">+91 1800 202 6246</div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
