import { useJitsu } from "@jitsu/jitsu-react"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"

export const ChildComponent = () => {
  const { analytics } = useJitsu()
  const location = useLocation()
  return useEffect(() => {
    analytics.page()
  }, [location])
}
