import React from 'react';
import { Stack, Chip } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InsertCommentIcon from '@mui/icons-material/InsertComment';

const BlogCard = ({ blog }) => {
    const { heading, content, image, categories, createdAt, commentsCount } = blog;

    return (
        <div className="product-section">
            <div className="blog-card">
                <div>
                    <img
                        src={image}
                        alt={heading}
                        className="blog-image-card zoom-effect"
                    />
                </div>
                <div className="blog-chip">
                    <Stack direction="row" spacing={1}>
                        {categories.map((category, index) => (
                            <Chip
                                key={index}
                                label={category}
                                size="small"
                                variant="outlined"
                                className="chip"
                            />
                        ))}
                    </Stack>
                </div>
                {/* <div className="relative group">
                    <div className="text-xl font-semibold my-2 text-left overflow-hidden text-ellipsis line-clamp-1 group-hover:line-clamp-none group-hover:whitespace-normal">
                        {heading}
                    </div>
                    <div className="text-md font-thin overflow-hidden text-ellipsis text-justify mb-6 line-clamp-4">
                        {content}
                    </div>
                </div> */}
                <div className="relative group">
                    <div className="text-xl font-semibold my-2 text-left overflow-hidden text-ellipsis line-clamp-2 transition-all duration-300 group-hover:line-clamp-none group-hover:whitespace-normal">
                        {heading}
                    </div>
                    <div className="text-md font-thin overflow-hidden text-ellipsis text-justify mb-6 transition-all duration-300 line-clamp-5">
                        {content}
                    </div>
                </div>


                <div
                    className="flex text-[14px] justify-between"
                >
                    <div className='flex'>
                        <div>
                            <CalendarTodayIcon className="calendar-icon" />
                        </div>
                        <div className="blog-date">
                            {new Date(createdAt).toLocaleDateString()}
                        </div>
                    </div>
                    <div className='flex'>
                        <div>
                            <InsertCommentIcon className="comment-icon" />
                        </div>
                        <a href="#commentsSection" className="blog-comment">
                            {commentsCount ?? 0}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;
