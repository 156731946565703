import SortIcon from '@mui/icons-material/Sort';
import { Dropdown } from 'flowbite-react';
import './DropDown.css'
import { useNavigate } from 'react-router-dom';


const DropDown = ({
    name = "Dropdown",
    values = []
}) => {
    return (
        <div className="flex items-center w-16">
            <button id="multiLevelDropdownButton" data-dropdown-toggle="multi-dropdown" className=" border border-gray-200 text-primary bg-white hover:bg-primary-800 hover:text-black focus:ring-2 focus:ring-primary font-medium rounded-full text-sm p-1 text-center inline-flex items-center dark:hover:text-white dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 gap-2" type="button">
                <SortIcon></SortIcon>
                <p className='text-gray-800'>{name}</p>
                <svg className="w-5 h-1.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
            </button>
            <div id="multi-dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                <ul className="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="multiLevelDropdownButton">
                    <li>
                        <button id="doubleDropdownButton" data-dropdown-toggle="doubleDropdown" data-dropdown-placement="right-start" type="button" className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dropdown
                            <svg className="w-5 h-1.5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </button>
                        <div id="doubleDropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButton">
                                <li>
                                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Overview</a>
                                </li>
                                <li>
                                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">My downloads</a>
                                </li>
                                <li>
                                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Billing</a>
                                </li>
                                <li>
                                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Rewards</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    {
                        values.map((value, index) => (
                            <li>
                                <a href={value.link} key={index} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{value.name}</a>
                            </li>
                        ))
                    }
                </ul>

            </div>


        </div>
    )
};

export const FlowBiteDropDown = ({ options, name }) => {
    const navigate = useNavigate();

    return (
        <Dropdown
            id='dropdown-menu'
            label={name}
            dismissOnClick={false}
            inline
            trigger="hover"
            className='mt-4 dropdown-position'
        >
            {options.map((option, index) => (
                <Dropdown.Item
                    key={option.id}
                    className='theme-bg-color'
                    onClick={() => navigate(option.link)}
                >
                    {option.label}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
}

export default DropDown;