import React, { useMemo, useState } from "react";
import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import chilli from "assets/categories/dry-red-chilli.png";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { renderStars } from "utils/CommonUtils";
import { Link } from "react-router-dom";
import useCategoryById from "hooks/useCategoryById";

import "./cards.css";
import { APP_ROUTES } from "constants/routes";
import QuantityControl from "components/QuantityControl";

const PLPCard = ({ product, itemCountInCart }) => {
  const [rating] = useState(2.5);
  const category = useCategoryById(product?.category);
  const [selectedSize, setSelectedSize] = useState(product?.sizes?.[0]);

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };
  const getDiscount = useMemo(() => {
    if (selectedSize?.pricing?.esp !== selectedSize?.pricing?.mrp) {
      const discount =
        ((selectedSize?.pricing?.mrp - selectedSize?.pricing?.esp) /
          selectedSize?.pricing?.mrp) *
        100;
      return Math.round(discount);
    }
    return 0;

  }, [selectedSize]);

  return (
    <Link to={`${APP_ROUTES.PRODUCTS}/${product?.id}`}>
      <div className="product-card flex flex-col">
        <div className="category-wishlist mb-1">
          <div className="category">{category?.name}</div>
          {/* <div>
            <FavoriteBorderTwoToneIcon className="wishlist" />
          </div> */}
        </div>
        <div className="zoom-effect rounded">
          <img
            src={product?.media?.[1] ?? chilli}
            alt="veggie"
            className="product-img"
          ></img>
        </div>
        <div className="product-description">
          <Stack className="mt-3" direction="row" spacing={1}>
            {product?.sizes?.map?.((size) => (
              <Chip
                key={size?.identifiers?.[0]?.value ?? size.name}
                label={size?.name ?? ""}
                size="small"
                variant={
                  selectedSize.name === size.name ? "filled" : "outlined"
                }
                onClick={() => handleSizeClick(size)}
              />
            ))}
          </Stack>

          <div className="product-price">
            <div className="product-offer-price">
              ₹{selectedSize?.pricing?.esp ?? "135"}
            </div>
            {selectedSize?.pricing?.esp !== selectedSize?.pricing?.mrp ||
              true ? (
              <div className="product-original-price">
                ₹{selectedSize?.pricing?.mrp ?? "150"}
              </div>
            ) : null}
          </div>
          <div className="product-discount">
            <div>
              <LocalOfferIcon className="discount-icon" />
            </div>
            <div className="discount-percent">{getDiscount}%</div>
          </div>
          <div>
            <div className="product-name">{product.name}</div>
          </div>
          <div className="product-rating">
            {renderStars(rating)}
            <div className="rating-number">({rating.toFixed(1)})</div>
          </div>
        </div>
        <div id="footer-cta" className="mt-2 flex-1 content-end w-full">
          <QuantityControl
            itemCountInCart={itemCountInCart}
            product={product}
          />
        </div>
      </div>
    </Link>
  );
};

export default PLPCard;
