import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./ProductDetails.css";
import { renderStars } from "../../../utils/CommonUtils";
import ShareIcon from "@mui/icons-material/Share";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import veg_png from "../../../assets/veg_png.png";
import non_veg_png from "../../../assets/non_veg.png";
import { userReviewCard } from "../Cards/Cards";
import CustomizedProgressBars from "./RatingProgressBar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BreadCrumbs from "../../BreadCrumbs";
import chillies from "../../../assets/categories/dry-red-chilli.png";
import logo from "../../../assets/company/logo.png";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PLPCard, PackSizeCard } from "components/cards";
import { getProductDetail, productServiceable } from "services/product.service";
import { APP_ROUTES } from "constants/routes";
import QuantityControl from "components/QuantityControl";


const SOCIAL_MEDIA_LIST = [
  {
    title: "Copy link",
    icon: <ContentCopy />,
  },
  {
    title: "Whatsapp",
    icon: <WhatsAppIcon />,
    link: "https://wa.me/919876543210",
  },
  {
    title: "Facebook",
    icon: <FacebookIcon />,
    link: "XXXXXXXXXXXXXXXXXXXXXXXX",
  },
  {
    title: "Twitter",
    icon: <XIcon />,
    link: "XXXXXXXXXXXXXXXXXXXXXXXXX",
  },
  {
    title: "Pinterest",
    icon: <PinterestIcon />,
    link: "XXXXXXXXXXXXXXXXXXXXXXXXX",
  },
];

const ProductDetails = () => {
  const { cartDetail } = useSelector((state) => state.userProfile);
  const { products: tempCataloge = [] } = useSelector(
    (state) => state.products
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [product, setProduct] = useState(null);
  const [serviceablityInfo, setServiceabilityInfo] = useState(null)
  const [selectedSize, setSelectedSize] = useState(null);

  let { productId } = useParams();
  useEffect(() => {
    const promises = [getProductDetail(productId), productServiceable()]
    Promise.allSettled(promises).then(([res1, res2]) => {
      //handle product api
      if (res1.status === 'fulfilled') {
        setProduct(res1?.value?.data);
        setSelectedSize(res1?.value?.data?.sizes?.[0]);
      } else {
        setIsError(true);
      }

      //handle serviceablity api
      if (res2.status === 'fulfilled') {
        setServiceabilityInfo(res2?.value)
      } else {
        console.log(res2, 'Failed')
      }
    }).catch((err) => {
      console.log('PDP', err)
      setIsError(true);
    }).finally(() => {
      console.log('finally')
      setIsLoading(false);
    })
  }, [productId]);

  const handleSizeSelection = useCallback((size) => {
    console.log("Size selected", size);
    setSelectedSize(size);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [offerOpen, setOfferOpen] = useState(false);
  const [bankOfferOpen, setBankOfferOpen] = useState(false);
  const [couponOpen, setCouponOpen] = useState(false);
  const [descActive, setDescActive] = useState(true);
  const [reviewActive, setReviewActive] = useState(false);
  const [quantity, setQuantity] = useState("");
  const isItVegProduct = true;

  const images = [
    chillies,
    chillies,
    chillies,
    logo,
    // Add more image URLs as needed
  ];
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const offersDesc = [
    "Special PriceGet extra ₹4860 off (price inclusive of cashback/coupon)T&C",
    "Bank Offer10% off on HSBC Bank Credit Card and EMI Transactions, up to ₹1,500 on orders of ₹5,000 and aboveT&C",
    "Bank OfferExtra ₹500 off on HSBC Bank Credit Card EMI Transactions on tempCataloge priced ₹24,990 and aboveT&C",
  ];

  const productDetailsTableData = [
    { label: "Variety", value: "355" },
    { label: "Length", value: "As per grade" },
    { label: "Quality", value: "Deluxe" },
    { label: "Foreign material", value: "1% Max" },
    { label: "Chilli Type Available", value: "Stem" },
    { label: "Broken chilies", value: "3% Max" },
    { label: "HSN Code", value: "9042110" },
    { label: "Loose seeds", value: "2% Max" },
    { label: "Storage Instructions", value: "Cool" },
    { label: "Defectives", value: "3% Max" },
    { label: "Moisture", value: "14% Maximum" },
    { label: "Procurement", value: "Guntur" },
    { label: "Packaging Size", value: "40 Kg / Gunny" },
    { label: "Shelf Life", value: "1 Year" },
  ];

  const productDetailsAddInfoData = [
    { label: "ASIN", value: "B084884GKX" },
    { label: "Customer Reviews", value: "4.2 out of 5 stars (59,079 ratings)" },
    {
      label: "Best Sellers Rank",
      value: "#13 in Grocery & Gourmet Foods\n#1 in Almonds",
    },
    { label: "Date First Available", value: "23 January 2020" },
    { label: "Manufacturer", value: "Sundheep Pvt Ltd" },
    { label: "Packer", value: "Sundheep Pvt. Ltd" },
    {
      label: "Importer",
      value:
        "Sundheep Pvt. Ltd. 17/A1, Maruti Arcade, Yeswanthpur, Bengaluru, Karnataka 560022",
    },
    { label: "Item Weight", value: "500 g" },
    { label: "Item Dimensions LxWxH", value: "15 x 4 x 23 Centimeters" },
    { label: "Included Components", value: "Almonds" },
    { label: "Generic Name", value: "Almond" },
  ];

  const starRatings = [
    { stars: 1, count: 3 },
    { stars: 2, count: 2 },
    { stars: 3, count: 4 },
    { stars: 4, count: 4 },
    { stars: 5, count: 7 },
  ];
  const getDiscount = useMemo(() => {
    return () => {
      if (product?.pricing?.esp !== product?.pricing?.mrp) {
        const discount =
          ((product?.pricing?.mrp - product?.pricing?.esp) /
            product?.pricing?.mrp) *
          100;
        return Math.round(discount);
      }
      return 0;
    };
  }, [product]);

  const shareClickHandler = (event) => {
    console.log("Share clicked", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const shareCloseHandler = () => {
    setAnchorEl(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const getItemCountInCart = useMemo(() => cartDetail?.items?.find((i) => i.id === product?.id)?.quantity ?? 0, [cartDetail, product]
  );

  const divider = useMemo(() => {
    return <hr className="border-t border-t-solid border-t-lightgrey" />;
  }, []);

  const productImages = useMemo(() => {
    return (
      <>
        <div className="p-d-image">
          <img
            src={product?.media?.[0]}
            alt="Product Image"
            style={{ height: "450px", objectFit: "contain" }}
          />
        </div>
        <div className="flex" style={{ width: "100%" }}>
          {product?.media?.map?.((image, index) => (
            <div className="p-d-image-row">
              <img
                key={`pdp-img-${index}`}
                src={image}
                alt={`prod-pic ${index}`}
                onClick={() => handleImageClick(image)}
                style={{ height: "100px", objectFit: "contain" }}
              />
            </div>
          ))}
        </div>
      </>
    );
  }, [product?.media]);

  const productPriceSection = useMemo(() => {
    return (
      <>
        <Table style={{ width: "200px" }}>
          <TableBody>
            <TableRow>
              <td>
                <div className="p-d-mrp">M.R.P:</div>
              </td>
              <td>
                <div
                  className="p-d-mrp-num"
                  style={{
                    textDecoration: "line-through",
                    fontSize: "16px",
                  }}
                >
                  ₹ {product?.pricing?.mrp}
                </div>
              </td>
            </TableRow>
            <TableRow>
              <td>
                <div className="p-d-price">Price:</div>
              </td>
              <td className="flex" style={{ alignItems: "baseline" }}>
                <div
                  className="p-d-price-num"
                  style={{
                    fontSize: "22px",
                    color: isItVegProduct ? "green" : "#B12704",
                  }}
                >
                  ₹{product?.pricing?.esp}
                </div>
                <div
                  className="p-d-price-per-gm"
                  style={{
                    fontSize: "14px",
                    color: isItVegProduct ? "green" : "#B12704",
                  }}
                >
                  &nbsp;(₹{product?.pricing?.costPerItem} / item)
                </div>
              </td>
            </TableRow>
            <TableRow>
              <td>
                <div className="p-d-you-save">You Save:</div>
              </td>
              <td className="flex" style={{ alignItems: "baseline" }}>
                <div
                  className="p-d-you-save-num"
                  style={{
                    fontSize: "16px",
                    color: isItVegProduct ? "green" : "#B12704",
                  }}
                >
                  {product?.pricing?.mrp - product?.pricing?.esp}
                </div>
                <div
                  className="p-d-you-save-dis"
                  style={{
                    fontSize: "14px",
                    color: isItVegProduct ? "green" : "#B12704",
                  }}
                >
                  &nbsp; {getDiscount}%
                </div>
              </td>
            </TableRow>
          </TableBody>
        </Table>
        <div
          style={{
            fontSize: "14px",
            textAlign: "start",
            paddingLeft: "70px",
            marginBottom: "20px",
          }}
        >
          inclusive of all taxes
        </div>
      </>
    );
  }, [selectedSize, product]);

  const productDetails = useMemo(() => {
    return (
      <div>
        <BreadCrumbs
          path={`${APP_ROUTES.PRODUCTS}/${product?.name?.replaceAll(
            "/",
            ", "
          )}`}
        />
        <div
          ariaLabel="product container"
          className="flex gap-6 p-2.5 flex-col md:flex-row"
        >
          <div ariaLabel="image and size selection" className="md:w-[30%]">
            {productImages}

            <div className="mt-5">
              <h2 className="font-semibold">Select Pack</h2>

              <div className="mt-2 max-w-full flex flex-wrap justify-between gap-2">
                <PackSizeCard
                  size={{ ...selectedSize, pricing: product?.pricing }}
                  onClick={() => {
                    handleSizeSelection("pass size");
                  }}
                  className="w-[48%]"
                />
                <PackSizeCard
                  size={{ ...selectedSize, pricing: product?.pricing }}
                  onClick={() => {
                    handleSizeSelection("pass size");
                  }}
                  className="w-[48%]"
                />
                <PackSizeCard
                  size={{ ...selectedSize, pricing: product?.pricing }}
                  onClick={() => {
                    handleSizeSelection("pass size");
                  }}
                  className="w-[48%]"
                />
              </div>
            </div>
          </div>
          <div ariaLabel="description" className="md:w-[50%]">
            <div className="text-[30px] font-medium overflow-hidden text-ellipsis text-left line-clamp-2">
              {product?.name}
            </div>
            <div className="p-d-rating">
              <div className="p-d-rating-number">4.5</div>
              <div className="p-d-rating-stars">{renderStars(4.5)}</div>
              <div className="p-d-rating-number">
                &nbsp;&nbsp; (20 ratings) &nbsp; |
              </div>
              <div className="p-d-share">
                <Button
                  variant="text"
                  startIcon={<ShareIcon />}
                  onClick={shareClickHandler}
                  style={{ color: "black" }}
                >
                  Share
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={shareCloseHandler}
                  className="p-d-share-menu"
                >
                  {SOCIAL_MEDIA_LIST.map((item) => {
                    return (
                      <MenuItem key={item.title} onClick={shareCloseHandler}>
                        <div className="p-d-share-option">
                          {item.icon}
                          <div className="p-d-menu-text">{item.title}</div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </div>
            {divider}
            {productPriceSection}
            <div className="flex mb-2.5">
              <img
                src={isItVegProduct ? veg_png : non_veg_png}
                alt={isItVegProduct ? veg_png : non_veg_png}
                width={"24px"}
                height={"24px"}
              ></img>
              <p style={{ fontSize: "14px", paddingLeft: "8px" }}>
                {isItVegProduct
                  ? "This is Vegetarian product"
                  : "This is Non-Vegetarian product"}
              </p>
            </div>
            {divider}
            <div className="p-d-offers">
              {/* <div>
                <LocalOfferIcon className="p-d-offer-icon" />
              </div> */}
              <div style={{ color: "grey", fontWeight: "500" }}>Offers</div>
              <div
                onClick={() => {
                  setOfferOpen(!offerOpen);
                }}
                style={{ marginLeft: "auto" }}
              >
                {offerOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </div>
            {offerOpen && (
              // (offersDesc.length > 0
              //   ? offersDesc.map((of, index) => (
              //       <div key={index} style={{ display: "flex" }}>
              //         <div>
              //           <LocalOfferIcon className="p-d-offer-icon" />
              //         </div>
              //         <div style={{ textAlign: "left", fontSize: "14px" }}>
              //           {of}
              //         </div>
              //       </div>
              //     ))
              //   : "No offers available."
              // )
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <AccountBalanceIcon />
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      textAlign: "left",
                      fontSize: "14px",
                      paddingLeft: "8px",
                      // alignItems: "center",
                    }}
                  >
                    <div style={{ fontWeight: "600", color: "grey" }}>
                      BANK OFFER(S)
                    </div>
                    <div>
                      Enjoy flat Rs.100 off On A Minimum Grocery Value of Rs.
                      600
                    </div>
                    <div>4 Offer(s) Available</div>
                  </div>
                  <div
                    onClick={() => {
                      setBankOfferOpen(!bankOfferOpen);
                    }}
                    style={{ marginLeft: "auto" }}
                  >
                    {bankOfferOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </div>
                </div>
                {bankOfferOpen &&
                  (offersDesc.length > 0
                    ? offersDesc.map((of, index) => (
                      <div key={index} style={{ display: "flex" }}>
                        <div>
                          <LocalOfferIcon className="p-d-offer-icon" />
                        </div>
                        <div style={{ textAlign: "left", fontSize: "14px" }}>
                          {of}
                        </div>
                      </div>
                    ))
                    : "No offers available.")}

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <MoneyOffIcon />
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      textAlign: "left",
                      fontSize: "14px",
                      paddingLeft: "8px",
                      paddingTop: "12px",
                      // alignItems: "center",
                    }}
                  >
                    <div style={{ fontWeight: "600", color: "grey" }}>
                      COUPON(S)
                    </div>
                    <div>Apply coupon, get flat 500 off!</div>
                    <div>4 Offer(s) Available</div>
                  </div>
                  <div
                    onClick={() => {
                      setCouponOpen(!couponOpen);
                    }}
                    style={{ marginLeft: "auto" }}
                  >
                    {couponOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </div>
                </div>
                {couponOpen &&
                  (offersDesc.length > 0
                    ? offersDesc.map((of, index) => (
                      <div key={index} style={{ display: "flex" }}>
                        <div>
                          <LocalOfferIcon className="p-d-offer-icon" />
                        </div>
                        <div style={{ textAlign: "left", fontSize: "14px" }}>
                          {of}
                        </div>
                      </div>
                    ))
                    : "No offers available.")}
              </div>
            )}
            <div className="mt-2.5" />
            {divider}
            <div style={{ margin: "10px 0px", display: "flex" }}>
              <div style={{ width: "90px" }}>
                <LocalShippingOutlinedIcon
                  style={{ width: "32px", height: "32px" }}
                />
                <div style={{ fontSize: "14px" }}>Free Delivery</div>
              </div>
              <div style={{ width: "90px" }}>
                <CachedIcon style={{ width: "32px", height: "32px" }} />
                <div style={{ fontSize: "14px" }}>Non Returnable</div>
              </div>
              <div style={{ width: "90px" }}>
                <AirportShuttleOutlinedIcon
                  style={{ width: "32px", height: "32px" }}
                />
                <div style={{ fontSize: "14px" }}>Sundheep Delivered</div>
              </div>
              <div style={{ width: "90px" }}>
                <PaidOutlinedIcon style={{ width: "32px", height: "32px" }} />
                <div style={{ fontSize: "14px" }}>Cash on Delivery</div>
              </div>
              <div style={{ width: "90px" }}>
                <StarBorderOutlinedIcon
                  style={{ width: "32px", height: "32px" }}
                />
                <div style={{ fontSize: "14px" }}>Top Brand</div>
              </div>
            </div>
            <hr
              style={{ borderTop: "1px solid lightgrey", marginTop: "10px" }}
            />
            <div style={{ margin: "10px 0px" }}>
              <div>
                <div
                  style={{
                    color: "#6a6a6a",
                    fontWeight: "500",
                    display: "flex",
                  }}
                >
                  About this item
                </div>
                {product?.description ?? "No Description available."}
              </div>
            </div>
          </div>
          <div className="p-d-est-time-cost-card md:w-[20%] h-min">
            {
              serviceablityInfo?.expectedDeliveryDate && <div>
                <div>Expected Delivery Date: </div>
                <div style={{ fontWeight: "600", paddingBottom: "5px" }}>
                  {serviceablityInfo?.expectedDeliveryDate}
                </div>
              </div>
            }

            {
              serviceablityInfo?.address && <div>
                <div>Delivery Address:</div>
                <div style={{ fontWeight: "600", paddingBottom: "5px" }}>
                  {serviceablityInfo?.address}
                </div>
              </div>
            }


            {/* <div style={{ marginTop: "10px", width: "100px" }}>
              <FormControl fullWidth>
                <InputLabel id="quantity-select-label">Quantity</InputLabel>
                <Select
                  labelId="quantity-select-label"
                  id="quantity-select"
                  value={quantity}
                  onChange={handleQuantityChange}
                  label="Quantity"
                  sx={{
                    "& .MuiSelect-select": {
                      minHeight: "32px", // Adjust the height as needed
                      minWidth: "48px", // Adjust the width as needed
                    },
                  }}
                >
                  {[...Array(30).keys()].map((num) => (
                    <MenuItem key={num + 1} value={num + 1}>
                      {num + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            {
              serviceablityInfo?.inStock ? <div className="text-primary py-2.5 text-base font-semibold">In Stock</div> : <div className="text-[red] py-2.5 text-base font-semibold">Not serviceable at your location</div>
            }

            <table style={{ paddingBottom: "10px" }}>
              <tbody>
                {
                  serviceablityInfo?.seller && <tr>
                    <td>Ships from: </td>
                    <td>{serviceablityInfo?.seller}</td>
                  </tr>
                }

                {
                  serviceablityInfo?.soldBy && <tr>
                    <td>Sold by: </td>
                    <td>{serviceablityInfo?.soldBy}</td>
                  </tr>
                }
              </tbody>
            </table>
            <div
              className="grid justify-center gap-2.5 mt-11"

            >
              <QuantityControl product={product} itemCountInCart={getItemCountInCart} icon={<ShoppingCartIcon className="mr-2" />} />
              <button
                type="button"
                className="text-primary hover:text-white border border-primary hover:bg-green-700 font-medium rounded-lg text-sm px-5 py-2.5 w-full"
              >
                <PaymentIcon className="mr-2" />
                Buy now
              </button>
            </div>
          </div>
        </div>
        {tempCataloge?.length && (
          <div>
            <div
              style={{
                display: "flex",
                fontSize: "24px",
                fontWeight: "bold",
                paddingBottom: "10px",
                paddingLeft: "10px",
              }}
            >
              Customers also Viewed
            </div>
            <div style={{ display: "flex" }}>
              <PLPCard product={tempCataloge[0]} />
              <PLPCard product={tempCataloge[1]} />
              <PLPCard product={tempCataloge[2]} />
              <PLPCard product={tempCataloge[4]} />
              <PLPCard product={tempCataloge[5]} />
            </div>
          </div>
        )}
        <div style={{ padding: "10px" }}>
          {/* <div style={{ display: "flex", columnGap: "10px" }}>
            <Button
              variant={descActive ? "contained" : "outlined"}
              onClick={() => {
                setDescActive(true);
                setReviewActive(false);
              }}
            >
              Description
            </Button>
            <Button
              variant={reviewActive ? "contained" : "outlined"}
              onClick={() => {
                setReviewActive(true);
                setDescActive(false);
              }}
            >
              Reviews
            </Button>
          </div> */}
          {descActive && (
            <div>
              <div>
                <h2
                  style={{
                    display: "flex",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  Description
                </h2>
                <p style={{ fontSize: "14px", textAlign: "left" }}>
                  A typical potato chip bag is made up of multiple layers of
                  polymer materials: Biaxially Oriented Polypropylene (BOPP) on
                  the inside, low-density polyethylene (LDPE) in the middle,
                  another middle layer of BOPP, and an outer layer of Surlyn, a
                  thermoplastic resin…Each layer performs a specific function.
                  BOPP is an excellent moisture barrier (so it keeps moisture
                  away from chips), and it’s also resistant to oils and grease
                  (so you don’t get chip grease on your fingers when you pick up
                  the bag).LDPE is also resistant to vegetable oils, and both
                  LDPE and Surlyn are strong and flexible but not
                  unbreakable.This means your chip bag won’t burst if you drop
                  it, but you can easily pull it open to get at the yummy stuff
                  inside.
                </p>
                <div style={{ display: "flex", columnGap: "50px" }}>
                  <div>
                    <h2
                      style={{
                        display: "flex",
                        margin: "20px 0px",
                        fontWeight: "600",
                      }}
                    >
                      Technical Details
                    </h2>
                    <table style={{ border: "1px solid lightgrey" }}>
                      <tbody>
                        {productDetailsTableData.map((detail, index) => (
                          <tr
                            key={index}
                            style={{ borderBottom: "1px solid lightgrey" }}
                          >
                            <td
                              style={{
                                textAlign: "left",
                                background: "#f0f0f0",
                                fontSize: "14px",
                              }}
                            >
                              {detail.label}
                            </td>
                            <td style={{ textAlign: "left", fontSize: "14px" }}>
                              {detail.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <h2
                      style={{
                        display: "flex",
                        margin: "20px 0px",
                        fontWeight: "600",
                      }}
                    >
                      Product Details
                    </h2>
                    <table style={{ border: "1px solid lightgrey" }}>
                      <tbody>
                        {productDetailsAddInfoData.map((detail, index) => (
                          <tr
                            key={index}
                            style={{ borderBottom: "1px solid lightgrey" }}
                          >
                            <td
                              style={{
                                textAlign: "left",
                                background: "#f0f0f0",
                                fontSize: "14px",
                              }}
                            >
                              {detail.label}
                            </td>
                            <td style={{ textAlign: "left", fontSize: "14px" }}>
                              {detail.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginTop: "50px",
                  }}
                >
                  Reviews and Ratings
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "30%" }}>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div style={{ display: "flex" }}>{renderStars(4.5)}</div>
                      <div style={{ fontSize: "18px", paddingLeft: "2px" }}>
                        4.5 out of 5
                      </div>
                    </div>
                    <div style={{ width: "auto" }}>
                      <CustomizedProgressBars starRatings={starRatings} />
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid lightgrey",
                      width: "70%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "50px",
                        fontSize: "16px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      Images Uploaded by Customers
                    </div>
                    <div
                      style={{
                        paddingLeft: "50px",
                        display: "flex",
                      }}
                    >
                      {images.map((image, index) => (
                        <div className="p-d-image-row">
                          <img
                            key={index}
                            src={chillies}
                            alt={`prod-pic ${index}`}
                            onClick={() => handleImageClick(image)}
                          />
                        </div>
                      ))}
                    </div>
                    <div style={{ paddingLeft: "50px", marginTop: "20px" }}>
                      {userReviewCard()}
                      {userReviewCard()}
                      {userReviewCard()}
                      {userReviewCard()}
                      {userReviewCard()}
                      {userReviewCard()}
                    </div>
                  </div>
                </div>
              </div>
              {tempCataloge?.length && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "24px",
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      paddingTop: "30px",
                    }}
                  >
                    Top picks for you
                  </div>
                  <div style={{ display: "flex" }}>
                    <PLPCard product={tempCataloge[0]} />
                    <PLPCard product={tempCataloge[1]} />
                    <PLPCard product={tempCataloge[2]} />
                    <PLPCard product={tempCataloge[4]} />
                    <PLPCard product={tempCataloge[5]} />
                  </div>
                </div>
              )}
              {tempCataloge?.length && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "24px",
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    Recently viewed
                  </div>
                  <div style={{ display: "flex" }}>
                    <PLPCard product={tempCataloge[0]} />
                    <PLPCard product={tempCataloge[1]} />
                    <PLPCard product={tempCataloge[2]} />
                    <PLPCard product={tempCataloge[4]} />
                    <PLPCard product={tempCataloge[5]} />
                  </div>
                </div>
              )}
            </div>
          )}
          {reviewActive && (
            <div>
              <div>Review detail</div>
            </div>
          )}
        </div>
      </div>
    );
  }, [product, productImages, productPriceSection, getItemCountInCart, getDiscount, isLoading, anchorEl]);

  return product && !isLoading && !isError ? (
    <div>{productDetails}</div>
  ) : !isLoading && !isError ? (
    <div>Error</div>
  ) : (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <CircularProgress color="success" />
    </div>
  );
};

export default ProductDetails;
