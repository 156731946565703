import { configureStore } from '@reduxjs/toolkit';
import {
    appReducer,
    userReducer,
    errorsReducer,
    categoriesReducer,
    productsReducer
} from '../reducers';

export const store = configureStore({
    reducer: {
        application: appReducer,
        userProfile: userReducer,
        errors: errorsReducer,
        categories: categoriesReducer,
        products: productsReducer
    },
});