import React from 'react';
import CategoryCard from './CategoryListCard';
import { useSelector } from 'react-redux';

const CategoryListingSlider = () => {
    const categories = useSelector(state => state.categories.categories);

    return categories?.length > 0 && (
        <div className='w-full inline-flex flex-shrink-0 overflow-x-scroll py-4 sm:py-1 gap-4 sm:gap-2 scrollbar-hide md:scrollbar-default'
            style={{ scrollbarWidth: "none" }}>
            {
                categories.map(category => <CategoryCard key={category.id} category={category} />)
            }
        </div>);
};

export default CategoryListingSlider;