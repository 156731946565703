import React from "react";
import "./Cards.css";
// import pic from '../../../assets/vegtables.jpg';
import user_icon from "../../../assets/user_logo.jpg";
import chilli from "../../../assets/categories/dry-red-chilli.png";
import Chip from "@mui/material/Chip";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

import { renderStars } from "../../../utils/CommonUtils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { yellow, grey } from "@mui/material/colors";
// import IconButton from '@mui/material/IconButton';

export const TopSellerCards = () => {
  return (
    <div className="top-seller-section zoom-effect">
      <div className="seller-card">
        <div>
          <img src={user_icon} alt="seller pic" className="top-seller-image" />
        </div>
        <div>
          <div className="top-seller-name">
            Bhargav Dadi Sai Charan Somesh Katta
          </div>
          <div className="seller-rating">{renderStars(3.5)}</div>
        </div>
      </div>
    </div>
  );
};

export const WishlistCard = () => {};

export const cartCard = () => {};

export const userReviewCard = () => {
  return (
    <div style={{ paddingBottom: "8px", borderBottom: "1px solid lightgrey" }}>
      <div
        style={{ display: "flex", alignItems: "center", margin: "10px 0px" }}
      >
        <div>
          <Chip
            icon={
              <StarOutlinedIcon
                style={{ height: "16px", width: "16px", color: yellow[800] }}
              />
            }
            label="4.5"
            style={{ height: "22px", width: "60px" }}
          />
        </div>
        <div style={{ fontSize: "16px", paddingLeft: "4px" }}>Fabulous</div>
      </div>
      <div>
        <p style={{ fontSize: "14px", textAlign: "left" }}>
          The taste of the new Indian masala flavor is satisfying there is taste
          of garlic and less spicy and that is not and chatpata as before please
          make it like that before
        </p>
      </div>
      <div style={{ margin: "8px 0px" }}>
        <img
          src={chilli}
          alt=""
          style={{
            border: "1px solid lightgrey",
            width: "80px",
            height: "80px",
            objectFit: "cover",
          }}
        ></img>
      </div>
      <div style={{ display: "flex", fontSize: "14px", color: "grey" }}>
        <div style={{ fontWeight: "600" }}>Sai Charan Narayanasetti,</div>
        <div style={{ paddingLeft: "8px" }}>10 Feb, 2024</div>
        <div style={{ marginLeft: "auto", display: "flex", columnGap: "16px" }}>
          <div style={{ display: "flex" }}>
            <div>
              <ThumbUpIcon style={{ width: "16px", height: "16px" }} />
            </div>
            <div>90</div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <ThumbDownIcon style={{ width: "16px", height: "16px" }} />
            </div>
            <div>10</div>
          </div>
        </div>
      </div>
    </div>
  );
};
