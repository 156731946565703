import { useEffect } from 'react';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { useState } from 'react';
import { Stepper as MuiStepper, Step, StepLabel, StepConnector } from '@mui/material';
import { getStepConnectorUtilityClass } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { yellow } from "@mui/material/colors";


const DEFALUT_STEPS = [
    { label: 'Cart', value: 'cart', icon: CheckCircleTwoToneIcon }, { label: 'Address', value: 'address', icon: CheckCircleTwoToneIcon }, { label: 'Payment', value: 'payment', icon: CheckCircleTwoToneIcon }
]

const Stepper = ({ steps = DEFALUT_STEPS, activeStep = 0, setActiveStep }) => {

    const CustomConnector = styled(StepConnector)(() => ({
        [`&.${getStepConnectorUtilityClass('active')}, &.${getStepConnectorUtilityClass('completed')}`]: {
            [`& .${getStepConnectorUtilityClass('line')}`]: {
                borderColor: 'green',
            },
        },
        [`& .${getStepConnectorUtilityClass('line')}`]: {
            borderColor: 'lightgrey',
            borderTopWidth: 2,
            borderRadius: 6,
        },
    }));

    const StepIconComponent = ({ icon: Icon, active, completed }) => {
        if (completed) {
            return <Icon style={{ color: 'green' }} />;
        } else if (active) {
            return (
                <Icon
                    style={{
                        color: yellow[800],
                        boxShadow: `0 0 6px 0 ${yellow[800]}`,
                        animation: 'pulse 1.5s infinite',
                        borderRadius: '50%',
                    }}
                />
            );
        } else {
            return <Icon style={{ color: 'grey' }} />;
        }
    };

    return (
        <MuiStepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
            {steps.map((stage, index) => (
                <Step key={stage.label}  >
                    <StepLabel
                        StepIconComponent={() => <StepIconComponent icon={stage.icon} active={index === activeStep} completed={index < activeStep} />}
                        onClick={() => activeStep > index ? setActiveStep(index) : null}
                        sx={activeStep > index ? { cursor: "pointer" } : null}
                    >
                        {stage.label}
                    </StepLabel>
                </Step>
            ))}
        </MuiStepper>
    );
}

export default Stepper;