import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    page: {
        current: 1,
        pageSize: 1,
        totalPages: 1,
        totalResults: 1
    }
};

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories: (state, action) => {
            if (action.payload.page.current === 1) {
                return {
                    ...state,
                    categories: action.payload.data,
                    page: action.payload.page
                }
            } else if (action.payload.page.current === state.page.current + 1) {
                return {
                    ...state,
                    categories: [...state.categories, ...action.payload.data],
                    page: action.payload.page
                }
            } else {
                return state;
            }
        },
    },
});

export const { setCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;