import React, { useState } from "react";
import { Pagination } from 'flowbite-react';

import Card from "../components/Body/Products/Card";
import BreadCrumbs from "../components/BreadCrumbs";

import logo from '../assets/company/logo.png';
import banner from '../assets/fresh_fruits_blog_02-520x330.jpg'

const Collections = () => {
    const [collections, setCollections] = useState([]);
    const [loader, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const onPageChange = (page) => setCurrentPage(page);

    // call the collection api and set the data
    useState(() => {
        setLoading(true);
        const collections = [];
        const collection = {
            name: "Discount Products",
            slug: 'discount-products',
            logoUrl: logo,
            imageUrl: banner,
            title: "Discount products",
            subTitle: "Buy it before it runs out",
        };
        // set timeout to mimic the api call
        setTimeout(() => { }, 100000);

        for (let index = 0; index < 24; index++) {
            collections.push(collection);
        }
        setCollections(collections);
        setLoading(false);
    }, [])

    return (
        <div className='lg:basis p-5 mx-5 overflow-x-auto sm:justify-center'>
            <BreadCrumbs path={"/collections"} />
            <div className='py-12 mx-3 lg:mx-0 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-5'>
                {
                    collections?.map(collection => <Card size={"small"} imageUrl={collection.imageUrl} logoUrl={collection.logoUrl} title={collection.name} subTitle={collection.subTitle} target={`/collection/${collection.slug}`} />)
                }
            </div>
            <div>
                <Pagination currentPage={currentPage} totalPages={100} onPageChange={onPageChange} showIcons />
            </div>
        </div>
    )
};

export default Collections;