import React, { useEffect, useState } from 'react';
import Products from './Products';
import emptyCart from '../assets/empty-cart.webp';

const DiscountPage = () => {
    const [discountedProducts, setDiscountedProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDiscountedProducts = async () => {
            try {
                /**
                 * call the discounts api here, if the no products, show the no products found 
                 */

                // if (response.ok) {
                // setDiscountedProducts(data.products || []);
                // } else {
                setDiscountedProducts([]);
                // }
            } catch (error) {
                console.error('Error fetching discounted products:', error);
                setDiscountedProducts([]);
            } finally {
                setLoading(false);
            }
        };

        fetchDiscountedProducts();
    }, []);

    if (loading) {
        return <div className="flex items-center justify-center h-full w-full">Loading...</div>;
    }

    return (
        <div className='flex flex-1 items-center justify-center h-full w-full'>
            {discountedProducts.length > 0 ? (
                <Products products={discountedProducts} />
            ) : (
                <div className="flex flex-col items-center text-center justify-center p-3">
                    <img src={emptyCart} alt="No Products Found" />
                    <p className='text-lg text-greyLight'>No Products found</p>
                </div>
            )}
        </div>
    );
}

export default DiscountPage;
