import { axiosClient } from "./axios";
import { URLS } from "./url.service";

export const getBlogDetails = async (blogId) => {
    return axiosClient.get(URLS.BLOG_DETAIL(blogId))
};

export const getBlogs = async () => {
    return axiosClient.get(URLS.BLOGS());
};
