import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ category }) => {
    const navigate = useNavigate();

    return (
        <div className="flex h-16 items-center gap-2 justify-evenly rounded-full py-2 w-full" onClick={() => navigate(`/categories?category=${category.slug}`)}>
            <div className="h-16 w-16 rounded-full overflow-hidden items-center content-center transition-all ease-out duration-200 bg-yellow-400">
                <img className=" h-16 w-16 items-center rounded-full" src={category.image.url} alt="Category Logo" />
            </div>
            <div className="flex-col w-36 border-r-2 border-gray-600 border-dotted mr-4">
                <h2 className="text-sm font-medium font-sans text-black">{category.name}</h2>
                <span className="text-sm font-thin font-sans text-gray-600">{category.productCount ?? 0} Products</span>
            </div>
        </div>
    );
}

export default CategoryCard;