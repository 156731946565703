import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";
import { Modal } from '@mui/material';

const LocationModal = ({ isOpen, onClose, onAddressSelectHandler, onPincodehandler, location }) => {
    const { addresses = [] } = useSelector((state) => state.userProfile);

    const inputRef = useRef(null);
    const [errorText, setErrorText] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [pincode, setPincode] = useState();

    console.log(location)

    useEffect(() => {
        if (location.id) {
            setSelectedAddress(location);
        } else if (inputRef.current) {
            setPincode(location.area_code || '');
        }
    }, [location])

    if (!isOpen) return null;

    const onAddressSelect = (address) => {
        setSelectedAddress(address);
        onAddressSelectHandler(address);
        // setPincode('');
    };

    const onPincodeSubmitHandler = () => {
        const value = inputRef.current.value;
        if (value.length < 6) {
            setErrorText(true);
            return;
        }
        setSelectedAddress(null);
        onPincodehandler(value);
    };

    const handleInput = (e) => {
        setErrorText(false);
        const input = e.target;
        if (input.value.length > 6) {
            input.value = input.value.slice(0, 6);
        }
    };

    return ReactDOM.createPortal(
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div id="orderConfirmationModal" aria-hidden="false" className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden antialiased md:inset-0">
                <div className="relative max-h-full w-full max-w-lg">
                    <div className="relative rounded-lg bg-white shadow dark:bg-gray-800 p-6">
                        <div className='flex justify-between items-center border-b-2 border-t-body-color pb-3 w-full'>
                            <h2 className='font-bold text-lg'>Choose your location</h2>
                            <button type="button" onClick={onClose} className="rounded-lg bg-transparent p-1.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="orderConfirmationModal">
                                <svg aria-hidden="false" className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>
                        <div className='mt-5'>
                            <p className='text-sm text-body-color'>Select a delivery location to see product availability and delivery options</p>
                            <div className='mt-2'>
                                {addresses.length > 0 && (
                                    <>
                                        {addresses.map((address) => (
                                            <div key={address.id} className={`p-2 border mb-2 rounded-md cursor-pointer ${selectedAddress === address ? 'border-green-600 border-4' : 'border-gray-400'}`} onClick={() => onAddressSelect(address)}>
                                                <p><b>{address.first_name}</b> {address.address}</p>
                                                <p>{address.city} {address.state} {address.area_code}</p>
                                                {address.is_default_address && <p className='mt-2 text-[14px] text-gray-600 font-bold'>Default address</p>}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            <div className='my-4 flex items-center'>
                                <div className="flex-grow border-t border-gray-400"></div>
                                <span className="mx-4 text-sm text-body-color">or enter an Indian pincode</span>
                                <div className="flex-grow border-t border-gray-400"></div>
                            </div>
                            <div className='flex w-full'>
                                <div className='flex flex-col w-full'>
                                    <input type="number" className='w-full py-1 rounded-md font-semibold' max={6} ref={inputRef} onChange={handleInput} value={pincode} />
                                    {errorText && <p className='text-sm text-red-700 font-bold mt-1'>Please enter a valid pincode</p>}
                                </div>
                                <button className='px-8 py-1 rounded-md border border-gray-500 ml-2 font-[500] h-fit' onClick={onPincodeSubmitHandler}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        document.getElementById('modal-root')
    );
};

export default LocationModal;
