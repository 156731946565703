import { useDispatch } from "react-redux";
import { Button, ButtonGroup, colors } from "@mui/material";
import useUpdateCart from "hooks/useUpdateCart";
import { productServiceable } from "services/product.service";
import { setCartDetail } from "reducers/userSlice";

const QuantityControl = ({ product, icon, itemCountInCart, ...restProps }) => {

  const dispatch = useDispatch();
  const { updateCart } = useUpdateCart();
  const decrementHandler = () => {
    updateCart(product, "dec").then((res) => {
      dispatch(setCartDetail(res));
      console.log(res);
    });
  };
  const incrementHandler = () => {
    productServiceable().then((res) => {
      if (res) {
        updateCart(product, "inc").then((res) => {
          dispatch(setCartDetail(res));
          console.log(res);
        });
      }
    });
  };
  const addToCartHandler = () => {
    productServiceable().then((res) => {
      if (res) {
        updateCart(product, "inc").then((res) => {
          dispatch(setCartDetail(res));
          console.log(res);
        });
      }
    });
  };

  const avoidDefaultBehaviour = (e) => {
    e.preventDefault();
  };

  return (
    <div onClick={avoidDefaultBehaviour} {...restProps}>
      {itemCountInCart ? (
        <div className="flex items-center justify-between w-[148px]">
          <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{ height: '40px', width: '100%' }}>
            <Button onClick={decrementHandler}>-</Button>
            <Button sx={{ flex: 1 }}>{itemCountInCart}</Button>
            <Button onClick={incrementHandler} className="cursor-pointer">
              +
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <button
          type="button"
          class=" text-white bg-primary hover:bg-green-700 font-medium rounded-lg text-sm px-5 py-2.5 w-full"
          onClick={addToCartHandler}
        >
          {icon}
          Add to Cart
        </button>
      )}
    </div>
  );
};

export default QuantityControl;
