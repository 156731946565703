import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Pagination, Label, Select } from "flowbite-react";
import { CircularProgress } from "@mui/material";

// Components
import BreadCrumbs from "components/BreadCrumbs";
import Filters from "components/Body/Products/Filters";
import { getProducts as fetchProductsService } from "services/product.service";
import { PLPCard } from "components/cards";

import { MOCK_FILTERS } from "constants/mock_data";

const INITIAL_PAGINATION = { current: 1, pageSize: 12, totalPages: 1 }

const Products = () => {
  const { cartDetail } = useSelector((state) => state.userProfile)
  const [searchParams, setSearchParams] = useSearchParams();

  const [paginationData, setPaginationData] = useState(INITIAL_PAGINATION);
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(true)
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState(MOCK_FILTERS);
  const [appliedFilters, setAppliedFilters] = useState([])


  const getItemCountInCart = useCallback(
    (item) => {
      return cartDetail?.items?.find((i) => i.id === item.id)?.quantity ?? 0;
    },
    [cartDetail]
  );

  const getProducts = useCallback(() => {
    fetchProductsService(searchParams).then((res) => {
      setProducts(res.data);
      setFilters(res?.filters ?? MOCK_FILTERS)
      const { current, pageSize, totalPages } = res?.page ?? paginationData
      setPaginationData({ current, pageSize, totalPages })
      setIsError(false)
    }).catch((err) => {
      console.log(err);
      setIsError(true)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [searchParams])

  useEffect(() => {
    getProducts();
  }, [getProducts])

  useEffect(() => {
    const params = {};
    params.page = paginationData.current;
    params.pageSize = paginationData.pageSize;
    const mergedParams = new URLSearchParams(searchParams);
    Object.entries(params).forEach(([key, value]) => {
      mergedParams.set(key, value);
    });
    setSearchParams(mergedParams);
  }, [paginationData, appliedFilters, setSearchParams])

  const onPageChange = (page) => {
    setPaginationData((state) => {
      return { ...state, current: page }
    })
  }

  const applyFilters = (filter) => {
    //merge updated filter with old filters
    console.log(filter)
    // setPaginationData(INITIAL_PAGINATION)
    // setAppliedFilters(filters)
  }

  const Loader = () => {
    return <div className="h-[700px] text-center">
      <CircularProgress color="success" className="text-center" />
    </div>
  }
  const PlpContainer = (classes) => {
    return <div {...classes}>
      <Sort />
      <section className="py-6 mx-3 lg:mx-0 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4  lg:gap-5">
        {
          isLoading ? <Loader /> : !isLoading && isError ? <div>Something went wrong</div> : !isLoading && !isError && !products?.length ? <div>No Products found</div> : products?.map((product, index) => (
            <div key={product.id + "products"}>
              <PLPCard product={product} itemCountInCart={getItemCountInCart(product)} />
            </div>
          ))
        }
      </section>
      {paginationData?.totalPages > 1 && (
        <Pagination
          currentPage={paginationData.current}
          totalPages={paginationData.totalPages}
          onPageChange={onPageChange}
          showIcons
        />
      )}
    </div>
  }
  return (
    <div aria-label="plp-page" className="">
      <BreadCrumbs path={"/products"} className="my-4" />

      <div aria-label="products-and-filters" className="flex flex-col md:flex-row gap-4">
        <Filters filters={filters} onFilterChange={applyFilters} className="border-primary rounded-xl md:w-[20%]" />
        <PlpContainer className="md:w-[80%] pb-8" />
      </div>
    </div>
  )
};

const Sort = () => {
  return (
    <div className="flex max-w-md items-center">
      <Label
        htmlFor="sortby"
        value="Sort"
        className="pr-4 text-base text-primary1"
      />
      <Select id="sortby" required>
        <option>Price Low to High</option>
        <option>Price High to Low</option>
        <option>Most Popular</option>
        <option>Discount Low to High</option>
        <option>Discount High to Low</option>
      </Select>
    </div>
  );
};

export default Products;
