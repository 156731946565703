import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    products: [],
    filters: [],
    page: {
        current: 1,
        pageSize: 1,
        totalPages: 1,
        totalResults: 1
    }
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            if (action.payload.page.current === 1) {
                return {
                    ...state,
                    products: action.payload.data,
                    page: action.payload.page
                }
            } else if (action.payload.page.current === state.page.current + 1) {
                return {
                    ...state,
                    products: [...state.products, ...action.payload.data],
                    page: action.payload.page
                }
            } else {
                return state;
            }
        },
    },
});

export const { setProducts } = productsSlice.actions;

export default productsSlice.reducer;