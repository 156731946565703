import OrderProductCard2 from "components/cards/OrderProductCard2";
import OrderTrackingCard from "components/cards/OrderTrackingCard";
import React, { useState } from "react";

const OrderTracking = ({ orderId }) => {

    const [order, setOrder] = useState({
        id: "12348019324",
        products: [{
            id: "asdfasdfasd",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasdfasd",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasdfasd",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        }
        ],
        status: "IN_TRANSIT",
        estimateDelivery: new Date(),
        user: {
            email: "test@gmail.com",
            phone: "9090909090",
            address: "Pune, India, 492008",
        },
        invoiceUrl: "http://localhost:3000/static/media/logo.e4ca87f10b815d2e2c2c.png",
        createdAt: new Date(),
        cost: 1000,
        discount: 10,
        deliveryCharge: 10,
        tax: 10,
        total: 1020
    });

    return (
        <section className="bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                {/* Heading */}
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">Track the delivery of order #{order.id}</h2>

                {/* Product & Pricing */}
                <div className="mt-6 sm:mt-8 lg:flex lg:gap-8">
                    <div className="w-full divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 dark:divide-gray-700 dark:border-gray-700 lg:max-w-xl xl:max-w-2xl">
                        {order?.products.map(product => <OrderProductCard2 product={product} />)}

                        {/* Order value */}
                        {order?.products ?
                            <div className="space-y-4 bg-gray-50 p-6 dark:bg-gray-800">
                                <div className="space-y-2">
                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="font-normal text-gray-500 dark:text-gray-400">Original price</dt>
                                        <dd className="font-medium text-gray-900 dark:text-white">&#8377;{order?.cost}</dd>
                                    </dl>

                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="font-normal text-gray-500 dark:text-gray-400">Savings</dt>
                                        <dd className="text-base font-medium text-green-500">-&#8377;{order?.discount}</dd>
                                    </dl>

                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="font-normal text-gray-500 dark:text-gray-400">Store Pickup</dt>
                                        <dd className="font-medium text-gray-900 dark:text-white">&#8377;{order?.deliveryCharge}</dd>
                                    </dl>

                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="font-normal text-gray-500 dark:text-gray-400">Tax</dt>
                                        <dd className="font-medium text-gray-900 dark:text-white">&#8377;{order?.tax}</dd>
                                    </dl>
                                </div>

                                <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                                    <dt className="text-lg font-bold text-gray-900 dark:text-white">Total</dt>
                                    <dd className="text-lg font-bold text-gray-900 dark:text-white">&#8377;{order?.total}</dd>
                                </dl>
                            </div>
                            : <></>}
                    </div>
                    {/* Order Tracking Card */}
                    <div className="mt-6 grow sm:mt-8 lg:mt-0">
                        <OrderTrackingCard orderId={order?.id} estimatedDelivery={order?.estimateDelivery} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OrderTracking;
