import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginFailed } from '../../reducers/userSlice';
import { setAuthToken } from '../../services/axios';
import CONSTANTS from '../../utils/Constants';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('RetailAccessToken');
        localStorage.removeItem('RetailRefreshToken');
        setAuthToken(CONSTANTS.RETAIL_API_NAME);
        dispatch(loginFailed());
        navigate('/');
    }, []);

    return (
        <div>Logging out...</div>
    );
};

export default Logout;