import React from "react";

const OrderProductCard = ({ product }) => {
    return (
        <div class="flex flex-wrap items-center space-y-4 p-6 sm:gap-6 sm:space-y-0 md:justify-between">
            <div class="w-full items-center space-y-4 sm:flex sm:space-x-6 sm:space-y-0 md:max-w-md lg:max-w-lg">
                <a href="#" class="block aspect-square w-20 shrink-0">
                    <img class="h-full w-full dark:hidden" src={`${product.url}`} alt="imac image" />
                    {/* <img class="hidden h-full w-full dark:block" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg" alt="imac image" /> */}
                </a>

                <div class="w-full md:max-w-sm lg:max-w-md">
                    <a href={`/products/${product.id}`} class="font-medium text-gray-900 hover:underline dark:text-white"> {product.name} </a>
                    <p class="font-medium text-gray-500 dark:text-white-500 line-clamp-3 hover:line-clamp-none">{product.description}</p>
                </div>
            </div>

            <div class="w-8 shrink-0">
                <p class="text-base font-normal text-gray-900 dark:text-white">x{product.quantity}</p>
            </div>

            <div class="md:w-24 md:text-right">
                <p class="text-base font-bold text-gray-900 dark:text-white">&#8377;{product.cost}</p>
            </div>
        </div>
    );
}

export default OrderProductCard;
