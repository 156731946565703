import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import QuantityControl from "components/QuantityControl";
import { PLPCard } from "components/cards";
import emptyCart from '../../assets/empty-cart.webp';
import { Link } from "react-router-dom";
import { APP_ROUTES } from "constants/routes";
const CartReview = () => {
    const { cartDetail } = useSelector((state) => state.userProfile)

    const getItemCountInCart = useCallback(
        (item) => {
            return cartDetail?.items?.find((i) => i.id === item.id)?.quantity ?? 0;
        },
        [cartDetail]
    );

    return (
        <div >
            <h2 className="mb-6 text-xl font-semibold text-gray-900 sm:text-2xl">Your Cart</h2>
            {cartDetail?.items?.length > 0 ? <div className="">
                {cartDetail?.items?.map((item) => (
                    <div key={item.id} className="cartpage-item-card">
                        <img src={item?.media?.[0]} alt={item.name} className="cartpage-item-img" />
                        <div className="cartpage-item-details">
                            <h3 className="cartpage-item-name hover:underline"><Link to={`${APP_ROUTES.PRODUCTS}/${item.id}`}>{item.name}</Link></h3>
                            <div className="price-section">
                                <div className="cartpage-product-price">
                                    <div className="cartpage-product-offer-price">₹{(item.quantity ?? 0) * (item?.pricing?.esp ?? 0)}</div>
                                    <div className="cartpage-product-original-price">₹{(item.quantity ?? 0) * (item?.pricing?.mrp ?? 0)}</div>
                                    <div className="cartpage-discount-percent">{100 * (1 - (item?.pricing?.esp / item?.pricing?.mrp))}%</div>
                                </div>
                                <p className="cartpage-saved-money">You save {item.quantity * (item?.pricing?.mrp - item?.pricing?.esp)} ₹ in this order</p>
                            </div>
                        </div>
                        <QuantityControl product={item} itemCountInCart={getItemCountInCart(item)} className="flex items-center" />
                    </div>
                ))}
            </div> : (
                <div className="flex flex-row items-center justify-center text-[red]">
                    <img src={emptyCart} alt="Empty cart" />
                </div>)}
        </div>)
}

export default memo(CartReview);