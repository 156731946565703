import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const OrderTrackRecord = ({ record }) => {
    return (
        <li className={"mb-10 ms-6" + (record?.active ? " text-primary dark:text-primary" : "")}>
            <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-primary-100 ring-8 ring-white dark:bg-primary-900 dark:ring-gray-800">
                <svg className="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
                </svg>
            </span>
            <h4 className="mb-0.5 font-semibold">{record?.timestamp.toLocaleString()}</h4>
            <p className="text-sm">{record?.reason}</p>
        </li>
    );
}

const OrderTrackingCard = ({ orderId, estimatedDelivery }) => {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        // fetch the audit trail of the order ID.
        setRecords([
            {
                active: true,
                timestamp: "23 Nov 2023, 15:15",
                reason: "Products in the courier's warehouse",
            },
            {
                active: true,
                timestamp: "22 Nov 2023, 12:27",
                reason: "Products delivered to the courier - DHL Express",
            },
            {
                active: true,
                timestamp: "19 Nov 2023, 10:47",
                reason: "Payment accepted - VISA Credit Card",
            },
            {
                active: true,
                timestamp: "19 Nov 2023, 10:45",
                reason: `Order placed - Receipt #${orderId}`,
            },
        ]);
    }, [orderId]);

    return (
        <>
            {loading ?
                <Loader /> :
                <div className="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Order history</h3>

                    <ol className="relative ms-3 border-s border-gray-200 dark:border-gray-700">
                        {/* Apply conditional validation if the current date is equal to estimatedDelivery */}
                        <li className="mb-10 ms-6">
                            <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-700 dark:ring-gray-800">
                                <svg className="h-4 w-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5" />
                                </svg>
                            </span>
                            <h4 className="mb-0.5 text-base font-semibold text-gray-900 dark:text-white">Estimated delivery in {estimatedDelivery.toLocaleString()}</h4>
                            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Products delivered</p>
                        </li>

                        {/* Apply conditional validation if the current date is equal to estimatedDelivery */}
                        <li className="mb-10 ms-6 text-yellow-300">
                            <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-700 dark:ring-gray-800">
                                <svg className="h-4 w-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                                </svg>
                            </span>
                            <h4 className="mb-0.5 text-base font-semibold text-gray-900 dark:text-white">Today</h4>
                            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Products being delivered</p>
                        </li>

                        {records.map(record => <OrderTrackRecord record={record} />)}
                    </ol>

                    <div className="gap-4 sm:flex sm:items-center">
                        <button type="button" className="w-full rounded-lg  border border-gray-200 bg-white px-5  py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">Cancel the order</button>
                        <Link to={`/orders/${orderId}`} className="mt-4 flex w-full items-center justify-center rounded-lg bg-primary  px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary dark:focus:ring-primary-800 sm:mt-0">Order details</Link>
                    </div>
                </div>
            }
        </>
    );
}

export default OrderTrackingCard;
