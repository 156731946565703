import "./App.css";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from './config/store';
import DefaultLayout from './layouts/DefaultLayout';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { JitsuProvider } from "@jitsu/jitsu-react";
import { ChildComponent } from "components/ChildComponent";

// TODO: Handle categories fetching in a better way

/**
 * Create a theme for the application
 */
const theme = createTheme({
  typography: {
    fontFamily: "system-ui",
  },
});

/**
 *  Main application
 * @return {React.Component}
 */
function App() {
  return (
    <JitsuProvider options={{ host: "https://cly1f4uko00002e6gsod3vbqh.d.jitsu.com", writeKey: process.env.REACT_JISTU_ANALYTICS }} >
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <ChildComponent />
            <DefaultLayout>
            </DefaultLayout>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </JitsuProvider>
  );
}

export default App;
