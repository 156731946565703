import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginBottom: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const RatingProgressBar = (props) => {
  const { starRatings } = props;

  return (
    <Box>
      {starRatings.map((rating) => (
        <div style={{ display: "flex" }}>
          <div>{rating.stars} star</div>
          <div
            style={{
              width: "200px",
              marginTop: "8px",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          >
            <BorderLinearProgress
              key={rating.stars}
              variant="determinate"
              value={(rating.count / 10) * 100} // Assuming each count represents 10%
            />
          </div>
          {/* give total count instead of 20 in below line */}
          <div>{((rating.count / 10) * 1000) / 20}%</div>
        </div>
      ))}
    </Box>
  );
};
export default RatingProgressBar;
