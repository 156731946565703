import React from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';

const Input = ({ type, name, label, id, className, placeholder, value, onChange, isInvalid, error, isOptional }) => {
    return (
        <div>
            {label && (
                <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {label} {isOptional && (<span className="text-greyLight">(Optional)</span>)}
                </label>
            )}
            {type === 'phone' ? (
                <PhoneInput
                    inputComponent={() => (
                        <InputComponent
                            type="tel"
                            name={name}
                            id={id}
                            className={className}
                            placeholder={placeholder}
                            isInvalid={isInvalid}
                        />
                    )}
                    value={value}
                    onChange={onChange}
                    defaultCountry='IN'
                />
            ) : (
                <InputComponent
                    type={type}
                    name={name}
                    id={id}
                    className={className}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    isInvalid={isInvalid}
                />
            )}
            {error && (<span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{error}</span>)}
        </div>
    );
};

export const InputComponent = ({ type, name, id, isInvalid, className, placeholder, value, onChange }) => {
    return (
        <input
            type={type}
            name={name}
            id={id || name}
            className={`sm:text-sm rounded-lg block w-full border ${isInvalid ? 'bg-red-50 border border-red-300 text-red-600 sm:text-sm rounded-lg focus:ring-red-600 focus:border-red-400' : 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-primary-600 focus:border-primary-600'} p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`}
            placeholder={placeholder || ''}
            value={value}
            onChange={onChange}
        />
    );
}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isInvalid: PropTypes.bool,
    error: PropTypes.string,
    isOptional: PropTypes.bool,
};

Input.defaultProps = {
    isInvalid: false,
    isOptional: false,
};


export default Input;