import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCategoryById = (categoryID) => {
    const [results, setResults] = useState(null);
    const categories = useSelector(state => state.categories.categories);

    useEffect(() => {
        let results = categories.find(category => category.id == categoryID);
        setResults(results);
    }, [categories])
    if (results) {
        return results;
    } else {
        return null;
    }
};

export default useCategoryById;