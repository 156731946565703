export const MOCK_ADDRESS = [
    {
        street: "khese park",
        user_id: "6602a744cb1a5e0a45367fb8",
        country_phone_code: "+91",
        address: "khese park, lohegaon",
        country: "India",
        checkout_mode: "self",
        area_code: "411014",
        id: "667261c0107c8cccf4373a9e",
        geo_location: {
            latitude: 18.542569,
            longitude: 73.913706,
        },
        email: null,
        is_default_address: true,
        state_code: "",
        area_code_slug: "pincode",
        phone: "9111111111",
        first_name: "Test 1",
        last_name: "Engineer 1",
        country_code: "",
        city: "Pune",

        landmark: "near airport",
        country_iso_code: "IN",
        created_by_user_id: "6602a744cb1a5e0a45367fb8",
        state: "Maharashtra",
        tags: [],
        app_id: "65d8a0b00de0abb4cfe59db8",
        uid: 2333,
        address_type: "home",
    },
    {
        street: "khese park",
        user_id: "6602a744cb1a5e0a45367fb8",
        country_phone_code: "+91",
        address: "khese park, lohegaon",
        country: "India",
        checkout_mode: "self",
        area_code: "411014",
        id: "667261c0107c8cccf4373a9f",
        geo_location: {
            latitude: 18.542569,
            longitude: 73.913706,
        },
        email: null,
        is_default_address: false,
        state_code: "",
        area_code_slug: "pincode",
        phone: "9211111111",
        first_name: "Test 2",
        last_name: "Engineer2",
        country_code: "",
        city: "Pune",

        landmark: "near airport",
        country_iso_code: "IN",
        created_by_user_id: "6602a744cb1a5e0a45367fb8",
        state: "Maharashtra",
        tags: [],
        app_id: "65d8a0b00de0abb4cfe59db8",
        uid: 2333,
        address_type: "office",
    },
    {
        street: "khese park",
        user_id: "6602a744cb1a5e0a45367fb8",
        country_phone_code: "+91",
        address: "khese park, lohegaon",
        country: "India",
        checkout_mode: "self",
        area_code: "411014",
        id: "667261c0107c8cccf4373a9g",
        geo_location: {
            latitude: 18.542569,
            longitude: 73.913706,
        },
        email: null,
        is_default_address: false,
        state_code: "",
        area_code_slug: "pincode",
        phone: "9311111111",
        first_name: "Test 3",
        last_name: "Engineer3",
        country_code: "",
        city: "Pune",

        landmark: "near airport",
        country_iso_code: "IN",
        created_by_user_id: "6602a744cb1a5e0a45367fb8",
        state: "Maharashtra",
        tags: [],
        app_id: "65d8a0b00de0abb4cfe59db8",
        uid: 2333,
        address_type: "home",
    },
    {
        street: "khese park",
        user_id: "6602a744cb1a5e0a45367fb8",
        country_phone_code: "+91",
        address: "khese park, lohegaon",
        country: "India",
        checkout_mode: "self",
        area_code: "411014",
        id: "667261c0107c8cccf4373a9h",
        geo_location: {
            latitude: 18.542569,
            longitude: 73.913706,
        },
        email: null,
        is_default_address: false,
        state_code: "",
        area_code_slug: "pincode",
        phone: "9411111111",
        first_name: "Test 4",
        last_name: "Engineer 4",
        country_code: "",
        city: "Pune",
        landmark: "near airport",
        country_iso_code: "IN",
        created_by_user_id: "6602a744cb1a5e0a45367fb8",
        state: "Maharashtra",
        tags: [],
        app_id: "65d8a0b00de0abb4cfe59db8",
        uid: 2333,
        address_type: "home",
    },
    {
        street: "khese park",
        user_id: "6602a744cb1a5e0a45367fb8",
        country_phone_code: "+91",
        address: "khese park, lohegaon",
        country: "India",
        checkout_mode: "self",
        area_code: "411014",
        id: "667261c0107c8cccf4373a9i",
        geo_location: {
            latitude: 18.542569,
            longitude: 73.913706,
        },
        email: null,
        is_default_address: false,
        state_code: "",
        area_code_slug: "pincode",
        phone: "9511111111",
        first_name: "Test 5",
        last_name: "Engineer 5",
        country_code: "",
        city: "Pune",

        landmark: "near airport",
        country_iso_code: "IN",
        created_by_user_id: "6602a744cb1a5e0a45367fb8",
        state: "Maharashtra",
        tags: [],
        app_id: "65d8a0b00de0abb4cfe59db8",
        uid: 2333,
        address_type: "other",
    },
];

export const MOCK_FILTERS = [
    {
        values: [
            {
                display: "Diapers & Wipes",
                value: "diapers-wipes",
                count: 7,
                logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/category.png",
                is_selected: false,
            },
            {
                display: "Multi-Vitamins",
                value: "multi-vitamins",
                count: 4,
                logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/category.png",
                is_selected: false,
            },
        ],
        key: {
            kind: "multivalued",
            name: "category",
            display: "Category",
            logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/category.png",
        },
    },
    {
        values: [
            {
                display: "Dolce&Gabbana",
                value: "Dolce&Gabbana",
                count: 11,
                logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/popular.png",
                is_selected: false,
            },
        ],
        key: {
            kind: "multivalued",
            name: "brandfilter",
            display: "Brands",
            logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/popular.png",
        },
    },
    {
        values: [
            {
                min: 0,
                max: 3000,
                selected_min: 0,
                selected_max: 3000,
                display: "₹0- ₹3000",
                is_selected: true,
                display_format: "{}- {}₹",
                currency_code: "INR",
                currency_symbol: "₹",
                query_format: "[{},INR TO {},INR]",
            },
        ],
        key: {
            kind: "range",
            name: "min_price_effective",
            display: "Price",
            logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/min-price.png",
        },
    },
    {
        values: [
            {
                min: 0,
                max: 40,
                selected_min: 21,
                selected_max: 40,
                display: "₹21- ₹40",
                is_selected: true,
                display_format: "{}% - {}%",
                query_format: "[{} TO {}]",
            },
        ],
        key: {
            kind: "range",
            name: "discount",
            display: "Discount",
            logo: "https://cdn.pixelbin.io/v2/plain-cake-860195/nms-np/wrkr/nmz5/misc/default-assets/original/discount.png",
        },
    },
    {
        values: [
            {
                display: "In Stock",
                value: "true",
                count: 11,
                logo: null,
                is_selected: false,
            },
            {
                display: "Out Of Stock",
                value: "false",
                count: 11,
                logo: null,
                is_selected: false,
            },
        ],
        key: {
            kind: "singlevalued",
            name: "is_available",
            display: "Available",
            logo: null,
        },
    },
];
