import { Modal } from '@mui/material';
import React from 'react';

const OrderModal = ({ isOpen, type, icon, title, message, buttonText, handleClose, handleButtonClick }) => {

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div id="orderConfirmationModal" aria-hidden="false" className="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden antialiased md:inset-0">
                <div className="relative max-h-full w-full max-w-lg p-4 m-auto mt-[10%]">
                    {/* <!-- Modal content --> */}
                    <div className="relative rounded-lg text-center bg-white p-4 shadow dark:bg-gray-800 sm:p-5">
                        {/* <!-- Modal header --> */}
                        <button type="button" onClick={handleClose} className="absolute ml-auto inline-flex end-2 top-2 items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="orderConfirmationModal">
                            <svg aria-hidden="false" className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* <!-- Modal body --> */}
                        <div className="flex justify-center mx-auto items-center w-12 h-12 sm:w-16 sm:h-16 rounded-full">
                            {icon}
                        </div>
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white my-4">{title}</h2>
                        <p className="text-gray-500 dark:text-gray-400">{message}</p>
                        {/* <p className="text-gray-500 dark:text-gray-400">Your order <a href={`${APP_ROUTES.ORDERS}/${orderId}`} className="text-gray-900 dark:text-white font-semibold hover:underline">{orderId}</a> will be processed within 24 hours during working days. We will notify you by email once your order has been shipped.</p> */}
                        <div className="mt-4 justify-center items-center space-x-0 space-y-4 border-t border-gray-200 pt-4 dark:border-gray-700 sm:flex sm:space-x-4 sm:space-y-0 md:mt-5 md:pt-5">
                            <button type="submit" className={`flex items-center justify-center rounded-lg px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 ${type === 'success' ? 'bg-green-700 hover:bg-green-900' : 'bg-red-500 hover:bg-red-700'} focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600  dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:mt-0 sm:w-auto"`} onClick={handleButtonClick}>{buttonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    );
};

export default OrderModal;
