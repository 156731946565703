import { store } from "../../config/store";
import { axiosClient } from "../axios";
import { setErrors } from "../../reducers/errorsSlice";

export const getUser = async (userId) => {
    try {
        return await axiosClient.get(`/v1/users/${userId}`);
    } catch (error) {
        store.dispatch(setErrors({ message: error.message }));
    }
}