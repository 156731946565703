import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TrendingCard } from "components/cards";
import dow_bg_img from "assets/dow_bg_img.png";
const DealOfTheWeek = () => {
  const { products = [] } = useSelector((state) => state.products);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });


  useEffect(() => {
    const targetDate = new Date("2024-12-30T23:59:59");
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dow-section p-3 sm:py-5 sm:px-6">
      <div className="flex flex-col items-center lg:flex-row lg:justify-between">
        <div className="dow-title mb-3">Deal Of The Week</div>
        <div className="dow-timer">
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="dow-timer-day text-center">
              <div style={{ fontSize: "20px", fontWeight: "bolder" }} >{value}</div>
              <div style={{ fontSize: "12px", fontWeight: 400 }} className=" capitalize" >{unit}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 sm:bg-[#d8f2de] flex">
        {products.length === 0 ? (
          <div className="p-6 text-center w-full">
            <p className="text-lg font-semibold">No Deals Available</p>
          </div>
        ) : (
          <>
            <div className="hidden lg:block dow-banner">
              <img
                src={dow_bg_img}
                alt="dow-banner"
                className="w-auto"
              />
            </div>
            <div className="sm:p-6 dow-trending-cards">
              {products.slice(0, 6).map((product) => (
                <TrendingCard
                  key={"trendingcard:" + product.id}
                  product={product}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DealOfTheWeek;
