import { axiosClient } from "./axios";
import { URLS } from "./url.service";

export const getCart = async ({ cartDetail }) => {
    return Promise.resolve(cartDetail)
}
export const updateCart = async ({ cartDetail }) => {
    return Promise.resolve(cartDetail)
}

