import React from 'react';
// import axios from 'axios';
import logo from '../../../assets/company/logo.png';

const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            console.log("Razorpay script loaded");
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    })
};

const DisplayRazorpay = async (onSuccess, onFailure) => {
    console.log(onSuccess);
    const response = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!response) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    // create a new order
    // const result = await axios.post("http://localhost:5000/payment/orders");

    // if (!result) {
    //     alert('server error');
    //     return;
    // }

    // Gettng the order details back
    // const {amount, id: order_id, currency} = result.data;
    const { amount, id: order_id, currency } = {
        amount: 500,
        order_id: "order-12",
        currency: "INR"
    };

    const handlePaymentSuccess = async (response) => {
        const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
        };

        // Process the payment data, e.g., make an API call
        const result = true; // Placeholder for the actual logic

        console.log(data);
        onSuccess(); // Navigate to the order modal after handling the payment data
    };

    const handlePaymentFailure = (error) => {
        console.error("Payment failed:", error);
        onFailure(); // Optional: You can handle failure navigation or state update here
    };

    const options = {
        key: "rzp_test_23aXeoBU4koR1V", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Sundheep Retail",
        description: "Order transaction",
        image: { logo },
        order_id: order_id,
        handler: handlePaymentSuccess,
        prefill: {
            name: "Vamsi Dalai",
            email: "thevamsi20@gmail.com",
            contact: "9999999999",
        },
        notes: {
            address: "Vamsi D",
        },
        theme: {
            color: "#1b8057",
        },
        modal: {
            "ondismiss": function () {
                handlePaymentFailure('Payment window closed without completion');
            }
        }
    }
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};

export default DisplayRazorpay;