import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import GoogleAddressMap from '../components/GoogleMapAddress';

import BreadCrumbs from '../components/BreadCrumbs';

const Contact = () => {
    return (
        <>
            <BreadCrumbs path={"/contact-us"} />
            <div className='flex py-5 flex-grow-1 p-1 gap-2 flex-col'>
                <div className=' w-full px-1'>
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">How can we help you ?</h2>
                    <p className="mt-0 inline-block mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">When an unknown printer took a galley of type and scrambled it to make type pecimen book. It has survived not only five areafact types remaining essentially unchang edIt.</p>
                    <ul>
                        <li className='flex align-center my-4'>
                            <div className="flex break-words">
                                <div className="flex gap-x-5 items-center transition-all ease-in">
                                    <div className="h-10 w-10 flex bg-metaLight text-primary1 items-center justify-center rounded-full">
                                        <PhoneIcon className='h-8 w-8' />
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="block mb-1 leading-snug text-lg font-semibold">Address</h3>
                                        <ul classNamecla="list-none p-0 m-0">
                                            <li>
                                                AnkireddyPalem, Guntur, Andhra Pradesh, IN
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='flex align-center my-4'>
                            <div className="flex break-words">
                                <div className="flex gap-x-5 items-center transition-all ease-in">
                                    <div className="h-10 w-10 flex bg-metaLight text-primary1 items-center justify-center rounded-full">
                                        <PhoneIcon className='h-8 w-8' />
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="block mb-1 leading-snug text-lg font-semibold">Phone</h3>
                                        <ul className="list-none p-0 m-0">
                                            <li>
                                                +91 1800 202 6246
                                            </li>
                                            {/* <li>
                                                +48-500-130-0001
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='flex align-center my-4'>
                            <div className="flex break-words">
                                <div className="flex gap-x-5 items-center transition-all ease-in">
                                    <div className="h-10 w-10 flex bg-metaLight text-primary1 items-center justify-center rounded-full">
                                        <PhoneIcon className='h-8 w-8' />
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="block mb-1 leading-snug text-lg font-semibold">E-mail</h3>
                                        <ul className="list-none p-0 m-0">
                                            <li>
                                                info@sundheep.com
                                            </li>
                                            {/* <li>
                                                contact@sundheep.com
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='px-1 w-full bg-secondary'>
                    <GoogleAddressMap />
                </div>
            </div>
            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
                    <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
                    <form action="#" className="space-y-8">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                            <input type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary1 focus:border-primary1 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary1 dark:focus:border-primary1 dark:shadow-sm-light" placeholder="name@sundheep.com" required />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                            <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary1 focus:border-primary1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary1 dark:focus:border-primary1 dark:shadow-sm-light" placeholder="Let us know how we can help you" required />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                            <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary1 focus:border-primary1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary1 dark:focus:border-primary1" placeholder="Leave a comment..."></textarea>
                        </div>
                        <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary1 sm:w-fit hover:bg-primary1 focus:ring-4 focus:outline-none focus:ring-primary1 dark:bg-primary1 dark:hover:bg-primary1 dark:focus:ring-primary1">Send us a message</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default Contact;