import { useSelector } from 'react-redux';
import { updateCart as updateCartService } from 'services/cart.service';

const useUpdateCart = () => {
    const { cartDetail } = useSelector((state) => state.userProfile);
    const updateCart = (item, action) => {
        const tempCarDetails = JSON.parse(JSON.stringify(cartDetail))
        let items = tempCarDetails?.items || [];
        let existingItem = items.find((i) => i.id === item.id)
        if (action == 'inc') {
            if (existingItem) {
                existingItem['quantity'] += 1
            } else {
                items.push({ ...item, quantity: 1 });
            }
        } else if (action == "dec") {
            if (existingItem) {
                existingItem['quantity'] -= 1
                if (existingItem['quantity'] <= 0) {
                    items = items.filter((i) => i.id !== item.id)
                }
            }
        } else {
            items.push({ ...item, quantity: 1 });
        }
        return updateCartService({ cartDetail: { ...tempCarDetails, items } })
    };
    return { updateCart }

};

export default useUpdateCart