import { axiosClient } from "./axios";
import { URLS } from "./url.service";

export const getProducts = async (params = {}) => {
    return axiosClient.get(URLS.GET_PRODUCTS(), { params: params });
};

export const getProductDetail = async (productId) => {
    return axiosClient.get(URLS.GET_PRODUCTS(productId));
};

// Asset Uploads -- Upload images for the product reviews
export const uploadImages = async (images) => {
    let data = new FormData();
    data.append('entityType', 'PRODUCT_REVIEWS');

    images.array.forEach(element => {
        data.append('images', element);
    });

    axiosClient.post(URLS.UPLOAD_ASSETS(), data, { headers: { ...axiosClient.defaults.headers } })
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
}

// Product Reviews
export const getProductReviews = async (productId, params) => {
    return axiosClient.get(URLS.GET_REVIEWS(productId), { params: params });
}

export const createProductReview = async (productId, payload) => {
    return axiosClient.get(URLS.GET_REVIEWS(productId), { data: payload });
}

export const getProductReview = async (productId, commentId) => {
    return axiosClient.get(URLS.REVIEW_DETAIL(productId, commentId));
}

export const deleteProductReview = async (productId, commentId) => {
    return axiosClient.delete(URLS.REVIEW_DETAIL(productId, commentId));
}

export const updateProductReview = async (productId, commentId, payload) => {
    return axiosClient.delete(URLS.REVIEW_DETAIL(productId, commentId), { data: payload });
}


export const productServiceable = (productId, identifier, quantity) => {
    // should call the serviceable api 
    return new Promise((resolve, reject) => {
        resolve({
            expectedDeliveryDate: "14-02-2024",
            address: "1-23, Aruku Valley, 500001, Andhra Pradesh",
            soldBy: "Sundheep Pvt.",
            seller: "Sundheep Pvt.",
            inStock: true,
            quantities: {
                available: 10,
            }
        });
    })

}
