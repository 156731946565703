import brand_1 from '../assets/brands/brand_01.png';
import brand_2 from '../assets/brands/brand_02.png';
import brand_3 from '../assets/brands/brand_03.png';
import brand_4 from '../assets/brands/brand_04.png';
import brand_5 from '../assets/brands/brand_05.png';

export default function BrandsBanner() {
  const brands = [{
    image: brand_1,
    alt: "Brand1"
  },
  {
    image: brand_2,
    alt: "Brand2"
  },
  {
    image: brand_3,
    alt: "Brand3"
  },
  {
    image: brand_4,
    alt: "Brand4"
  },
  {
    image: brand_5,
    alt: "Brand5"
  },
  ]
  return (
    <div className="bg-white py-12 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 lg:mt-6">
        <h2 className="text-center text-3xl font-semibold leading-8 text-grey-900">
          Trusted by esteemed brands like
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {brands.map((brand, index) => (
            <div
              key={index}
              className="group col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale transition-transform duration-300 transform hover:scale-110 hover:grayscale-0"
            >
              <img
                key={index}
                src={brand.image}
                alt={brand.alt}
                width={158}
                height={48}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
