import CopyButton from 'components/CopyButton';
import React from 'react';

const OrderProductCard2 = ({ product }) => {
    return (
        <div className="space-y-4 p-6">
            <div className="flex items-center gap-6">
                <a href="#" className="h-14 w-14 shrink-0">
                    <img className="h-full w-full dark:hidden" src={`${product.url}`} alt="product image" />
                    {/* <img className="hidden h-full w-full dark:block" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/iphone-dark.svg" alt="phone image" /> */}
                </a>

                <a href={`/products/${product.id}`} className="min-w-0 flex-1 font-medium text-gray-900 hover:underline dark:text-white"> {product?.name}
                    <span class="font-medium text-gray-500 dark:text-white-500 line-clamp-1 hover:line-clamp-none">{product.description}</span>
                </a>
            </div>

            <div className="flex items-center justify-between gap-4">
                <p className="text-sm font-normal text-gray-500 dark:text-gray-400"><span className="font-medium text-gray-900 dark:text-white">Product ID:</span> {product?.id} <CopyButton value={product?.id} /></p>

                <div className="flex items-center justify-end gap-4">
                    <p className="text-base font-normal text-gray-900 dark:text-white">x{product.quantity}</p>

                    <p className="text-xl font-bold leading-tight text-gray-900 dark:text-white">&#8377;{product.cost}</p>
                </div>
            </div>
        </div>
    );
}

export default OrderProductCard2;
