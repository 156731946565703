import React from 'react';
import ContactCard from "./ContactUsCard";
import BreadCrumbs from '../../components/BreadCrumbs';
import { APP_ROUTES } from 'constants/routes';

const StoresInfo = () => {
    return (
        <div>
            <header>
                <title>SUNDHEEP PRODUCTS - Stores</title>
                <meta name="description" content="A leading player in food product manufacturing and agricultural trading." />
            </header>
            <BreadCrumbs path={APP_ROUTES.STORES} />
            <div className='flex flex-1 mx-auto pb-5'>
                <div className='flex flex-col space-x-1 space-y-3 mx-auto'>
                    <div className='justify-center items-center text-justify'>
                        <h1 className='font-bold text-xl text-center space-y-1'>Sundheep Stores</h1>
                    </div>
                </div>
                <div className='mt-6 space-y-5'>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Bringing Quality to Your Neighbourhood</h3>
                        <p>
                            At SUN DHEEP PRODUCTS, we believe that our people are our greatest asset. As a rapidly growing company in the food manufacturing and agricultural trading industry, we are always looking for talented, passionate, and dedicated individuals to join our team. Our commitment to excellence is reflected not only in the products we create but also in the environment we foster for our employees.
                        </p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Our Vision</h3>
                        <p>SUN DHEEP STORES aims to redefine the shopping experience by offering a wide range of meticulously crafted food products under one roof. From spices and pulses to millets, edible oils, and honey, our stores will feature everything that has made SUN DHEEP PRODUCTS a trusted name in the industry. We envision SUN DHEEP STORES as a place where tradition meets innovation, delivering superior quality products to consumers who care about what they eat.</p>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>
                                <span className='font-semibold'>Dynamic Growth Environment: </span>
                                <p>As an industry leader, we offer opportunities for growth and development in a dynamic, fast-paced work environment.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Innovation and Excellence: </span>
                                <p>Our collaborative work culture ensures that every voice is heard, and every contribution is valued. We believe in teamwork, respect, and shared success.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Why SUN DHEEP STORES?</h3>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>
                                <span className='font-semibold'>Premium Quality: </span>
                                <p> All products available at SUN DHEEP STORES are sourced, processed, and packaged with the highest standards of quality, ensuring that customers receive only the best.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Diverse Product Range: </span>
                                <p>Our stores will offer an extensive selection of food products, including Chili Powder, Turmeric Powder, Coriander Powder, Urad Dal, Bengal Gram, Wheat Rava, Millets, and more, catering to the varied tastes and preferences of Indian household.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Trust and Transparency: </span>
                                <p>SUN DHEEP STORES will uphold our core values of trust and transparency, providing customers with products they can rely on for their daily needs.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Opportunities for Investors</h3>
                        <p>We are actively seeking like-minded investors to partner with us in expanding SUN DHEEP STORES across India. This is a unique opportunity to be part of a rapidly growing brand with a strong foundation in food manufacturing and agricultural trading. As an investor, you will benefit from:</p>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>
                                <span className='font-semibold'>Proven Business Model: </span>
                                <p>Leverage our established brand reputation and extensive industry experience to drive store success.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Growing Market Demand: </span>
                                <p>Tap into the increasing demand for high-quality food products among health-conscious consumers.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Comprehensive Support: </span>
                                <p>Receive end-to-end support from site selection and store setup to marketing and supply chain management, ensuring your success as a SUN DHEEP STORES partner.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Lucrative Returns: </span>
                                <p>Invest in a business model designed for profitability and growth, with the potential for significant returns.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Partner With Us</h3>
                        <p>We invite you to join us on this exciting journey as we expand SUN DHEEP STORES across India. Together, we can bring the best of SUN DHEEP PRODUCTS to every corner of the country, making high-quality food accessible to all.</p>
                        <p>If you are interested in exploring this investment opportunity, please reach out to us at <a href="mailto:sundheepproducts@gmail.com">sundheepproducts@gmail.com</a> or call us at +91 9090 9494 84. Let’s build a healthier, more prosperous future together with SUN DHEEP STORES.</p>

                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Stay Tuned</h3>
                        <p>Keep an eye out for a SUN DHEEP STORE opening near you! We are eager to welcome you to experience the quality, variety, and trust that define SUN DHEEP PRODUCTS.</p>
                    </section>
                </div>
            </div>
            <div className='ml-8'>
                <ContactCard />
            </div>
        </div>
    );
};

export default StoresInfo;
