import DeleteModal from "components/Modals/DeleteModal";
import { APP_ROUTES } from "constants/routes";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ORDER_STATUS = {
    IN_TRANSIT: "IN_TRANSIT",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    RETURN: "RETURN",
    PRE_ORDER: "PRE_ORDER",
};

const PAYMENT_STATUS = {
    VISA: "VISA",
    UPI: "UPI",
    PAYPAL: "PAYPAL",
    RUPAY: "RUPAY",
    MASTERCARD: "MASTERCARD",
};

const PAYMENT_CARD_LOGO = {
    [PAYMENT_STATUS.VISA]: {
        DARK: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg",
        LIGHT: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg",
    },
    [PAYMENT_STATUS.UPI]: {
        DARK: "",
        LIGHT: "",
    },
    [PAYMENT_STATUS.PAYPAL]: {
        DARK: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg",
        LIGHT: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg",
    },
    [PAYMENT_STATUS.RUPAY]: {
        DARK: "",
        LIGHT: "",
    },
    [PAYMENT_STATUS.MASTERCARD]: {
        DARK: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard-dark.svg",
        LIGHT: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg",
    }
};

/**
 * Get the formatted date for estimated delivery
 * @param {Date} dateToBeFormatted 
 * @returns string
 */
const getFormattedDate = (dateToBeFormatted) => {
    const dateObj = new Date(dateToBeFormatted);

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison

    // Get yesterday's date
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Get tomorrow's date
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Reset time to midnight for the given date
    const givenDate = new Date(dateObj);
    givenDate.setHours(0, 0, 0, 0);

    let formattedDate;

    if (givenDate.getTime() === today.getTime()) {
        formattedDate = "Today";
    } else if (givenDate.getTime() === yesterday.getTime()) {
        formattedDate = "Yesterday";
    } else if (givenDate.getTime() === tomorrow.getTime()) {
        formattedDate = "Tomorrow";
    } else {
        // Define options for formatting
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        // Format the date
        formattedDate = dateObj.toLocaleDateString('en-GB', options);
    }

    return formattedDate;
}

/**
 * Get the formatted date for estimated delivery
 * @param {Date} dateToBeFormatted 
 * @returns string
 */
const getStringDate = (dateToBeFormatted) => {
    const dateObj = new Date(dateToBeFormatted);

    // Define options for formatting
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // Format the date
    const formattedDate = dateObj.toLocaleDateString('en-GB', options);

    return formattedDate;
}

/**
 * Get the order status label for the order status
 * @param {string} orderStatus - status of the order
 * @returns 
 */
const getOrderTag = (orderStatus) => {
    switch (orderStatus) {
        case ORDER_STATUS.PRE_ORDER:
            return (
                <span className="ms-2 inline-flex items-center rounded bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800 dark:bg-primary-900 dark:text-primary-300">
                    <svg className="me-1 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.5 4h-13m13 16h-13M8 20v-3.333a2 2 0 0 1 .4-1.2L10 12.6a1 1 0 0 0 0-1.2L8.4 8.533a2 2 0 0 1-.4-1.2V4h8v3.333a2 2 0 0 1-.4 1.2L13.957 11.4a1 1 0 0 0 0 1.2l1.643 2.867a2 2 0 0 1 .4 1.2V20H8Z" />
                    </svg>
                    Pre-order
                </span>
            );
            break;

        case ORDER_STATUS.IN_TRANSIT:
            return (
                <span className="ms-2 mt-1.5 inline-flex items-center rounded bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300">
                    <svg className="me-1 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                    </svg>
                    In transit
                </span>
            );

        case ORDER_STATUS.COMPLETED:
            return (
                <span className="ms-2 inline-flex items-center rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
                    <svg className="me-1 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                    </svg>
                    Completed
                </span>
            );

        case ORDER_STATUS.CANCELLED:
            return (
                <span className="ms-2 inline-flex items-center rounded bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800 dark:bg-red-900 dark:text-red-300">
                    <svg className="me-1 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                    </svg>
                    Cancelled
                </span>
            );

        case ORDER_STATUS.RETURN:
            return (
                <span className="ms-2 mt-1.5 inline-flex items-center rounded bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300">
                    <svg className="me-1 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                    </svg>
                    Return
                </span>
            );

        default:
            return (<></>);
    }
};

// should be able to download PDF
const downloadPdf = (invoiceUrl) => {
    const link = document.createElement('a');
    link.href = invoiceUrl;
    link.download = 'invoice.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const PaymentLogo = ({ paymentType }) => {
    const logo = PAYMENT_CARD_LOGO[paymentType];

    return (
        <>
            <img className="h-4 w-auto dark:hidden" src={logo.LIGHT} alt={`${paymentType} logo light`} />
            <img className="hidden h-4 w-auto dark:flex" src={logo.DARK} alt={`${paymentType} logo dark`} />
            <span className="sr-only">{paymentType}</span>
        </>
    );
};

const DeliveryStatusCard = (orderStatus, estimatedDate) => {
    return (
        <>
            {(orderStatus === ORDER_STATUS.IN_TRANSIT || orderStatus === ORDER_STATUS.PRE_ORDER || orderStatus === ORDER_STATUS.RETURN) ?
                <div className="flex items-center rounded-lg bg-orange-50 px-4 py-3 text-sm text-orange-800 dark:bg-gray-700 dark:text-orange-300" role="alert">
                    <svg className="me-2 hidden h-4 w-4 flex-shrink-0 sm:flex" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        {/* <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"  strokeWidth="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" /> */}
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>Expected delivery <span className="font-medium">{getFormattedDate(estimatedDate)}</span></div>
                </div>
                :
                <div className="flex items-center rounded-lg bg-gray-50 px-4 py-3 text-sm text-gray-800 dark:bg-gray-700 dark:text-gray-300" role="alert">
                    <svg className="me-2 hidden h-4 w-4 flex-shrink-0 sm:flex" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        {/* <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"  strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /> */}
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>Delivered on <span className="font-medium">{getFormattedDate(estimatedDate)}</span></div>
                </div>
            }
        </>
    );
};

const OrderCard = ({ order, handleCancelOrder }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    const orderStatus = order?.status;
    const orderId = order?.id.toUpperCase();

    useEffect(() => {
        console.log(openDeleteModal);
        // call the order's api here
    }, []);

    const showOrderCTAs = () => {
        return (
            <div className="space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
                <button id="deleteOrderButton4" data-modal-target="deleteOrderModal2" data-modal-toggle="deleteOrderModal2" type="button" className="w-full rounded-lg bg-red-700 px-3 py-2 text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 sm:w-auto" onClick={() => handleCancelOrder(orderId)}>Cancel order</button>
                {/* <DeleteModal open={openDeleteModal} handleClose={handleClose} /> */}
                <Link to={`${APP_ROUTES.ORDERS}/details/${"track"}`} className="inline-flex w-full items-center justify-center rounded-lg  border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 sm:mt-0 sm:w-auto">
                    <svg className="-ms-0.5 me-1.5 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 5h6m-6 4h6M10 3v4h4V3h-4Z" />
                    </svg>
                    Track order
                </Link>
                <Link to={`${APP_ROUTES.ORDERS}/${"details"}`} className="inline-flex w-full justify-center rounded-lg  border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 sm:mt-0 sm:w-auto">
                    Order details
                </Link>
            </div>
        );
    }

    return (
        <div className="mb-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800">
            {/* Card header */}
            <div className="items-start justify-between border-b border-gray-100 pb-4 dark:border-gray-700 md:flex lg:block xl:flex">
                <div className="mb-4 justify-between sm:flex sm:items-center md:mb-0 md:block lg:mb-4 lg:flex xl:mb-0 xl:block">
                    <h3 className="dark:text-gry-400 mb-2 text-gray-500 sm:mb-0 md:mb-2">
                        Order ID: <a href="" className="font-medium text-gray-900 hover:underline dark:text-white">#{orderId}</a>
                        {getOrderTag(orderStatus)}
                    </h3>

                    {/* Download Invoice */}
                    <button type="button" className="inline-flex items-center font-medium text-primary-700 hover:underline dark:text-primary-500" onClick={downloadPdf}>
                        <svg className="me-2 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4" />
                        </svg>
                        Download invoice
                    </button>
                </div>
                {(orderStatus === ORDER_STATUS.IN_TRANSIT || orderStatus === ORDER_STATUS.PRE_ORDER || orderStatus === ORDER_STATUS.RETURN) ? showOrderCTAs() : <></>}

            </div>

            {/* Order Details */}
            <div className="mb-4 items-center sm:flex sm:flex-wrap xl:flex">
                <dl className="mt-4 flex items-center text-gray-500 dark:text-gray-400 sm:me-8">
                    <dt className="me-2 font-medium text-gray-900 dark:text-white">Order date:</dt>
                    <dd>{getStringDate(order.createdAt)}</dd>
                </dl>
                <dl className="mt-4 flex items-center text-gray-500 dark:text-gray-400 sm:me-8">
                    <dt className="me-2 font-medium text-gray-900 dark:text-white">Email:</dt>
                    <dd>{order.email}</dd>
                </dl>
                <dl className="mt-4 flex items-center text-gray-500 dark:text-gray-400">
                    <dt className="me-2 font-medium text-gray-900 dark:text-white">Payment method:</dt>
                    <dd className="flex items-center">
                        <PaymentLogo paymentType={PAYMENT_STATUS.VISA} />
                    </dd>
                </dl>
            </div>

            <DeliveryStatusCard orderStatus={order?.status} estimatedDate={order?.estimateDelivery} />
        </div>
    );
}

export default OrderCard;
