import React, { useEffect, useState } from "react";
import companylogo from '../../assets/company/logo.png';
import { Link } from "react-router-dom";
import Input from "../../components/common/form/Input";
import { login } from "../../services/auth/auth.service";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setErrors } from "../../reducers/errorsSlice";
import { loginSuccess, loginFailed, loadingStatus } from "../../reducers/userSlice";
import { setAuthToken } from "../../services/axios";
import CONSTANTS from "../../utils/Constants";
import { CircularProgress } from "@mui/material";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errors = useSelector(state => state.errors);
    const loading = useSelector(state => state.userProfile.loading);
    const isAuthenticated = useSelector(state => state.userProfile.isAuthenticated);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);

    const handleFormChange = e => {
        if (errors[e.target.name]) {
            dispatch(setErrors({
                ...errors,
                [e.target.name]: null,
            }));
        }
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmit = async e => {
        try {
            e.preventDefault();
            // TODO: Add frontend validations to reduce load on server
            dispatch(loadingStatus(true))
            const resData = await login({
                email: formData.email,
                password: formData.password,
            });
            localStorage.setItem('RetailAccessToken', resData.tokens.access.token);
            localStorage.setItem('RetailRefreshToken', resData.tokens.refresh.token);
            setAuthToken(CONSTANTS.RETAIL_API_NAME, resData.tokens.access.token);
            dispatch(setErrors({}));
            dispatch(loginSuccess(resData.user))
        } catch {
            dispatch(loginFailed())
        }
    }

    return (
        (loading) ?
            <div className="w-full h-[100vh] flex justify-center items-center">
                <CircularProgress color="success" />
            </div>
            :
            <section className="bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col border-primary1 items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="w-45 h-20 mr-2" src={companylogo} alt="logo" />
                    </Link>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Sign in to your account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
                                <Input
                                    type="email"
                                    name="email"
                                    label="Email/Phone"
                                    placeholder="Enter email or phone number"
                                    value={formData.email}
                                    onChange={handleFormChange}
                                    isInvalid={!!errors?.email}
                                    error={errors?.email}
                                />
                                <Input
                                    type="password"
                                    name="password"
                                    label="Password"
                                    placeholder="••••••••"
                                    value={formData.password}
                                    onChange={handleFormChange}
                                    isInvalid={!!errors?.password}
                                    error={errors?.password}
                                />
                                <div className="flex items-center justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary dark:ring-offset-gray-800" required="" />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                                        </div>
                                    </div>
                                    <Link to="/reset-password" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</Link>
                                </div>
                                <button
                                    className="w-full text-white bg-primary1 hover:bg-primary1 focus:ring-4 focus:outline-none focus:ring-primary1 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    onClick={handleFormSubmit}
                                    type="submit"
                                >
                                    Sign in
                                </button>
                                {/* <button type="button" onClick={() => { }}>Sign In with Google</button> */}
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Don’t have an account yet? <Link to="/signup" className="font-medium text-primary1 hover:underline dark:text-primary-500">Sign up</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
    );
};

export default Login;