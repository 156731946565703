import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.userProfile.isAuthenticated);
    const loading = useSelector(state => state.userProfile.loading);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/signin');
        }
    }, [isAuthenticated]);

    return (
        (loading || !isAuthenticated) ? '<h1>Loading...</h1>' : children
    );
};

ProtectedRoute.propTypes = {
    children: PropTypes.element.isRequired,
}

export default ProtectedRoute;