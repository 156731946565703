import React from 'react';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';

const CopyButton = ({ value }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(value);
    };

    return (
        <button type="button" onClick={handleCopy} ><FileCopyTwoToneIcon fontSize="small" className=' text-black hover:text-primary' /></button>
    )
};

export default CopyButton;
