export const APP_ROUTES = {
    HOME: "/",
    ABOUT_US: "/about-us",
    CART: "/cart",
    CATEGORIES: "/categories",
    COLLECTION: "/collections",
    CONTACT_US: "/contact-us",
    CAREERS: "/careers",
    CORPORATE: "/corporate",
    DASHBOARD: "/dashboard",
    DISCOUNTS: "/discounts",
    DELIVERY_TERMS: "/delivery-terms",
    EXCHANGE: "/exchange-policy",
    ORDERS: "/orders",
    PRIVACY: "/privacy",
    PRICING_TERMS: "/pricing-terms",
    PRODUCTS: "/products",
    PAYMENT_TERMS: "/payment-terms",
    RETURNS: "/returns-exchange-terms",
    RESET_PASSWORD: "/reset-password",
    STORES: "/stores",
    SIGNIN: "/signin",
    SIGNOFF: "/sign-off",
    SIGNUP: "/signup",
    TERMS_CONDITIONS: "/terms-and-conditions",
    WISHLIST: "/wishlist",
}