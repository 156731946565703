import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination } from 'flowbite-react';
import { useSearchParams, useNavigate } from 'react-router-dom';

// Components
import BreadCrumbs from "../components/BreadCrumbs";
import CategoryCard from '../components/CategoryCard';

const Categories = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchedCategorySlug = searchParams.get('category');
    const allCategories = useSelector(state => state.categories.categories);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(4);

    useEffect(() => {
        // Reset Pagination
        setCurrentPage(1);
        // Initially render only level One Categories
        let filteredCategories = allCategories.filter(category => category.level === 1);
        // if specified render children of specified categories
        if (searchedCategorySlug) {
            const searchedCategory = allCategories.find(category => category.slug === searchedCategorySlug);
            if (searchedCategory) {
                filteredCategories = allCategories.filter(category => category.parent === searchedCategory.id);
                // if no children found redirect to products page
                if (filteredCategories.length === 0) {
                    navigate(`/products?categories=${searchedCategorySlug}`);
                }
            } else {
                filteredCategories = [];
            }
        }
        setFilteredCategories(filteredCategories);
    }, [allCategories, searchedCategorySlug]);

    // Pagination
    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);
        setCategories(currentItems);
    }, [currentPage, itemsPerPage, filteredCategories]);
    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

    const onPageChange = page => setCurrentPage(page);

    return categories.length === 0 ? (
        <p className='text-center text-2xl'>Categories Not Found!</p>
    ) : (
        <div className='lg:basis p-5 mx-5 overflow-x-auto sm:justify-center'>
            <BreadCrumbs path={"/categories"} />
            <div className='py-12 mx-3 lg:mx-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                {categories.map(category => (
                    <div key={category.id}>
                        <CategoryCard category={category} />
                    </div>
                ))}
            </div>
            {totalPages > 1 && (
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={onPageChange}
                        showIcons
                    />
                </div>
            )}
        </div>
    );
};

export default Categories;