import { useMemo } from "react";
const PackSizeCard = ({
  size,
  className,
  ...restProps
}) => {
  const getDiscount = useMemo(() => {
    if (size?.pricing?.esp !== size?.pricing?.mrp) {
      const discount =
        ((size?.pricing?.mrp - size?.pricing?.esp) / size?.pricing?.mrp) *
        100;
      return Math.round(discount);
    }
    return 0.00;

  }, [size]);
  return (
    <div
      className={`flex items-center min-h-13 border border-solid border-lightgrey rounded-lg px-2 py-1 cursor-pointer ${className ?? ""
        }`}
      {...restProps}
    >
      <div className="w-1/5 font-semibold">
        <div>{size.name}</div>
      </div>
      <div className="flex-grow">
        <div className="flex items-baseline">
          <div>₹{size?.pricing?.esp}</div>
          <div className="text-xs text-gray-500 ml-1 ">
            ₹{size?.pricing?.costPerItem} / item
          </div>
        </div>
        {size?.pricing?.esp !== size?.pricing?.mrp || true ? (
          <div className="flex items-baseline">
            <div className="line-through text-sm">₹{size?.pricing?.mrp}</div>
            <div className="text-xs text-orange-800 ml-1">{getDiscount}%off</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PackSizeCard;
