export const APP_ID = process.env.REACT_APP_PUBLIC_APP_ID;
export const APP_TOKEN = process.env.REACT_APP_PUBLIC_APP_TOKEN;

const BASE_APPLICATION_URL_V1 = `v1/applications/${APP_ID}`
export const URLS = {
    APP_DETAILS: () => {
        return BASE_APPLICATION_URL_V1
    },
    USER_DETAIL: (userId) => {
        return `${BASE_APPLICATION_URL_V1}/users/${userId}`
    },
    UPLOAD_ASSETS: () => {
        return `${process.env.REACT_APP_RETAIL_BASE_URL}/v1/upload/bulk`
    },
    GET_DEPARTMENTS: () => {
        return `${BASE_APPLICATION_URL_V1}/departments`
    },
    DEPARTMENT_DETAIL: (departmentId) => {
        return `${URLS.GET_DEPARTMENTS}/${departmentId}`
    },
    GET_CATEGORIES: () => {
        return `${BASE_APPLICATION_URL_V1}/categories`
    },
    CATEGORY_DETAIL: (categoryId) => {
        return `${URLS.GET_CATEGORIES}/${categoryId}`
    },
    GET_PRODUCTS: (productId = null) => {
        let url = `${BASE_APPLICATION_URL_V1}/products`
        url = productId ? `${url}/${productId}` : url
        return url
    },
    SEARCH: () => {
        return `${BASE_APPLICATION_URL_V1}/search`
    },
    GET_REVIEWS: (productId) => {
        return `${URLS.GET_PRODUCTS(productId)}/reviews`
    },
    REVIEW_DETAIL: (productId, commentId) => {
        return `${URLS.GET_REVIEWS(productId)}/reviews/${commentId}`
    },
    CART: () => {
        return `${BASE_APPLICATION_URL_V1}/cart`
    },
    CART_DETAIL: (cartId) => {
        return `${BASE_APPLICATION_URL_V1}/cart/${cartId}`
    },
    ORDERS: () => {
        return `${BASE_APPLICATION_URL_V1}/orders`
    },
    ORDER_DETAIL: (orderId) => {
        return `${BASE_APPLICATION_URL_V1}/orders/${orderId}`
    },
    BLOGS: () => {
        return `${BASE_APPLICATION_URL_V1}/blogs`
    },
    BLOG_DETAIL: (blogId) => {
        return `${BASE_APPLICATION_URL_V1}/blogs/${blogId}`
    },
}