import React from "react";
import { Label, RangeSlider } from "flowbite-react";
import Slider from '@mui/material/Slider';

const FilterCard = ({ filter, onFilterChange }) => {
    const svfChangeHandler = (filter, i) => {
        const copyFilter = JSON.parse(JSON.stringify(filter));
        copyFilter.values[i].is_selected = true;
        onFilterChange(copyFilter);
    };
    const rfChangeHandler = (a, b, c, d) => {
        console.log(a, b, c, d);
    };
    const RangeFilter = ({ filter }) => {
        const { min, max, selected_min, selected_max } = filter?.values?.[0]
        console.log()
        return (
            <div>
                <div className="mb-1 flex justify-between mx-0 bottom-6">
                    <Label htmlFor="sm-range" value={min} />
                    <Label htmlFor="sm-range" value={max} />
                </div>
                <Slider value={[selected_min, selected_max]} onChange={rfChangeHandler} id="sm-range text-primary" sizing="sm" />
            </div>
        );
    };

    const MultivaluedFilter = ({ filter }) => {
        return (
            <div>
                <div
                    id="dropdownSearch"
                    className="z-10 bg-white rounded-lg shadow w-full dark:bg-gray-700"
                >
                    <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">
                            Search
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        stroke-linejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="input-group-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={`Search ${filter?.key?.display ?? ""}`}
                            />
                        </div>
                    </div>
                    <ul
                        className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownSearchButton"
                    >
                        {
                            filter?.values?.map((item, index) => {
                                return (
                                    <li>
                                        <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                            <input
                                                checked
                                                id={`checkbox-item-${index}`}
                                                type="checkbox"
                                                value=""
                                                className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                            />
                                            <label
                                                htmlFor={`checkbox-item-12`}
                                                className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                            >
                                                {item.display}
                                            </label>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    };

    const SingleValuedFilter = ({ filter }) => {
        return (
            <div>
                {filter.values?.map((item, index) => {
                    console.log(filter);
                    return (
                        <div className="flex items-center mb-4" key={item.display}>
                            <input
                                checked={item.is_selected}
                                onChange={(e) => svfChangeHandler(filter, index)}
                                id={`default-radio-${index}`}
                                type="radio"
                                value={item.is_selected}
                                name="default-radio"
                                className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="default-radio-1"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                {item.display}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    };
    return (
        <div id={`${filter?.key?.name}`}>
            {filter && filter.key.kind === "singlevalued" ? (
                <SingleValuedFilter filter={filter} />
            ) : filter.key.kind === "multivalued" ? (
                <MultivaluedFilter filter={filter} />
            ) : (
                <RangeFilter filter={filter} />
            )}
        </div>
    );
};

export default FilterCard;
