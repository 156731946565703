import StarOutlineTwoToneIcon from "@mui/icons-material/StarOutlineTwoTone";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import { yellow, grey } from "@mui/material/colors";
import React from "react";

export const renderStars = (rating) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <div key={i}>
        <StarOutlinedIcon
          sx={{ color: yellow[800], borderColor: grey[800] }}
          className="rating-icon"
        />
      </div>
    );
  }

  if (hasHalfStar) {
    stars.push(
      <div key={fullStars}>
        <StarHalfOutlinedIcon
          sx={{ color: yellow[800], borderColor: grey[800] }}
          className="rating-icon"
        />
      </div>
    );
  }

  const remainingStars = 5 - stars.length;

  for (let i = 0; i < remainingStars; i++) {
    stars.push(
      <div key={fullStars + i}>
        <StarOutlineTwoToneIcon
          sx={{ color: yellow[800], borderColor: grey[800] }}
          className="rating-icon"
        />
      </div>
    );
  }

  return stars;
};

export const formatDate = (dateString) => {
  const [day, month, year] = dateString.split("-");
  const date = new Date(`${year}-${month}-${day}`);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[date.getMonth()];
  const dayNumber = parseInt(day);
  const suffix = (() => {
    if (dayNumber >= 11 && dayNumber <= 13) {
      return "th";
    }
    switch (dayNumber % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  })();
  const formattedDate = `${parseInt(day)}${suffix}, ${monthName}`;
  return formattedDate;
};

export const errMsgToObj = (msg = "Something went wrong") => {
  let errorObj = {
    message: msg
  };

  msg.split(',').forEach(err => {
    const fieldNameMatch = err.match(/"([^"]+)"/);
    if (fieldNameMatch && fieldNameMatch[1]) {
      const fieldName = fieldNameMatch[1];
      errorObj[fieldName] = err.replace(/"/g, '');
    }
  });

  if (errorObj.message === 'Incorrect email or password') {
    errorObj.email = 'Invalid credentials';
    errorObj.password = 'Invalid credentials';
  }

  if (errorObj.message === 'Email already taken') {
    errorObj.email = 'Email already taken';
  }

  return errorObj;
}