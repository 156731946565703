import { store } from "../../config/store";
import { axiosClient } from "../axios";
import { setErrors } from "../../reducers/errorsSlice";
import { errMsgToObj } from "../../utils/CommonUtils";

export const login = async (data) => {
    try {
        return await axiosClient.post('/v1/auth/login', data);
    } catch (error) {
        const errorObj = errMsgToObj(error.message);
        store.dispatch(setErrors(errorObj));
        throw errorObj;
    }
};

export const register = async (data) => {
    try {
        return await axiosClient.post('/v1/auth/register', data);
    } catch (error) {
        const errorObj = errMsgToObj(error.message);
        store.dispatch(setErrors(errorObj));
    }
};