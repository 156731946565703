import CopyButton from 'components/CopyButton';
import React from 'react';
import { Link } from 'react-router-dom';

const OrderDetailCard = ({ order }) => {
    return (
        <div class="mt-6 w-full divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 dark:divide-gray-700 dark:border-gray-700 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md">
            <div class="p-6">
                <h4 class="mb-4 text-xl font-semibold text-gray-900 dark:text-white">Order Details</h4>

                <div class="flow-root">
                    <div class="divide-y divide-gray-200 dark:divide-gray-700">
                        <dl class="pb-4 sm:flex sm:items-center sm:justify-between sm:gap-4">
                            <dt class="whitespace-nowrap font-semibold text-gray-900 dark:text-white">Order ID</dt>
                            <dd class="mt-2 text-gray-500 dark:text-gray-400 sm:mt-0 sm:text-right">#{order?.id} <CopyButton value={order?.id} /></dd>
                        </dl>

                        <dl class="pb-4 sm:flex sm:items-center sm:justify-between sm:gap-4">
                            <dt class="whitespace-nowrap font-semibold text-gray-900 dark:text-white">Order date</dt>
                            <dd class="mt-2 text-gray-500 dark:text-gray-400 sm:mt-0 sm:text-right">{order?.createdAt.toLocaleString()}</dd>
                        </dl>

                        <dl class="py-4 sm:flex sm:items-center sm:justify-between sm:gap-4">
                            <dt class="whitespace-nowrap font-semibold text-gray-900 dark:text-white">Email</dt>
                            <dd class="mt-2 text-gray-500 dark:text-gray-400 sm:mt-0 sm:text-right">{order?.user.email}</dd>
                        </dl>

                        <dl class="py-4 sm:flex sm:items-center sm:justify-between sm:gap-4">
                            <dt class="whitespace-nowrap text-base font-semibold text-gray-900 dark:text-white">Phone</dt>
                            <dd class="mt-2 text-gray-500 dark:text-gray-400 sm:mt-0 sm:text-right">{order?.user.phone}</dd>
                        </dl>

                        <dl class="py-4 sm:flex sm:items-center sm:justify-between sm:gap-4">
                            <dt class="whitespace-nowrap font-semibold text-gray-900 dark:text-white">Payment method</dt>
                            <dd class="mt-2 flex items-center gap-2 sm:mt-0 sm:justify-end">
                                <img class="h-auto w-5" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg" alt="" />
                                <span class="text-right text-gray-500 dark:text-gray-400"> Credit Card </span>
                            </dd>
                        </dl>

                        <dl class="pt-4 sm:flex sm:items-center sm:justify-between sm:gap-4">
                            <dt class="whitespace-nowrap font-semibold text-gray-900 dark:text-white">Shipping address</dt>
                            <dd class="mt-2 text-gray-500 dark:text-gray-400 sm:mt-0 sm:text-right">{order?.user.address}</dd>
                        </dl>
                    </div>
                </div>
            </div>

            <div class="space-y-4 p-6">
                <h4 class="text-xl font-semibold text-gray-900 dark:text-white">Order amount</h4>

                <div class="space-y-4">
                    <div class="space-y-2">
                        <dl class="flex items-center justify-between gap-4">
                            <dt class="text-gray-500 dark:text-gray-400">Original price</dt>
                            <dd class="font-medium text-gray-900 dark:text-white">&#8377;{order?.cost}</dd>
                        </dl>

                        <dl class="flex items-center justify-between gap-4">
                            <dt class="text-gray-500 dark:text-gray-400">Savings</dt>
                            <dd class="font-medium text-green-500">-&#8377;{order?.discount}</dd>
                        </dl>

                        <dl class="flex items-center justify-between gap-4">
                            <dt class="text-gray-500 dark:text-gray-400">Store Pickup</dt>
                            <dd class="font-medium text-gray-900 dark:text-white">&#8377;{order?.deliveryCharge}</dd>
                        </dl>

                        <dl class="flex items-center justify-between gap-4">
                            <dt class="text-gray-500 dark:text-gray-400">Tax</dt>
                            <dd class="font-medium text-gray-900 dark:text-white">&#8377;{order?.tax}</dd>
                        </dl>
                    </div>

                    <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                        <dt class="font-bold text-gray-900 dark:text-white">Total</dt>
                        <dd class="font-bold text-gray-900 dark:text-white">&#8377;{order?.total}</dd>
                    </dl>
                </div>
            </div>

            {(order.staus === "IN_TRANSIT" || order.staus === "RETURN" || order.staus === "PRE_ORDER") ? <div className='space-y-4 p-6'>
                <div className="gap-4 flex flex-col items-center">
                    <Link to={`/orders/${order?.id}/track`} className="mt-4 flex w-full items-center justify-center rounded-lg bg-primary  px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary dark:focus:ring-primary-800 sm:mt-0">Track order</Link>
                    <button type="button" className="w-full rounded-lg  border border-gray-200 bg-white px-5  py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">Cancel the order</button>
                </div>
            </div> : <></>}

        </div>);
}

export default OrderDetailCard;
