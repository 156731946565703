import Loader from 'components/Loader';
import OrderDetailCard from 'components/cards/OrderDetailCard';
import OrderProductCard from 'components/cards/OrderProductCard';
import React, { useEffect, useState } from 'react';

const OrderDetail = ({ orderId }) => {
    const [order, setOrder] = useState({
        id: "orderId",
        products: [{
            id: "asdfasdfasd",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasdfasd",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasdfasd",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        },
        {
            id: "asdfasas",
            name: "Products",
            description: "asdlkfasd asdfalksdjfaksjdfklas asd lkfjahskldfhas dkajfhskdjfhasd asldjfa",
            url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
            quantity: 9,
            cost: 100
        }
        ],
        status: "IN_TRANSIT",
        estimateDelivery: new Date(),
        user: {
            email: "test@gmail.com",
            phone: "9090909090",
            address: "Pune, India, 492008",
        },
        invoiceUrl: "http://localhost:3000/static/media/logo.e4ca87f10b815d2e2c2c.png",
        createdAt: new Date(),
        cost: 1000,
        discount: 10,
        deliveryCharge: 10,
        tax: 10,
        total: 1020
    });
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // fetch the card details
    //     // fetch the product details using the productId
    //     setLoading(true);
    //     debugger;
    //     setOrder({
    //         id: "orderId",
    //         products: [{
    //             id: "asdfasdfasd",
    //             name: "Products",
    //             url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
    //             quantity: 9,
    //             cost: 100
    //         },
    //         {
    //             id: "asdfas",
    //             name: "Products",
    //             url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
    //             quantity: 9,
    //             cost: 100
    //         },
    //         {
    //             id: "asdfasas",
    //             name: "Products",
    //             url: "https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg",
    //             quantity: 9,
    //             cost: 100
    //         }
    //         ],
    //         status: "IN_TRANSIT",
    //         estimateDelivery: new Date(),
    //         email: "test@gmail.com",
    //         invoiceUrl: "http://localhost:3000/static/media/logo.e4ca87f10b815d2e2c2c.png",
    //         address: {
    //             pincode: 492008,
    //             city: "Pune",
    //             country: "India",
    //         },
    //         createdAt: new Date(),
    //         cost: 1000,
    //         discount: 10,
    //         deliveryCharge: 10,
    //         total: 1010
    //     });
    //     setLoading(false);
    // }, []);

    return (
        <>
            {loading ? <Loader /> :
                <section class="bg-white pb-8 antialiased dark:bg-gray-900 md:pb-16">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl pt-2">Order #{order.id}</h2>
                    <div class="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-8">

                        {/* Product Cards */}
                        <div class="min-w-0 flex-1 divide-y divide-gray-200 rounded-lg border border-gray-200 bg-white shadow-sm dark:divide-gray-700 dark:border-gray-700 dark:bg-gray-800">
                            {order?.products.map(product => (<OrderProductCard key={product.id} product={product} />))}
                        </div>

                        <OrderDetailCard order={order} />
                    </div>
                </section>
            }
        </>

    );

}

export default OrderDetail;
