import React from 'react';
import Products from './Products';

const CollectionDetails = () => {
    return (
        <div>
            <Products />
        </div>
    );
};

export default CollectionDetails;