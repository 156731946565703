import { useSelector } from "react-redux";
import { PLPCard } from "components/cards";
import { useCallback } from "react";
const FeaturedProducts = () => {
  //TODO create one hooks for featured products
  const { products, filters = [] } = useSelector((state) => state.products);
  const { cartDetail } = useSelector((state) => state.userProfile);
  const getItemCountInCart = useCallback(
    (item) => {
      return cartDetail?.items?.find((i) => i.id === item.id)?.quantity ?? 0;
    },
    [cartDetail]
  );

  const FiltersList = () => {
    //TODO update once get filters
    if (filters.length > 0 || true) {
      console.log(filters);
      return (
        <div className="hidden md:inline-flex gap-4">
          {["All (12)", "Chilly (12)", "Vegetables (5)"].map((item) => {
            return (
              <h3
                key={item}
                className="font-sans text-gray-500 hover:text-primary"
              >
                {item}
              </h3>
            );
          })}
        </div>
      );
    }
  };
  return (
    <div className="my-8">
      <div className="flex mb-3 justify-between">
        <div>
          <h3 className="font-sans text-2xl">Featured Products</h3>
        </div>
        <FiltersList />
      </div>
      {products?.length > 0 && (
        <div className="my-5 w-full inline-flex flex-shrink-0 md:flex md:flex-1 md:justify-evenly gap-4 overflow-x-scroll scrollbar-hide">
          {products?.map((product) => (
            <div key={"feature-product:" + product.id}>
              <PLPCard
                itemCountInCart={getItemCountInCart(product)}
                product={product}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeaturedProducts;
