import "./Header.css";
import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from 'flowbite-react';
import { useSelector } from "react-redux";
import logo from "../../assets/company/logo.png";
import locUtils from '../../utils/LocationUtils';
import SearchBar from "./SearchBar";
import Navigation from "./Navigation";
import LocationAuto from "../common/LocationAuto";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { APP_ROUTES } from "constants/routes";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";


const Header = () => {
  const navigate = useNavigate()
  const { user = {}, cartDetail = {} } = useSelector(state => state.userProfile);
  const isAuthenticated = useSelector(state => state.userProfile.isAuthenticated);
  const [drawerOpen, setDrawerOpen] = useState(false);


  const companyDetails = {
    address: "Guntur, Andhra Pradesh, India",
    email: "sundheepproducts@gmail.com",
    logo: logo,
  };

  const headerLinks = useMemo(() => {
    const { WISHLIST, CART, DASHBOARD, ORDERS, CONTACT_US, SIGNOFF } = APP_ROUTES
    return (
      <>
        <LocationAuto />
        <Link to={WISHLIST} className="hidden lg:block">
          <FavoriteBorderIcon fontSize="large" className="primary-color" />
        </Link>
        <Link to={CART} className="relative hidden lg:block">
          <ShoppingCartOutlinedIcon fontSize="large" className="primary-color" />
          <span className="text-white text-xs text-center content-center absolute w-4 h-4 right-1 rounded-[50%] bg-[red]">{cartDetail?.items?.length ?? 0}</span>
        </Link>
        <div className="hidden lg:block">

          {
            isAuthenticated ? (
              <Dropdown
                label={<AccountCircleIcon fontSize="large" className="primary-color hidden lg:block" />}
                arrowIcon={false}
                inline
              >
                <Dropdown.Header>
                  <span className="block text-sm">{user?.name}</span>
                  <span className="block truncate text-sm font-medium">{user?.email}</span>
                </Dropdown.Header>
                <Link to={DASHBOARD}>
                  <Dropdown.Item className="theme-bg-color" icon={PersonIcon}>Dashboard</Dropdown.Item>
                </Link>
                <Link to={ORDERS}>
                  <Dropdown.Item className="theme-bg-color" icon={ShoppingBagIcon}>Orders</Dropdown.Item>
                </Link>
                <Link to={WISHLIST}>
                  <Dropdown.Item className="theme-bg-color" icon={FavoriteIcon}>Wishlist</Dropdown.Item>
                </Link>
                <Link to={CONTACT_US}>
                  <Dropdown.Item className="theme-bg-color" icon={ContactPageIcon}>Contact Us</Dropdown.Item>
                </Link>
                <Dropdown.Divider style={{ backgroundColor: "lightgrey" }} />
                <Link to={SIGNOFF}>
                  <Dropdown.Item className="theme-bg-color" icon={LogoutIcon}>Log out</Dropdown.Item>
                </Link>
              </Dropdown>
            ) : (
              <AccountCircleIcon onClick={() => navigate(APP_ROUTES.SIGNIN)} fontSize="large" className="primary-color cursor-pointer hidden lg:block" />
            )
          }
        </div>
      </>
    )
  }, [user, cartDetail, isAuthenticated])

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const categories = useSelector(state => state.categories.categories);
  const levelOneCategories = useMemo(() => {
    return categories
      .filter(category => category.level === 1)
      .sort((cat1, cat2) => cat1.priority - cat2.priority);
  }, [categories]);


  const drawerContent = (
    <div className="px-7 py-5 text-[20px] w-80">
      <div className="flex justify-center mb-6">
        <Link to="/" onClick={closeDrawer}>
          <img src={companyDetails.logo} className="h-16 w-auto" alt="Sun Dheep" />
        </Link>
      </div>
      {isAuthenticated && user && (
        <div className="text-[18px] mb-4 border-b border-gray-400 pb-4">
          <p className="font-semibold">{user.name}</p>
          <p className="text-gray-600">{user.email}</p>
        </div>
      )
      }
      {/* Level One Categories */}
      <div className="mt-4">
        <h3 className="font-semibold mb-2">Categories</h3>
        {levelOneCategories.map(category => (
          <Link
            key={category.id}
            to={`/categories?category=${category.slug}`}
            className="block py-3 hover:text-primary text-[18px] last:border-b last:border-gray-400"
            onClick={closeDrawer}
          >
            {category.name}
          </Link>
        ))}
      </div>
      <div className="mt-4 flex flex-col space-y-4">
        <Link to={APP_ROUTES.WISHLIST} className="flex items-center py-3 border-b border-gray-400" onClick={closeDrawer}>
          <FavoriteBorderIcon className="mr-2" />
          <span>Wishlist</span>
        </Link>
        <Link to={APP_ROUTES.CART} className="flex items-center py-3 border-b border-gray-400" onClick={closeDrawer}>
          <ShoppingCartOutlinedIcon className="mr-2" />
          <span>Cart</span>
          <span className="ml-2 text-white text-xs text-center content-center w-4 h-4 rounded-full bg-red-500">
            {cartDetail?.items?.length ?? 0}
          </span>
        </Link>
        {isAuthenticated ? (
          <>
            <Link to={APP_ROUTES.DASHBOARD} className="flex items-center py-3 border-b border-gray-400" onClick={closeDrawer}>
              <PersonIcon className="mr-2" />
              <span>Dashboard</span>
            </Link>
            <Link to={APP_ROUTES.ORDERS} className="flex items-center py-3 border-b border-gray-400" onClick={closeDrawer}>
              <ShoppingBagIcon className="mr-2" />
              <span>Orders</span>
            </Link>
            <Link to={APP_ROUTES.CONTACT_US} className="flex items-center py-3 border-b border-gray-400" onClick={closeDrawer}>
              <ContactPageIcon className="mr-2" />
              <span>Contact Us</span>
            </Link>
            <Link to={APP_ROUTES.SIGNOFF} className="flex items-center py-3 border-b border-gray-400" onClick={closeDrawer}>
              <LogoutIcon className="mr-2" />
              <span>Log out</span>
            </Link>
          </>
        ) : (
          <Link to={APP_ROUTES.SIGNIN} className="flex items-center py-3 border-b border-gray-400">
            <AccountCircleIcon className="mr-2" />
            <span>Sign In</span>
          </Link>
        )}
      </div>
      {/* Add more drawer content as needed */}
    </div>
  );

  return (
    <header className="mx-0 sticky top-0 w-full z-50">
      <div className="flex flex-col w-full">
        {/* Company Location */}
        <div className="flex h-12 bg-primary text-white justify-center md:justify-between font-bold shadow-sm md:px-10">
          <div className="flex items-center">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>
            <div className="font-sans text-base p-2 mr-3">
              {companyDetails.address}
              {locUtils?.latitude}{locUtils?.longitude}
            </div> */}
            Sun Dheep Products
          </div>
        </div>
        {/* Navbar with search-box */}
        <div className="flex p-2 md:pt-6 items-center justify-between md:px-10 bg-body-bgColor shadow-md">
          <div className="flex items-center">
            <div className="lg:hidden mr-2">
              <MenuIcon onClick={toggleDrawer(true)} className="cursor-pointer" />
            </div>
            <div className="flex mb-2">
              <Link to="/"><img src={companyDetails.logo} className="h-12 w-30" alt="Sun Dheep" /></Link>
            </div>
          </div>
          <div className="hidden lg:block">
            <SearchBar />
          </div>
          <div className="flex">
            <div className="header-top-left-icons">
              {headerLinks}
            </div>
          </div>
        </div>
        {/* Categories Bar */}
        <Navigation></Navigation>
      </div>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ style: { width: '20rem' } }}
      >
        {drawerContent}
      </Drawer>
    </header>
  );
};

export default Header;
