import React, { useState } from "react";
import sameple_packet from "../../../assets/vegtables.jpg";
import "./Wishlist.css";
import { Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([
    {
      id: 1,
      name: "Product 1, this name can be wrapped in 2 lines if it goes bigger, after 2 lines it shows ellipses. ellipses is three dots ...",
      price: "₹100",
      imageUrl: sameple_packet,
    },
    { id: 2, name: "Product 2", price: "₹200", imageUrl: sameple_packet },
    { id: 3, name: "Product 3", price: "₹300", imageUrl: sameple_packet },
    // Add more dummy items as needed
  ]);

  return (
    <div className="wishlist-container">
      <div
        style={{
          display: "flex",
          padding: "24px 0px",
          fontWeight: 500,
          fontSize: "24px",
        }}
      >
        <h1>My Wishlist (3 Items)</h1>
      </div>
      <div className="wishlist-items">
        {wishlistItems.map((item) => (
          <div key={item.id} className="wishlist-item-card">
            <img
              src={item.imageUrl}
              alt={item.name}
              className="wishlist-item-img"
            />
            <div className="wishlist-item-details">
              <h3 className="wishlist-item-name">{item.name}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className="wishlist-product-price">
                    <div className="wishlist-product-offer-price">$200</div>
                    <div className="wishlist-product-original-price">$300</div>
                    <div className="wishlist-discount-percent">25%</div>
                  </div>
                  <p className="wishlist-saved-money">
                    You save 100 Dollars, in this order
                  </p>
                </div>
                <div style={{ columnGap: "8px", display: "flex" }}>
                  <Button
                    variant="outlined"
                    style={{ color: "rgb(27, 128, 87)" }}
                    startIcon={<DeleteIcon />}
                  >
                    Remove
                  </Button>
                  <Button
                    variant="contained"
                    style={{ background: "rgb(27, 128, 87)" }}
                    startIcon={<ShoppingCartIcon />}
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wishlist;
