import React from 'react';

export const GoogleAddressMap = () => {
    return (
      <gmp-map center="16.282546997070312,80.40316772460938" zoom="14" map-id="DEMO_MAP_ID">
        <gmp-advanced-marker position="16.282546997070312,80.40316772460938" title="Sund Dheep Products"></gmp-advanced-marker>
      </gmp-map>
    );
}

export default GoogleAddressMap;
