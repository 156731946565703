import { APP_ROUTES } from 'constants/routes';
import BreadCrumbs from 'components/BreadCrumbs';
import ContactCard from './ContactUsCard';
import React from 'react';

const DeliveryTerms = () => {
    return (
        <div>
            <header>
                <title>SUNDHEEP PRODUCTS - Product Returns</title>
                <meta name="description" content="A leading player in food product manufacturing and agricultural trading." />
            </header>
            <BreadCrumbs path={APP_ROUTES.DELIVERY_TERMS} />
            <div className='flex flex-1 mx-auto pb-5'>
                <div className='flex flex-col space-x-1 space-y-3 mx-auto'>
                    <div className='justify-center items-center text-justify'>
                        <h1 className='font-bold text-xl text-center space-y-1'>Careers</h1>
                    </div>
                </div>
                <div className='mt-6 space-y-5'>
                    <section className='space-y-2 text-justify'>
                        <p>
                            At SUN DHEEP PRODUCTS, we believe that our people are our greatest asset. As a rapidly growing company in the food manufacturing and agricultural trading industry, we are always looking for talented, passionate, and dedicated individuals to join our team. Our commitment to excellence is reflected not only in the products we create but also in the environment we foster for our employees.
                        </p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Why Work With Us?</h3>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>
                                <span className='font-semibold'>Dynamic Growth Environment: </span>
                                <p>As an industry leader, we offer opportunities for growth and development in a dynamic, fast-paced work environment.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Innovation and Excellence: </span>
                                <p>Our collaborative work culture ensures that every voice is heard, and every contribution is valued. We believe in teamwork, respect, and shared success.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Collaborative Culture:  </span>
                                <p>Our collaborative work culture ensures that every voice is heard, and every contribution is valued. We believe in teamwork, respect, and shared success.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Commitment to Quality: </span>
                                <p>Quality is at the heart of everything we do. If you share our commitment to delivering the best products and services, SUN DHEEP PRODUCTS is the place for you.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Employee Development: </span>
                                <p>We provide continuous learning opportunities, training programs, and career advancement paths to help you achieve your professional goals.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Opportunities Available</h3>
                        <p>Whether you're a seasoned professional or just starting your career, SUN DHEEP PRODUCTS offers a range of opportunities across various functions, including:</p>
                        <ul className='font-bold'>
                            <li className='ml-4 space-y-2'>
                                <span className='font-semibold'>Manufacturing and Production: </span>
                                <p>Join our team in producing high-quality food products with precision and efficiency.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Quality Control: </span>
                                <p>Help us maintain our stringent quality standards, ensuring that every product meets our commitment to excellence.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Sales and Marketing: </span>
                                <p> Drive our growth by developing and executing strategies that reach new markets and customers.</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Supply Chain and Logistics: </span>
                                <p>Play a key role in ensuring our products reach our customers efficiently and on time</p>
                            </li>
                            <li>
                                <span className='font-semibold'>Agricultural Trading: </span>
                                <p>Engage with farmers, suppliers, and partners to source the best agricultural commodities for our business.</p>
                            </li>
                        </ul>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>Join Us Today</h3>
                        <p>If you are driven, innovative, and ready to make a difference in the food manufacturing and agricultural trading industry, we invite you to explore the opportunities at SUN DHEEP PRODUCTS. Join us in our mission to deliver quality products and contribute to our vision of becoming a leading player in the industry.</p>
                    </section>
                    <section className='space-y-2 text-justify'>
                        <h3 className='font-semibold'>How to Apply</h3>
                        <p>Please send your resume and a cover letter to sundheepproducts@gmail.com. We look forward to hearing from you and potentially welcoming you to our team!</p>
                    </section>
                </div>
            </div>
            <div className='ml-8'>
                <ContactCard />
            </div>
        </div>
    );
}

export default DeliveryTerms;
