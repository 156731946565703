import { useEffect, useState } from "react";
import { setAddresses } from "reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import EditAddress from "components/cards/EditAddress";

const Address = ({ ...restProps }) => {
    const dispatch = useDispatch();
    const { addresses = [] } = useSelector((state) => state.userProfile);
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [addressToEdit, setAddressToEdit] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [viewAllAddress, setViewAllAddress] = useState(false);

    useEffect(() => {
        setDefaultAddress(
            addresses?.find((address) => address.is_default_address) ??
            addresses?.[0] ??
            null
        );
    }, [addresses]);

    const handleAddressSelection = (address) => {
        const tempAdd = JSON.parse(JSON.stringify(addresses));
        tempAdd.map((add) => (add.is_default_address = false));
        tempAdd.find((add) => add.id === address.id).is_default_address = true;
        dispatch(setAddresses(tempAdd));
    };

    const resetEditState = () => {
        setIsEdit(false);
        setAddressToEdit(null);
    }
    const saveHandler = (formData) => {
        const tempAdd = JSON.parse(JSON.stringify(addresses)) ?? []
        const index = tempAdd.findIndex((add) => add.id === formData.id)
        if (index !== -1) {
            tempAdd[index] = formData
        } else {
            tempAdd.push(formData)
        }
        dispatch(setAddresses(tempAdd))
        resetEditState()
    }

    const AddressChangeCta = () => {
        return (
            <p
                className="hidden  shrink-0 text-end text-base font-normal underline text-blue-600 cursor-pointer sm:block"
                onClick={() => {
                    setViewAllAddress(true);
                }}
            >
                change delivery address
            </p>
        );
    };

    const AddressCard = ({ address: addressToDisplay, classes, child, ...restProps }) => {
        const { first_name, last_name, address, landmark, area, city, state, area_code, address_type
        } = addressToDisplay ?? {};
        return (
            <div className={"flex items-start gap-4 pb-6 border-gray-200 " + classes ?? ''} {...restProps}>
                {
                    address_type === 'home' ? <HomeIcon
                        className="aspect-square h-10 w-10 shrink-0"
                        sx={{ height: "24px", width: "24px", color: "grey" }}
                    /> : address_type === 'office' ? <BusinessCenterIcon
                        className="aspect-square h-10 w-10 shrink-0" sx={{ height: "24px", width: "24px", color: "grey" }} /> : <NightShelterIcon
                        className="aspect-square h-10 w-10 shrink-0" sx={{ height: "24px", width: "24px", color: "grey" }} />
                }

                <div className="min-w-0 flex-1 space-y-1">
                    <div className="text-base font-medium text-gray-900">
                        <span> {first_name ?? ""} {last_name ?? ""}</span>
                        <span onClick={(e) => { setIsEdit(true); setAddressToEdit(addressToDisplay) }}
                            className=" rounded bg-gray-300  text-sm ml-2 py-[3px] pr-[6px] cursor-pointer hover:bg-gray-400"
                        > <EditLocationOutlinedIcon sx={{ fontSize: '16px' }} /> edit</span>
                    </div>
                    <div className="text-base font-normal text-gray-900">
                        {address} {landmark ?? ""}
                    </div>
                    <div className="text-base font-normal text-gray-900">
                        {area ?? ""}
                    </div>
                    <div className="text-base font-normal text-gray-900">{`${city ?? ""
                        } ${state ?? ""}, ${area_code ?? ""}`}</div>


                    <div className="flex items-center gap-2">
                        <LocalShippingOutlinedIcon className="aspect-square h-10 w-10 shrink-0"
                            sx={{ height: "24px", width: "24px", color: "grey" }} />
                        <p
                            className="text-sm font-normal text-gray-500 dark:text-gray-400"
                        >
                            Delivery on 14 Nov 2024
                        </p>
                    </div>
                </div>
                {child ? child : null}
            </div>
        );
    };

    const EmptyAddress = () => {
        return (
            <div className="flex flex-col items-start h-full">
                <p className="text-lg font-normal text-gray-900 dark:text-white">
                    No address found :/
                </p>
                <Button
                    color="info"
                    variant="outlined"
                    onClick={() => {
                        setIsEdit(true);
                    }}
                    sx={{ color: "grey" }}
                >
                    + Add Address
                </Button>
            </div>
        );
    };

    const AddressList = () => {
        return (
            <div className="w-[max] space-y-6 rounded-lg border border-gray-100 bg-gray-50 p-4">
                <div className="min-w-0 flex-1 flex flex-col">
                    <div aria-label="address-selection-header" className="flex justify-between items-baseline">
                        <h2 className="mb-6 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                            Your addresses
                        </h2>
                        <button
                            onClick={() => {
                                setViewAllAddress(false);
                            }}
                            className="bg-primary hover:bg-green-700 text-white font-normal py-1 px-2 rounded text-sm"
                        >
                            Use the selected address
                        </button>
                    </div>

                    <form>
                        {addresses.map((address, index) => (
                            <div key={index} className="mb-2">
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="deliveryAddress"
                                        value={address}
                                        checked={defaultAddress.id === address.id}
                                        onChange={() => handleAddressSelection(address)}
                                        className="mr-2 h-4 w-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0"
                                    />
                                    <AddressCard address={address} classes={'border-b flex-1 cursor-pointer'} />
                                </label>
                            </div>
                        ))}
                        <div className="cursor-pointer hover:text-green-800" onClick={() => setIsEdit(true)}>
                            + Add a new address
                        </div>
                        <button
                            onClick={() => {
                                setViewAllAddress(false);
                            }}
                            className="bg-primary hover:bg-green-700 text-white font-normal mt-6 py-1 px-2 rounded text-sm"
                        >
                            Use the selected address
                        </button>
                    </form>

                </div>
            </div>
        );
    };


    return (
        <div {...restProps}>
            {!viewAllAddress && <h2 className="mb-6 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Delivery address
            </h2>}

            {defaultAddress ? (
                viewAllAddress ? (
                    <AddressList />
                ) : (
                    <AddressCard address={defaultAddress} child={<AddressChangeCta />} classes={'border-b '} />
                )
            ) : (
                <EmptyAddress />
            )}
            {
                isEdit && <EditAddress address={addressToEdit} onSave={saveHandler} onClose={resetEditState} />
            }
        </div>
    );
};

export default Address;
