import { setDefaults, fromLatLng, geocode, RequestType } from "react-geocode";

// Set default response language and region (optional).
// This sets default values for language and region for geocoding requests.
setDefaults({
    // key: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", // Your API key here (commented out for testing purposes).
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
});

// Get address from latitude & longitude.
fromLatLng(48.8583701, 2.2922926)
    .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        console.log(lat, lng);
    })
    .catch(console.error);

// These examples demonstrate how to override default settings for specific requests.
// const latlngResponse = await geocode(
//     RequestType.LATLNG,
//     "48.8583701,2.2922926",
//     { language: "en", region: "sp", enable_address_descriptor: true }
// );

// console.log(latlngResponse);

let longitude, latitude;

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const handleCurrentLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError, options);
    } else {
        console.log("Geolocation not supported");
    }
};

const handleSuccess = (position) => {
    const coordinates = position.coords;

    latitude = coordinates?.latitude;
    longitude = coordinates?.longitude;
    const accuracy = coordinates?.accuracy;
    console.log(`Location: lat: ${latitude} lng: ${longitude} acc: ${accuracy}`);
    return { latitude, longitude, accuracy };
};

const handleError = () => {
    console.log("Unable to retrieve your location");
};

export default { handleCurrentLocation, longitude, latitude };
